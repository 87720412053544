import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import { CallGETAPI, CallPOSTAPI, CallPostFileUpload } from "../../helper/Constants";
import ImportCSVModal from "../models/ImportCSVModal";
import AddNumberModal from "../models/AddNumberModal";
import EditNumberModal from "../models/EditNumberModal"; 
import Swal from "sweetalert2";


function ManageUsers() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedPhone, setSelectedPhone] = useState(null); 
  const [file, setFile] = useState(null);

  const fetchData = async () => {
    try {
      const response = await CallGETAPI("api/get-all-phones");
      console.log(response, "fetch api");
      if (response.status) {
        setData(response.data.data);
      } else {
        console.error("Failed to fetch data:", response.msg);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    setIsLoading(true);
  }, []);




  const getStatusText = (status) => {
    switch (status) {
      case "1":
        return "ACTIVE";
      case "2":
        return "PURCHASED";
      case "0":
        return "DEACTIVATED";
      default:
        return "UNKNOWN";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "1":
        return "green";
      case "2":
        return "blue";
      case "0":
        return "red";
      default:
        return "gray";
    }
  };

  const handleStatusToggle = async (ph_id, currentStatus) => {
    let newStatus;
    if (currentStatus === "1") {
      newStatus = "0"; // Active to Deactivated
    } else if (currentStatus === "0") {
      newStatus = "1"; // Deactivated to Active
    } else {
      // If status is "2" (PURCHASED), we don't toggle
      toast.info("Purchased numbers cannot be toggled");
      return;
    }

    setIsLoading(true);
    try {
      const response = await CallPOSTAPI(`api/update-phone/${ph_id}`, {
        status: newStatus,
      });
      if (response.status) {
        const updatedData = data.map((item) => {
          if (item.ph_id === ph_id) {
            return { ...item, status: newStatus };
          }
          return item;
        });
        setData(updatedData);
        toast.success("Phone status updated successfully");
      } else {
        toast.error("Failed to update phone status");
      }
    } catch (error) {
      console.error("Error updating phone status:", error.message);
      toast.error("Error updating phone status");
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", file);

      const response = await CallPostFileUpload(`api/upload-csv`, formData, {
       
      });
      setIsLoading(false);
      if (response.data.status) {
        toast.success("File uploaded successfully");
        fetchData();
      } else {
        toast.error("Failed to upload file");
      }
    } catch (error) {
      console.error("Error uploading file:", error.message);
      setIsLoading(false);
    }
  };

  const handleAddNumber = (newNumber) => {
    setData((prevData) => [...prevData, newNumber]);
  };

  const handleEditClick = (phone) => {
    setSelectedPhone(phone);
    setShowEditModal(true);
  };

  const handleUpdateNumber = async (updatedPhone) => {
    try {
      setIsLoading(true);
      const response = await CallPOSTAPI(`api/update-phone/${updatedPhone.ph_id}`, updatedPhone);
      setIsLoading(false);
      if (response.status) {
        const updatedData = data.map((item) =>
          item.ph_id === updatedPhone.ph_id ? updatedPhone : item
        );
        setData(updatedData);
        toast.success("Phone updated successfully");
        setIsLoading(false);
        setShowEditModal(false);
      } else {
        toast.error("Failed to update phone");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error updating phone:", error.message);
    }
  };

  const handleDeleteClick = async (phoneId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await CallGETAPI(`api/delete-phone/${phoneId}`);
          if (response.data.status) {
            const updatedData = data.filter((item) => item.ph_id !== phoneId);
            setData(updatedData);
            Swal.fire(
              'Deleted!',
              'Your phone number has been deleted.',
              'success'
            );
          } else {
            toast.error("Failed to delete phone");
          }
        } catch (error) {
          console.error("Error deleting phone:", error.message);
          toast.error("Error deleting phone");
        }
      }
    });
  };
  



  const Phonedata = [
    {
      name: "Number",
      // selector: "mobile_number",
      selector: row => row.mobile_number,
      sortable: true,
      compact: true,
    },
    {
      name: "Number IP",
      // selector: "mobile_ip",
      selector: row => row.mobile_ip,
      sortable: true,
      compact: true,
    },
    {
      name: "Country",
      // selector: "country",
      selector: row => row.country,
      sortable: true,
      compact: true,
    },
    {
      name: "City",
      // selector: "city",
      selector: row => row.city,
      sortable: true,
      compact: true,
    },

    {
      name: "Purchased by",
      // selector: "user_name",
      selector: row => row.user_name,
      sortable: true,
      compact: true,
      cell: (row) => {
        return (
          <div className="link" >
            {row.user_name ? row.user_name: 'N/A'}
          </div>
        )
      }
    },
    {
      name: "Status",
      // selector: "status",
      selector: row => row.status,
      sortable: true,
      cell: (row) => {
        const statusText = getStatusText(row.status);
        const statusColor = getStatusColor(row.status);
        return (
          <div
            style={{ color: statusColor, cursor: row.status !== 2 ? "pointer" : "default" }}
            onClick={() => row.status !== 2 && handleStatusToggle(row.ph_id, row.status)}
          >
            {statusText}
          </div>
        );
      },
    },
    {
      name: "Number Type", // New column added here
      // selector: "is_local",
      selector: row => row.is_local,
      sortable: true,
      cell: (row) => (row.is_local === 1 ? "Local" : "Toll Free"), // Logic to show Local or Toll Free
    },
    
    {
      name: "ACTION",
      center: true,
      sortable: false,
      width: "10%",
      // selector: "null",
      selector: row => row.null,

      cell: (row) => [
        <button
          type="button"
          class="btn btn-sm btn-outline-warning"
          onClick={() => handleEditClick(row)} 
        >
          <i class="fa-regular fa-pen-to-square"></i>
        </button>,
        <button
          type="submit"
          class="btn btn-sm btn-outline-warning"
          onClick={() => handleDeleteClick(row.ph_id)}
        >
          <i class="fa-regular fa-trash-can"></i>
        </button>,
      ],
    },
  ];

  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active">Phone Number</li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <div className="card">
            <div className="card-body mt-3">
              <h3>
                <strong className="border-bottom border-3 pb-2">
                  Phone Number
                </strong>
              </h3>
              <div className="d-flex justify-content-end mb-3">
                <Button
                  variant="primary"
                  onClick={() => setShowImportModal(true)}
                >
                  <i className="fa-solid fa-download"></i> Import Number
                </Button>

                <Button
                  variant="primary"
                  className="ms-2"
                  onClick={() => setShowAddModal(true)}
                >
                  Add Number
                </Button>
              </div>
              <div className="card" style={{ boxShadow: "none" }}>
                <div className="card-body" style={{ padding: 0 }}>
                  <div className="container-fluid d-flex justify-content-center">
                    <div className="w-100">
                      {isLoading && (
                        <div className="d-flex justify-content-center my-5">
                          <ReactLoading
                            type="spokes"
                            color="grey"
                            height={50}
                            width={50}
                          />
                        </div>
                      )}
                      {!isLoading && (
                        <div className="main">
                          <DataTable
                            className="border-top border-1 mt-4"
                            columns={Phonedata}
                            data={data}
                            searchable
                            noHeader
                            defaultSortField="id"
                            pagination
                            highlightOnHover
                            dense
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ImportCSVModal
        show={showImportModal}
        onHide={() => setShowImportModal(false)}
        onFileChange={handleFileChange}
        onFileUpload={handleFileUpload}
      />
      <AddNumberModal
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        onAddNumber={handleAddNumber}
        fetchData={fetchData}
      />
      <EditNumberModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        phoneData={selectedPhone}
        onUpdateNumber={handleUpdateNumber}
        isLoading={isLoading}
      />
      <footer id="footer" className="footer">
        <div className="copyright">
          &copy; Copyright 2023{" "}
          <strong>
            <span>Live PBX</span>
          </strong>
          . All Rights Reserved
        </div>
      </footer>
    </>
  );
}

export default ManageUsers;
