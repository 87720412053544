import React, { useState, useEffect } from "react";
import { ipcolumns, ipdata } from "./CustomerData";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import { CallGETAPI, CallPOSTAPI } from "../helper/Constants";
const ReportList = () => {
  const [formData, setFormData] = useState({
    name: "",
    ip: "",
    prefix: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [ipData, setIpData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [searchText, setSearchText] = useState(""); // State to hold search query
  const [isSubmitted, setIsSubmitted] = useState(false);

  const filteredData = ipData.filter((item) => {
    // Convert all search text and item values to lowercase for case-insensitive search
    const search = searchText.toLowerCase();
    const name = item.name.toLowerCase();
    const ip = item.ip.toLowerCase();
    const prefix = item.prefix.toLowerCase();

    // Check if any of the fields contain the search text
    return (
      name.includes(search) || ip.includes(search) || prefix.includes(search)
    );
  });

  useEffect(() => {
    fetchIpData();
  }, []);

  const fetchIpData = async () => {
    setIsLoading(true);
    try {
      const response = await CallGETAPI("api/get-all-ipsettings");
      const data = response.data.ipSettings;
      setIpData(data);
      setIsDataLoaded(true);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to fetch data!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    setIsSubmitted(true);
    // Check if required fields are empty
    if (!formData.ip) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await CallPOSTAPI("api/create-ipsettings", formData);

      const data = response.data;
      console.log(data);
      toast.success("Data saved successfully!");
      fetchIpData();
      setFormData({
        name: "",
        ip: "",
        prefix: "",
      });
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to save data!");
    } finally {
      setIsLoading(false);
    }
  };

  // Validation function for a specific field
  const validateField = (fieldName) => {
    if (isSubmitted && !formData[fieldName]) {
      return "This field is required";
    }
    return "";
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDelete = async (id) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this IP Setting!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await CallGETAPI(`api/delete-ipsettings/${id}`);
          if (response.status) {
            toast.success("Deleted Successfully!");
            fetchIpData();
          }
        }
      });
    } catch (error) {
      console.error("Error deleting data:", error);
      toast.error("Failed to delete data!");
    }
  };

  const customStyles = {
    table: {
      style: {
        borderCollapse: "collapse",
        border: "1px solid black",
      },
    },
    rows: {
      style: {
        borderCollapse: "collapse",
      },
    },
    columns: {
      style: {
        borderCollapse: "collapse",
      },
    },
    header: {
      style: {
        border: "1px solid black",
      },
    },
  };

  const columns = [
    {
      name: "NAME",
      // selector: "name",
      selector: (row) => row.name,
      sortable: true,
      width: "9%",
    },
    {
      name: "IP",
      // selector: "ip",
      selector: (row) => row.ip,
      sortable: true,
      compact: true,
    },
    {
      name: "PREFIX",
      // selector: "prefix",
      selector: (row) => row.prefix,
      sortable: true,
    },
    {
      name: "CREATED DATE",
      // selector: "createdAt",
      selector: (row) => row.createdAt,
      sortable: true,
    },
    {
      name: "MODIFIED DATE",
      // selector: "updatedAt",
      selector: (row) => row.updatedAt,
      sortable: true,
    },
    {
      name: "ACTION",
      center: true,
      sortable: false,
      cell: (row) => (
        <button
          type="button"
          className="btn btn-sm btn-outline-warning"
          onClick={() => handleDelete(row.id)}
        >
          <i className="fa-regular fa-trash-can"></i>
        </button>
      ),
    },
  ];

  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Providers</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Profile</a>
              </li>
              <li className="breadcrumb-item active">IP Settings</li>
            </ol>
          </nav>
        </div>
        <section>
          <div className="card">
            <div className="card-body mt-3">
              <h1></h1>
              {/* Bordered tabs Justified */}
              <div className="container-fluid">
                <div className="row">
                  <div className="col-6 d-flex justify-content-start ">
                    {/* <h5 className="text-left" >
                      Customer
                    </h5> */}
                  </div>
                </div>
                <div className="container-fluid mt-4 text-center">
                  <div className="row ">
                    <div className="col-6 d-flex justify-content-start ">
                      <button
                        type="button"
                        class="btn btn-warning my-2"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <i class="fa-solid fa-circle-plus"></i> Add
                      </button>
                    </div>
                    <div className="col-6 d-flex justify-content-start">
                      <li
                        className="nav-item flex-fill d-flex justify-content-end "
                        role="presentation"
                      >
                        <input
                          className="form-control w-50 rounded-0"
                          type="text"
                          placeholder="Search"
                          value={searchText} // Controlled input value
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                        <button type="submit" class="btn btn-primary rounded-0">
                          <i class="fa fa-search"></i>
                        </button>
                      </li>
                    </div>
                  </div>
                </div>

                <div className="container-fluid mt-4 text-center">
                  <div className="row ">
                    <div className="col-lg-12 p-4">
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse "
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="row mb-3">
                                <label
                                  htmlFor="inputEmail"
                                  className="col-sm-2 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                                >
                                  Name:
                                </label>
                                {/* <div className="col-sm-10">
                                  <input
                                    type="text"
                                    className="form-control w-100"
                                    // value={fromDate}
                                    // onChange={handleFromDate}
                                    id="text"
                                    placeholder=""
                                    required=""
                                  />
                                </div> */}
                                <div className="col-sm-10">
                                  <input
                                    type="text"
                                    className="form-control w-100"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Enter Name"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="row mb-3">
                                <label
                                  htmlFor="ip"
                                  className="col-sm-2 col-form-label d-flex d-flex justify-content-start "
                                >
                                  IP*:
                                </label>
                                {/* <div className="col-sm-10">
                                  <input
                                    type="text"
                                    className="form-control w-100"
                                    // value={toDate}
                                    // onChange={handleToDate}
                                    id="text"
                                    placeholder=""
                                    required=""
                                  />
                                </div> */}
                                <div className="col-sm-10">
                                  <input
                                    type="text"
                                    className={`form-control w-100 ${
                                      validateField("ip") ? "is-invalid" : ""
                                    }`}
                                    id="ip"
                                    name="ip"
                                    value={formData.ip}
                                    onChange={handleChange}
                                    placeholder="Enter IP"
                                    required=""
                                  />
                                  {/* Validation message */}
                                  {validateField("ip") && (
                                    <div className="invalid-feedback">
                                      {validateField("ip")}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="row mb-3">
                                <label
                                  htmlFor="inputEmail"
                                  className="col-sm-2 col-form-label d-flex d-flex justify-content-start "
                                >
                                  Prefix:
                                </label>
                                {/* <div className="col-sm-10">
                                  <input
                                    type="text"
                                    className="form-control w-100"
                                    // value={countryCode}
                                    // onChange ={handleCountryCode}
                                    id="country"
                                    placeholder=""
                                    required=""
                                  />
                                </div> */}
                                <div className="col-sm-10">
                                  <input
                                    type="text"
                                    className="form-control w-100"
                                    name="prefix"
                                    value={formData.prefix}
                                    onChange={handleChange}
                                    placeholder="Enter Prefix"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row my-4">
                              <div className="col-12 d-flex justify-content-Start">
                                <button
                                  type="button"
                                  class="btn btn-success px-5"
                                  // onClick={handleSearch}
                                  onClick={handleSave}
                                >
                                  {isLoading ? "Saving..." : "Save"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container-fluid mt-4 text-center">
                  <div className="row">
                    <div
                      className="tab-content"
                      id="borderedTabJustifiedContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="boredered-justified-campaign"
                        role="tabpanel"
                        aria-labelledby="campaign-tab"
                      >
                        <div className="card" style={{ boxShadow: "none" }}>
                          <div
                            className="card-body"
                            style={{ padding: 0, overflowX: "auto" }}
                          >
                            {isLoading && (
                              <div
                                className="d-flex justify-content-center my-5"
                                style={{ marginTop: "20px" }}
                              >
                                <ReactLoading
                                  type="spokes"
                                  color="grey"
                                  height={50}
                                  width={50}
                                />
                              </div>
                            )}
                            {!isLoading && (
                              <div className="table-responsive">
                                <div className="main">
                                  {/* <DataTableExtensions {...tableData}> */}
                                  <DataTable
                                    columns={columns}
                                    // data={ipData} // Use the fetched data from the API here
                                    data={filteredData}
                                    noHeader
                                    defaultSortField="id"
                                    defaultSortAsc={true}
                                    pagination
                                    highlightOnHover
                                    dense
                                    customStyles={customStyles}
                                  />
                                  {/* </DataTableExtensions> */}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ReportList;
