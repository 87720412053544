import React, { useState } from "react";
import { apicolumns } from "../lists/CustomerData";
import DataTable from "react-data-table-component";

function Api() {
  const [apiUrl, setApiUrl] = useState("");
  const [apiName, setApiName] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const handleInputChange = (event) => {
    setApiUrl(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("API URL submitted:", apiUrl);

    // Fetch API name using apiUrl
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setApiName(data.name); // Assuming API response has a 'name' property
        // Here you can further process the API data if needed
        setFilteredData([data]); // Setting API data to the table
      })
      .catch((error) => {
        console.error("Error fetching API:", error);
        setApiName(""); // Clearing API name if API fetch fails
        setFilteredData([]); // Clearing table data if API fetch fails
      });
  };

  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="breadcrumb-item active">API</li>
            </ol>
          </nav>
        </div>
        <section>
          <div className="card">
            <div className="card-body mt-3">
              <h1></h1>
              {/* Bordered tabs Justified */}
              <div className="container-fluid">
                <div className="row">
                  <div className="col-6 d-flex justify-content-start ">
                    <h5 className="text-left">API</h5>
                    <div className="container mt-5">
                      <h2>Add API</h2>
                      <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <label
                            htmlFor="apiInput"
                            className="form-label w-100 d-flex justify-content-start"
                          >
                            Enter API URL:
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            id="apiInput"
                            name="apiInput"
                            value={apiUrl}
                            onChange={handleInputChange}
                          />
                        </div>
                        <button className="btn btn-primary w-25 d-flex justify-content-center">
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="col-6 d-flex justify-content-start ">
                    <h5 className="text-left">API</h5>
                    <div className="container mt-5">
                      <h2>Add Data</h2>
                      <DataTable
                        columns={apicolumns}
                        data={filteredData}
                        noHeader
                        defaultSortField="id"
                        // sortIcon={<SortIcon />}
                        defaultSortAsc={true}
                        pagination
                        highlightOnHover
                        dense
                      />
                    </div>
                  </div>
                </div>

                <div className="container-fluid mt-4 text-center">
                  <div className="row">
                    <div
                      className="tab-content"
                      id="borderedTabJustifiedContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="boredered-justified-campaign"
                        role="tabpanel"
                        aria-labelledby="campaign-tab"
                      >
                        <div className="card" style={{ boxShadow: "none" }}>
                          <div
                            className="card-body"
                            style={{ padding: 0, overflowX: "auto" }}
                          >
                            <div className="table-responsive">
                              <div className="main">
                                {/* <DataTable
                                  columns={columns}
                                  data={filteredData}
                                  noHeader
                                  defaultSortField="id"
                                  // sortIcon={<SortIcon />}
                                  defaultSortAsc={true}
                                  pagination
                                  highlightOnHover
                                  dense
                                  customStyles={customStyles}
                                /> */}
                                {/* </DataTableExtensions> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Api;
