import React, { useState } from "react";
import { Link } from "react-router-dom";
import { sipcolumns, sipdata } from "./CustomerData";
import DataTable from "react-data-table-component";
import yourDocumentPDF from './eng_10601_56_2324b.pdf'; // Adjust the path accordingly
import { CSVLink } from 'react-csv';
function Sipdata() {

  const [accountSearch, setAccountSearch] = useState('');
  const [fnameSearch, setFnameSearch] = useState('');
  const [lnameSearch, setLnameSearch] = useState('');
  const [emailSearch, setEmailSearch] = useState('');
  const [companySearch, setCompanySearch] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [status, setStatus] = useState('');
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [filteredData, setFilteredData] = useState(sipdata);

  const handleFname = (e) => {
    setFnameSearch(e.target.value);
  };
  const handleLname = (e) => {
    setLnameSearch(e.target.value);
  };
  const handleEmail = (e) => {
    setEmailSearch(e.target.value);
  };
  const handleCompany = (e) => {
    setCompanySearch(e.target.value);
  };
  const handleAccount = (e) => {
    setAccountSearch(e.target.value)
  };
  const handleStartDate = (e) => {
    setStartDate(e.target.value)
  };
  const handleEndDate = (e) => {
    setEndDate(e.target.value)
  };
  const handleStatus = (e) => {
    setStatus(e.target.value)
  };
  // const handleSearch = () => {
  //   setSearchTrigger(true)
  // };
  const handleClear = () => {
    setAccountSearch('');
    setFnameSearch('');
    setLnameSearch('');
    setCompanySearch('');
    setStartDate('');
    setEndDate('');
    setEmailSearch('');
    setStatus('');
    setSearchTrigger(false);
    setFilteredData(sipdata);
  }


  const handleSearch = () => {
    setSearchTrigger(true);

    const newData = Sipdata.filter(
      (item) =>
        (!status || item.status.includes(status)) &&
        (!fnameSearch || item.firstname.toLowerCase().includes(fnameSearch.toLowerCase())) &&
        (!lnameSearch || item.lastname.toLowerCase().includes(lnameSearch.toLowerCase())) &&
        (!companySearch || item.company.toLowerCase().includes(companySearch.toLowerCase())) &&
        (!startDate || item.createddate.toLowerCase().includes(startDate.toLowerCase())) &&
        (!endDate || item.expirydate.toLowerCase().includes(endDate.toLowerCase())) &&
        (!accountSearch || item.account.toString().includes(accountSearch))
    );
    // console.log("Filtered Data:", newData);

    setFilteredData(newData);
  };

  const handleExportClick = () => {
    // Export logic here
    console.log('Exporting sipdata:', filteredData);
  };


  const handleClick = () => {

    // Open a new tab with the document URL
    window.open(yourDocumentPDF, '_blank');
  };

  const customStyles = {
    table: {
      style: {
        borderCollapse: 'collapse',
        border: '1px solid black', // Set the header border
      },
    },
    rows: {
      style: {
        borderCollapse: 'collapse',
      },
    },
    columns: {
      style: {
        borderCollapse: 'collapse',
      },
    },
    header: {
      style: {
        border: '1px solid black', // Set the header border
      },
    },
  }

  return (


    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Dashboard</a>
              </li>
              <li className="breadcrumb-item active">
                Customer
              </li>
            </ol>
          </nav>
        </div>
        <section>
          <div className="card">
            <div className="card-body mt-3">
              <h1></h1>
              {/* Bordered tabs Justified */}
              <div className="container-fluid">
                <div className="row">
                  <div className="col-6 d-flex justify-content-start ">
                    <h5 className="text-left" >
                      Customer
                    </h5>
                  </div>
                  <div className="col-12 d-flex justify-content-end mb-3">
                    <button type="button" class="btn btn-warning my-2" data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne">Search</button>

                  </div>
                  <div className="col-lg-12 p-4">
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Account:
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={accountSearch}
                                  onChange={handleAccount}
                                  id="account"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                First Name
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={fnameSearch}
                                  onChange={handleFname}
                                  id="firstname"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Last Name
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={lnameSearch}
                                  onChange={handleLname}
                                  id="lastname"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Company
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={companySearch}
                                  onChange={handleCompany}
                                  id="company"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>

                          </div>
                          <div className="col-lg-6">
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Email
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={emailSearch}
                                  onChange={handleEmail}
                                  id="email"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Start Date:
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="date"
                                  className="form-control w-100"
                                  value={startDate}
                                  onChange={handleStartDate}
                                  id="createddate"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                End Date
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="date"
                                  className="form-control w-100"
                                  value={endDate}
                                  onChange={handleEndDate}
                                  id="expirydate"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Status
                              </label>
                              <div className="col-sm-6">
                                <select
                                  class="form-select form-select-md"
                                  aria-label=".form-select-lg example"
                                  id="status"
                                  value={status}
                                  onChange={handleStatus}
                                >
                                  <option value="">--Select--</option>
                                  <option value="Active">Active</option>
                                  <option value="Inactive">Inactive</option>
                                </select>
                              </div>
                            </div>
                            <div className="row my-4">
                              <div className="col-12 d-flex justify-content-evenly">
                                <button type="button" class="btn btn-success px-5" onClick={handleSearch}>Search</button>
                                <button type="button" class="btn btn-primary px-5" onClick={handleClear}>Clear</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container-fluid mt-4 text-center">
                  <div className="row">
                    <div className="col-12 col-md-11 d-flex justify-content-between flex-wrap">
                      <Link to="/live-calls">
                        <button type="submit" className="btn btn-sm btn-outline-warning mb-2">
                          <i className="fa-solid fa-circle-plus"></i> Create Customer
                        </button>
                      </Link>
                      <button type="submit" className="btn btn-sm btn-outline-warning mb-2">
                        <i className="fa-solid fa-circle-plus"></i> Mass Create
                      </button>
                      <Link to="/Import-page">
                        <button type="submit" className="btn btn-sm btn-outline-warning mb-2">
                          <i className="fa-solid fa-upload"></i> Import Customer
                        </button>
                      </Link>
                      <button type="submit" className="btn btn-sm btn-outline-info mb-2">
                        <i className="fa-solid fa-circle-plus"></i> Create Provider
                      </button>
                      <CSVLink
                        data={filteredData}
                        filename="filtered_data.csv"
                        className="btn btn-sm btn-outline-primary mb-2"
                        onClick={handleExportClick}
                      >
                        <i className="fa-solid fa-download"></i> Export
                      </CSVLink>
                      <button type="submit" className="btn btn-sm btn-outline-danger mb-2">
                        <i className="fa-solid fa-circle-xmark"></i> Delete
                      </button>
                      <button
                        type="submit"
                        className="btn btn-sm btn-outline-warning mb-2"
                        onClick={handleClick}
                      >
                        <i className="fa-solid fa-mobile"></i> Open Document
                      </button>
                    </div>
                  </div>
                </div>
                <div className="container-fluid mt-4 text-center">
                  <div className="row">
                    <div className="tab-content" id="borderedTabJustifiedContent">
                      <div className="tab-pane fade show active" id="boredered-justified-campaign" role="tabpanel" aria-labelledby="campaign-tab">
                        <div className="card" style={{ boxShadow: "none" }}>
                          <div className="card-body" style={{ padding: 0, overflowX: "auto" }}>
                            <div className="table-responsive">
                              <div className="main">
                                {/* <DataTableExtensions {...tableData}> */}
                                <DataTable
                                  columns={sipcolumns}
                                  data={filteredData}
                                  noHeader
                                  defaultSortField="id"
                                  // sortIcon={<SortIcon />}
                                  defaultSortAsc={true}
                                  pagination
                                  highlightOnHover
                                  dense
                                  customStyles={customStyles}
                                />
                                {/* </DataTableExtensions> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default Sipdata