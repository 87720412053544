import React, { useState, useEffect ,useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import { Country, City } from "country-state-city";
import { CallPOSTAPI } from "../helper/Constants";

function EditDID() {
    const location = useLocation();
    const rowData = location.state;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    did: rowData.did,
    country: rowData.country,
    city: rowData.city,
    province: rowData.province,
    provider: rowData.provider,
    connect_cost: rowData.connect_cost,
    included_seconds: rowData.included_seconds,
    per_minute_cost: rowData.per_minute_cost,
    initial_increment: rowData.initial_increment,
    increment: rowData.increment,
    account: rowData.account,
    billing_connection_cost: rowData.billing_connection_cost,
    billing_included_seconds: rowData.billing_included_seconds,
    billing_per_minute_cost: rowData.billing_per_minute_cost,
    billing_initial_increment: rowData.billing_initial_increment,
    billing_increment: rowData.billing_increment,
    setup_fee: rowData.setup_fee,
    monthly_fee: rowData.monthly_fee,
    call_time_out: rowData.call_time_out,
    call_type: rowData.call_type,
    destination: rowData.destination,
    concurrent_calls: rowData.concurrent_calls,
    status: rowData.status,
  });

  useEffect(() => {
    const fetchCountries = async () => {
      const allCountries = Country.getAllCountries();
      setCountries(allCountries);
    };
    fetchCountries();

    if (selectedCountry) {
      const countryId = parseInt(selectedCountry);
      const countryCities = City.getCitiesOfCountry(countryId);
      setCities(countryCities);
    }
  }, [selectedCountry]);

//   const handleCountryChange = (e) => {
//     setSelectedCountry(e.target.value);

//     const countryId = parseInt(e.target.value);
//     const countryCities = City.getCitiesOfCountry(countryId);
//     setCities(countryCities);
//   };

  const handleEdit = async (event) => {
    event.preventDefault();

    try {
      setIsSubmitted(true);

      const invalidField = getFirstInvalidField();
      if (invalidField) {
        invalidField.scrollIntoView({ behavior: "smooth", block: "center" });
        return;
      }

      setIsLoading(true);
      const response = await CallPOSTAPI(`api/update-did/${rowData.did_id}`, formData);
      if (response.status) {
        toast.success("Data edited successfully.");
        navigate("/purchase-number-list");
      } else {
        toast.error("Failed to edit data.");
        console.error("Failed to edit data.");
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again later.");
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getFirstInvalidField = () => {
    const fieldsToCheck = [
      "did",
      "country",
      "province",
      "per_minute_cost",
      "initial_increment",
      "increment",
    ]; // Add other fields to check if needed

    for (const field of fieldsToCheck) {
      if (!formData[field]) {
        return formRef.current.querySelector(`#${field}`);
      }
    }

    return null;
  };

  const validateField = (fieldName) => {
    if (isSubmitted && !formData[fieldName]) {
      return "This field is required";
    }
    return "";
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    navigate("/purchase-number-list");
  };

  return (
    <>
      <main id="main" className="main">
        <section>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Edit DID</h5>
              {/* Bordered Tabs Justified */}
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <form onSubmit={handleEdit} ref={formRef}>
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-6">
                            <h4 className="mb-3">DID Information</h4>
                            <div className="row mb-3">
                              <label
                                htmlFor="did"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                DID*
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className={`form-control w-100 ${
                                    validateField("did") ? "is-invalid" : ""
                                  }`}
                                  value={formData.did}
                                  onChange={handleChange}
                                  id="did"
                                  name="did"
                                  placeholder=""
                                  required=""
                                />
                                {/* Validation message */}
                                {validateField("did") && (
                                  <div className="invalid-feedback">
                                    {validateField("did")}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label
                                htmlFor="country"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Country*
                              </label>
                              <div className="col-sm-6">
                                <Form.Select
                                  className={`form-select form-select-md ${
                                    validateField("country") ? "is-invalid" : ""
                                  }`}
                                  value={formData.country}
                                  id="country"
                                  name="country"
                                  onChange={handleChange}
                                >
                                  <option value="">Select Country</option>
                                  {countries.map((country) => (
                                    <option
                                      key={country.isoCode}
                                      value={country.id}
                                    >
                                      {country.name}
                                    </option>
                                  ))}
                                </Form.Select>
                                {/* Validation message */}
                                {validateField("country") && (
                                  <div className="invalid-feedback">
                                    {validateField("country")}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="city"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                City
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.city}
                                  onChange={handleChange}
                                  id="city"
                                  name="city"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label
                                htmlFor="province"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Province*
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className={`form-control w-100 ${
                                    validateField("province") ? "is-invalid" : ""
                                  }`}
                                  value={formData.province}
                                  onChange={handleChange}
                                  id="province"
                                  name="province"
                                  placeholder=""
                                  required=""
                                />
                                {/* Validation message */}
                                {validateField("province") && (
                                  <div className="invalid-feedback">
                                    {validateField("province")}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="provider"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Provider
                              </label>
                              <div className="col-sm-6">
                                <select
                                  className="form-select form-select-md"
                                  aria-label=".form-select-lg example"
                                  value={formData.provider}
                                  onChange={handleChange}
                                  id="provider"
                                  name="provider"
                                >
                                  <option value={true}>Yes</option>
                                  <option value={false}>No</option>
                                </select>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="connect_cost"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Connect Cost
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.connect_cost}
                                  onChange={handleChange}
                                  id="connect_cost"
                                  name="connect_cost"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="included_seconds"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Included Seconds
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.included_seconds}
                                  onChange={handleChange}
                                  id="included_seconds"
                                  name="included_seconds"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="per_minute_cost"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Per minute Cost*
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className={`form-control w-100 ${
                                    validateField("per_minute_cost") ? "is-invalid" : ""
                                  }`}
                                  value={formData.per_minute_cost}
                                  onChange={handleChange}
                                  id="per_minute_cost"
                                  name="per_minute_cost"
                                  placeholder=""
                                  required=""
                                />
                                {/* Validation message */}
                                {validateField("per_minute_cost") && (
                                  <div className="invalid-feedback">
                                    {validateField("per_minute_cost")}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="initial_increment"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Initial Increment*
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className={`form-control w-100 ${
                                    validateField("initial_increment") ? "is-invalid" : ""
                                  }`}
                                  value={formData.initial_increment}
                                  onChange={handleChange}
                                  id="initial_increment"
                                  name="initial_increment"
                                  placeholder=""
                                  required=""
                                />
                                {/* Validation message */}
                                {validateField("initial_increment") && (
                                  <div className="invalid-feedback">
                                    {validateField("initial_increment")}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="increment"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Increment*
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className={`form-control w-100 ${
                                    validateField("increment") ? "is-invalid" : ""
                                  }`}
                                  value={formData.increment}
                                  onChange={handleChange}
                                  id="increment"
                                  name="increment"
                                  placeholder=""
                                  required=""
                                />
                                {/* Validation message */}
                                {validateField("increment") && (
                                  <div className="invalid-feedback">
                                    {validateField("increment")}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <h4 className="mb-3">Billing Information</h4>
                            <div className="row mb-3">
                              <label
                                htmlFor="account"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Account
                              </label>
                              <div className="col-sm-6">
                                <select
                                  className="form-select form-select-md"
                                  aria-label=".form-select-lg example"
                                  value={formData.account}
                                  onChange={handleChange}
                                  name="account"
                                  id="account"
                                >
                                  <option value={true}>Yes</option>
                                  <option value={false}>No</option>
                                </select>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="billing_connection_cost"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Connection Cost
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.billing_connection_cost}
                                  onChange={handleChange}
                                  id="billing_connection_cost"
                                  name="billing_connection_cost"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="billing_included_seconds"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Included Seconds
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.billing_included_seconds}
                                  onChange={handleChange}
                                  id="billing_included_seconds"
                                  name="billing_included_seconds"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="billing_per_minute_cost"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Per Minute Cost
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.billing_per_minute_cost}
                                  onChange={handleChange}
                                  id="billing_per_minute_cost"
                                  name="billing_per_minute_cost"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label
                                htmlFor="billing_initial_increment"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Initial Increment
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.billing_initial_increment}
                                  onChange={handleChange}
                                  id="billing_initial_increment"
                                  name="billing_initial_increment"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="billing_increment"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Increment
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.billing_increment}
                                  onChange={handleChange}
                                  id="billing_increment"
                                  name="billing_increment"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label
                                htmlFor="setup_fee"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Setup Fee
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.setup_fee}
                                  onChange={handleChange}
                                  id="setup_fee"
                                  name="setup_fee"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="monthly_fee"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Monthly Fee
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.monthly_fee}
                                  onChange={handleChange}
                                  id="monthly_fee"
                                  name="monthly_fee"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="call_time_out"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Call TimeOut(sec.)
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.call_time_out}
                                  onChange={handleChange}
                                  id="call_time_out"
                                  name="call_time_out"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div>
                              <h4 className="my-4">DID Setting</h4>
                              <div className="row mb-3">
                                <label
                                  htmlFor="call_type"
                                  className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                                >
                                  Call Type
                                </label>
                                <div className="col-sm-6">
                                  <select
                                    class="form-select form-select-md"
                                    aria-label=".form-select-lg example"
                                    id="call_type"
                                    name="call_type"
                                    value={formData.call_type}
                                  >
                                    <option value={true} selected>Yes</option>
                                    <option value={false}>No</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <label
                                  htmlFor="destination"
                                  className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                                >
                                  Destination
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    className="form-control w-100"
                                    value={formData.destination}
                                    onChange={handleChange}
                                    id="destination"
                                    name="destination"
                                    placeholder=""
                                    required=""
                                  />
                                </div>
                              </div>
                              <div className="row mb-3">
                                <label
                                  htmlFor="concurrent_calls"
                                  className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                                >
                                  Concurrent Calls
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    className="form-control w-100"
                                    value={formData.concurrent_calls}
                                    onChange={handleChange}
                                    id="concurrent_calls"
                                    name="concurrent_calls"
                                    placeholder=""
                                    required=""
                                  />
                                </div>
                              </div>
                              <div className="row mb-3">
                                <label
                                  htmlFor="status"
                                  className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                                >
                                  Status{" "}
                                </label>
                                <div className="col-sm-6">
                                  <select
                                    class="form-select form-select-md"
                                    aria-label=".form-select-lg example"
                                    value={formData.status}
                                    onChange={handleChange}
                                    id="status"
                                    name="status"
                                  >
                                    <option value={true} selected>
                                      Active
                                    </option>
                                    <option value={false}>Inactive</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-5 m-2 d-flex justify-content-end">
                          <button
                            type="submit"
                            className={`btn btn-success px-5 ${
                              isLoading ? "fade-bg" : ""
                            }`}
                          >
                            {isLoading ? "Edited..." : "Edit"}
                          </button>
                        </div>
                        <div className="col-sm-6 m-2 d-flex justify-content-start">
                          <button
                            type="button"
                            className="btn btn-primary px-5"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-6"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default EditDID;
