import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import ReactLoading from "react-loading";

const EditNumberModal = ({ show, onHide, phoneData, onUpdateNumber, isLoading }) => {
  const [formData, setFormData] = useState({
    mobile_number: "",
    mobile_ip: "",
    country: "",
    city: "",
    status: 0,
    is_local: 0,
  });

  useEffect(() => {
    if (phoneData) {
      setFormData(phoneData);
    }
  }, [phoneData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? (checked ? 1 : 0) : value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    onUpdateNumber(formData);
  };

  return (
    <Modal 
    aria-labelledby="contained-modal-title-vcenter"
    centered 
    show={show} 
    onHide={onHide} 
    backdrop="static"
    dialogClassName="custom-modal-width" 
  >
      <Modal.Header closeButton>
        <Modal.Title>Edit Phone Number</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="mobile_number" className="mb-3">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              type="text"
              name="mobile_number"
              value={formData.mobile_number}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="mobile_ip" className="mb-3">
            <Form.Label>Mobile IP</Form.Label>
            <Form.Control
              type="text"
              name="mobile_ip"
              value={formData.mobile_ip}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="country" className="mb-3">
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="text"
              name="country"
              value={formData.country}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="city" className="mb-3">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="is_local" className="mb-3">
            <Form.Check
              type="switch"
              label="Is Local"
              name="is_local"
              checked={formData.is_local === 1}
              onChange={handleChange}
            />
          </Form.Group>

          <Button variant="secondary" onClick={onHide}>Close</Button>{" "}
          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? <ReactLoading type="spin" color="white" height={20} width={20} /> : "Save Changes"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditNumberModal;
