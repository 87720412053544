import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import { Country, City } from "country-state-city";
import { CallGETAPI, CallPOSTAPI } from "../helper/Constants";
import Select, { components } from "react-select";

// Custom ValueContainer component
const ValueContainer = ({ children, getValue, ...props }) => {
  const selectedValues = getValue();
  const selectedCount = selectedValues.length;

  return (
    <components.ValueContainer {...props}>
      {selectedCount > 2 ? (
        <div>
          {selectedValues[0].label}, {selectedValues[1].label} ... (
          {selectedCount} selected)
        </div>
      ) : (
        children
      )}
    </components.ValueContainer>
  );
};

function CreateDID() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const formRef = useRef(null);
  const [dids, setDids] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedDids, setSelectedDids] = useState([]);
  const [allDids, setAllDids] = useState([]);

  const [formData, setFormData] = useState({
    did: "",
    country: "",
    city: "",
    province: "",
    provider: true,
    connect_cost: "",
    included_seconds: "",
    per_minute_cost: "",
    initial_increment: "",
    increment: "",
    account: true,
    billing_connection_cost: "",
    billing_included_seconds: "",
    billing_per_minute_cost: "",
    billing_initial_increment: "",
    billing_increment: "",
    setup_fee: "",
    monthly_fee: "",
    call_time_out: "",
    call_type: true,
    destination: "",
    concurrent_calls: "",
    user_id: "",
    status: true,
  });

  useEffect(() => {
    const fetchCountries = async () => {
      const allCountries = Country.getAllCountries();
      setCountries(allCountries);
    };
    fetchCountries();

    if (selectedCountry) {
      const countryId = parseInt(selectedCountry);
      const countryCities = City.getCitiesOfCountry(countryId);
      setCities(countryCities);
    }
  }, [selectedCountry]);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);

    const countryId = parseInt(e.target.value);
    const countryCities = City.getCitiesOfCountry(countryId);
    setCities(countryCities);
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   console.log("Submitting formData:", formData);

  //   try {
  //     setIsSubmitted(true);

  //     const invalidField = getFirstInvalidField();
  //     if (invalidField) {
  //       invalidField.scrollIntoView({ behavior: "smooth", block: "center" });
  //       return;
  //     }

  //     setIsLoading(true);

  //     let apiEndpoint;
  //     let payload;
  //     if (formData.user_id && formData.did) {
  //       apiEndpoint = "api/create-purchase-number-v1";
  //       payload = { ...formData, number: formData.did };
  //     } else if (formData.did) {
  //       apiEndpoint = "api/create-did";
  //       payload = { ...formData };
  //       delete payload.number;
  //       delete payload.user_id;
  //     } else {
  //       toast.error("DID is required.");
  //       setIsLoading(false);
  //       return;
  //     }

  //     const response = await CallPOSTAPI(apiEndpoint, payload);
  //     if (response.status) {
  //       console.log("Data saved successfully!");
  //       setFormData({
  //         did: "",
  //         country: "",
  //         city: "",
  //         province: "",
  //         provider: true,
  //         connect_cost: "",
  //         included_seconds: "",
  //         per_minute_cost: "",
  //         initial_increment: "",
  //         increment: "",
  //         account: true,
  //         billing_connection_cost: "",
  //         billing_included_seconds: "",
  //         billing_per_minute_cost: "",
  //         billing_initial_increment: "",
  //         billing_increment: "",
  //         setup_fee: "",
  //         monthly_fee: "",
  //         call_time_out: "",
  //         call_type: true,
  //         destination: "",
  //         concurrent_calls: "",
  //         user_id: "",
  //         status: true,
  //       });
  //       toast.success("Data saved successfully.");
  //       navigate("/purchase-number-list");
  //     } else {
  //       toast.error("Failed to save data.");
  //       console.error("Failed to save data.");
  //     }
  //   } catch (error) {
  //     toast.error("An unexpected error occurred. Please try again later.");
  //     console.error("Error:", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Submitting formData:", formData);

    try {
      setIsSubmitted(true);

      const invalidField = getFirstInvalidField();
      if (invalidField) {
        invalidField.scrollIntoView({ behavior: "smooth", block: "center" });
        return;
      }

      setIsLoading(true);

      let apiEndpoint;
      let payload;
      if (formData.user_id && selectedDids.length > 0) {
        apiEndpoint = "api/create-purchase-number-v1";
        payload = selectedDids.map((did) => ({
          ...formData,
          number: did.value,
          did: did.value,
        }));
      } else if (selectedDids.length > 0) {
        apiEndpoint = "api/create-did";
        payload = selectedDids.map((did) => ({
          ...formData,
          did: did.value,
        }));
        delete payload[0].number;
        delete payload[0].user_id;
      } else {
        toast.error("At least one DID is required.");
        setIsLoading(false);
        return;
      }

      const response = await CallPOSTAPI(apiEndpoint, payload);
      if (response.status) {
        console.log("Data saved successfully!");
        setFormData({
          did: "",
          country: "",
          city: "",
          province: "",
          provider: true,
          connect_cost: "",
          included_seconds: "",
          per_minute_cost: "",
          initial_increment: "",
          increment: "",
          account: true,
          billing_connection_cost: "",
          billing_included_seconds: "",
          billing_per_minute_cost: "",
          billing_initial_increment: "",
          billing_increment: "",
          setup_fee: "",
          monthly_fee: "",
          call_time_out: "",
          call_type: true,
          destination: "",
          concurrent_calls: "",
          user_id: "",
          status: true,
        });
        setSelectedDids([]);
        toast.success("Data saved successfully.");
        navigate("/purchase-number-list");
      } else {
        toast.error("Failed to save data.");
        console.error("Failed to save data.");
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again later.");
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getFirstInvalidField = () => {
    const fieldsToCheck = [
      "did",
      "country",
      "province",
      "per_minute_cost",
      "initial_increment",
      "increment",
    ]; // Add other fields to check if needed

    for (const field of fieldsToCheck) {
      if (!formData[field]) {
        return formRef.current.querySelector(`#${field}`);
      }
    }

    return null;
  };

  // Validation function for a specific field
  const validateField = (fieldName) => {
    if (isSubmitted && !formData[fieldName]) {
      return "This field is required";
    }
    return "";
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    setFormData({
      did: "",
      country: "",
      city: "",
      province: "",
      provider: true,
      connect_cost: "",
      included_seconds: "",
      per_minute_cost: "",
      initial_increment: "",
      increment: "",
      account: true,
      billing_connection_cost: "",
      billing_included_seconds: "",
      billing_per_minute_cost: "",
      billing_initial_increment: "",
      billing_increment: "",
      setup_fee: "",
      monthly_fee: "",
      call_time_out: "",
      call_type: true,
      destination: "",
      concurrent_calls: "",
      status: true,
    });
    navigate("/purchase-number-list");
  };

  useEffect(() => {
    console.log("UseEffect Calling");
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await CallGETAPI(`api/users`);
      const result = response?.data?.data;
      if (response.status) {
        setUsers(result);
      } else {
        setUsers([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setUsers([]); // Clear data in case of error
    }
  };

  const handleUserSelect = (selectedOption) => {
    const userId = selectedOption.value;
    console.log(userId, "check user id");
    setSelectedUser(selectedOption);
    setFormData((prevData) => ({
      ...prevData,
      user_id: userId,
    }));
  };

  const fetchDids = async () => {
    try {
      const response = await CallGETAPI(`api/available-phone-numbers`);
      const result = response?.data?.data;
      if (response.status) {
        const didOptions = result.map((did) => ({
          label: did.mobile_number,
          value: did.mobile_number,
        }));
        setDids([{ label: "All", value: "all" }, ...didOptions]);
        setAllDids(didOptions);
      } else {
        setDids([]);
        setAllDids([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDids([]);
      setAllDids([]);
    }
  };

  useEffect(() => {
    fetchDids();
  }, []);

  const handleDidChange = async (selectedOptions) => {
    let updatedSelectedDids;
    if (selectedOptions.some((option) => option.value === "all")) {
      updatedSelectedDids = allDids;
    } else {
      updatedSelectedDids = selectedOptions;
    }

    setSelectedDids(updatedSelectedDids);

    if (updatedSelectedDids.length > 0) {
      const firstDid = updatedSelectedDids[0].value;
      try {
        setIsLoading(true);
        const response = await CallGETAPI(
          `api/get-alls-phones?did=${firstDid}`
        );
        if (response.status) {
          const { country, city, mobile_ip } = response.data.data[0];
          setFormData((prevData) => ({
            ...prevData,
            country: country || "",
            city: city || "",
            destination: mobile_ip || "",
          }));
        } else {
          toast.error("Failed to fetch phone data.");
        }
      } catch (error) {
        console.error("Error fetching phone data:", error);
        toast.error("An unexpected error occurred. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <main id="main" className="main">
        <section>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Create DID</h5>
              {/* Bordered Tabs Justified */}
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <form onSubmit={handleSubmit} ref={formRef}>
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-6">
                            <h4 className="mb-3">DID Information</h4>
                            <div className="row mb-3">
                              <label
                                htmlFor="did"
                                className="col-sm-5 col-form-label d-flex justify-content-start"
                              >
                                DID*
                              </label>
                              <div className="col-sm-6">
                                <Select
                                  value={selectedDids}
                                  onChange={handleDidChange}
                                  options={dids}
                                  placeholder="Search and select DIDs"
                                  isSearchable
                                  isMulti
                                  components={{ ValueContainer }}
                                />
                                {validateField("did") && (
                                  <div className="invalid-feedback">
                                    {validateField("did")}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label
                                htmlFor="country"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Country*
                              </label>
                              <div className="col-sm-6">
                                <Form.Select
                                  className={`form-select form-select-md ${
                                    validateField("country") ? "is-invalid" : ""
                                  }`}
                                  value={formData.country}
                                  id="country"
                                  name="country"
                                  onChange={handleChange}
                                >
                                  <option value="">Select Country</option>
                                  {countries.map((country) => (
                                    <option
                                      key={country.isoCode}
                                      value={country.id}
                                    >
                                      {country.name}
                                    </option>
                                  ))}
                                </Form.Select>
                                {/* Validation message */}
                                {validateField("country") && (
                                  <div className="invalid-feedback">
                                    {validateField("country")}
                                  </div>
                                )}
                              </div>
                            </div>

                            {/* <Form.Select
                              className="custom-select"
                              value={selectedCountry}
                              onChange={handleCountryChange}
                            >
                              <option value="">Select Country</option>
                              {countries.map((country) => (
                                <option
                                  key={country.isoCode}
                                  value={country.id}
                                >
                                  {country.name}
                                </option>
                              ))}
                            </Form.Select> */}

                            <div className="row mb-3">
                              <label
                                htmlFor="city"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                City
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.city}
                                  onChange={handleChange}
                                  id="city"
                                  name="city"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label
                                htmlFor="user_id"
                                className="col-sm-5 col-form-label d-flex justify-content-start"
                              >
                                Select User
                              </label>
                              <div className="col-sm-6">
                                <Select
                                  value={selectedUser}
                                  onChange={handleUserSelect}
                                  options={users.map((user) => ({
                                    label: `${user.first_name} ${user.last_name} - ${user.user_id}`,
                                    value: user.user_id,
                                  }))}
                                  placeholder="Search and select user"
                                  isSearchable
                                />
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label
                                htmlFor="province"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Province*
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className={`form-control w-100 ${
                                    validateField("province")
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  value={formData.province}
                                  onChange={handleChange}
                                  id="province"
                                  name="province"
                                  placeholder=""
                                  required=""
                                />
                                {/* Validation message */}
                                {validateField("province") && (
                                  <div className="invalid-feedback">
                                    {validateField("province")}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="provider"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Provider
                              </label>
                              <div className="col-sm-6">
                                <select
                                  className="form-select form-select-md"
                                  aria-label=".form-select-lg example"
                                  value={formData.provider}
                                  onChange={handleChange}
                                  id="provider"
                                  name="provider"
                                >
                                  <option value={true}>Yes</option>
                                  <option value={false}>No</option>
                                </select>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="connect_cost"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Connect Cost
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.connect_cost}
                                  onChange={handleChange}
                                  id="connect_cost"
                                  name="connect_cost"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="included_seconds"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Included Seconds
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.included_seconds}
                                  onChange={handleChange}
                                  id="included_seconds"
                                  name="included_seconds"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="per_minute_cost"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Per minute Cost*
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className={`form-control w-100 ${
                                    validateField("per_minute_cost")
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  value={formData.per_minute_cost}
                                  onChange={handleChange}
                                  id="per_minute_cost"
                                  name="per_minute_cost"
                                  placeholder=""
                                  required=""
                                />
                                {/* Validation message */}
                                {validateField("per_minute_cost") && (
                                  <div className="invalid-feedback">
                                    {validateField("per_minute_cost")}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="initial_increment"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Initial Increment*
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className={`form-control w-100 ${
                                    validateField("initial_increment")
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  value={formData.initial_increment}
                                  onChange={handleChange}
                                  id="initial_increment"
                                  name="initial_increment"
                                  placeholder=""
                                  required=""
                                />
                                {/* Validation message */}
                                {validateField("initial_increment") && (
                                  <div className="invalid-feedback">
                                    {validateField("initial_increment")}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="increment"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Increment*
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className={`form-control w-100 ${
                                    validateField("increment")
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  value={formData.increment}
                                  onChange={handleChange}
                                  id="increment"
                                  name="increment"
                                  placeholder=""
                                  required=""
                                />
                                {/* Validation message */}
                                {validateField("increment") && (
                                  <div className="invalid-feedback">
                                    {validateField("increment")}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <h4 className="mb-3">Billing Information</h4>
                            <div className="row mb-3">
                              <label
                                htmlFor="account"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Account
                              </label>
                              <div className="col-sm-6">
                                <select
                                  className="form-select form-select-md"
                                  aria-label=".form-select-lg example"
                                  value={formData.account}
                                  onChange={handleChange}
                                  name="account"
                                  id="account"
                                >
                                  <option value={true}>Yes</option>
                                  <option value={false}>No</option>
                                </select>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="billing_connection_cost"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Connection Cost
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.billing_connection_cost}
                                  onChange={handleChange}
                                  id="billing_connection_cost"
                                  name="billing_connection_cost"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="billing_included_seconds"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Included Seconds
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.billing_included_seconds}
                                  onChange={handleChange}
                                  id="billing_included_seconds"
                                  name="billing_included_seconds"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="billing_per_minute_cost"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Per Minute Cost
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.billing_per_minute_cost}
                                  onChange={handleChange}
                                  id="billing_per_minute_cost"
                                  name="billing_per_minute_cost"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label
                                htmlFor="billing_initial_increment"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Initial Increment
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.billing_initial_increment}
                                  onChange={handleChange}
                                  id="billing_initial_increment"
                                  name="billing_initial_increment"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="billing_increment"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Increment
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.billing_increment}
                                  onChange={handleChange}
                                  id="billing_increment"
                                  name="billing_increment"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label
                                htmlFor="setup_fee"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Setup Fee
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.setup_fee}
                                  onChange={handleChange}
                                  id="setup_fee"
                                  name="setup_fee"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="monthly_fee"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Monthly Fee
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.monthly_fee}
                                  onChange={handleChange}
                                  id="monthly_fee"
                                  name="monthly_fee"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="call_time_out"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Call TimeOut(sec.)
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.call_time_out}
                                  onChange={handleChange}
                                  id="call_time_out"
                                  name="call_time_out"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>

                            <div>
                              <h4 className="my-4">DID Setting</h4>
                              <div className="row mb-3">
                                <label
                                  htmlFor="call_type"
                                  className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                                >
                                  Call Type
                                </label>
                                <div className="col-sm-6">
                                  <select
                                    class="form-select form-select-md"
                                    aria-label=".form-select-lg example"
                                    id="call_type"
                                    name="call_type"
                                    value={formData.call_type}
                                  >
                                    <option value={true} selected>
                                      Yes
                                    </option>
                                    <option value={false}>No</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <label
                                  htmlFor="destination"
                                  className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                                >
                                  Destination
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    className="form-control w-100"
                                    value={formData.destination}
                                    onChange={handleChange}
                                    id="destination"
                                    name="destination"
                                    placeholder=""
                                    required=""
                                  />
                                </div>
                              </div>
                              <div className="row mb-3">
                                <label
                                  htmlFor="concurrent_calls"
                                  className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                                >
                                  Concurrent Calls
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    className="form-control w-100"
                                    value={formData.concurrent_calls}
                                    onChange={handleChange}
                                    id="concurrent_calls"
                                    name="concurrent_calls"
                                    placeholder=""
                                    required=""
                                  />
                                </div>
                              </div>
                              <div className="row mb-3">
                                <label
                                  htmlFor="status"
                                  className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                                >
                                  Status{" "}
                                </label>
                                <div className="col-sm-6">
                                  <select
                                    class="form-select form-select-md"
                                    aria-label=".form-select-lg example"
                                    value={formData.status}
                                    onChange={handleChange}
                                    id="status"
                                    name="status"
                                  >
                                    <option value={true} selected>
                                      Active
                                    </option>
                                    <option value={false}>Inactive</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-5 m-2 d-flex justify-content-end">
                          <button
                            type="submit"
                            className={`btn btn-success px-5 ${
                              isLoading ? "fade-bg" : ""
                            }`}
                          >
                            {isLoading ? "Saving..." : "Save"}
                          </button>
                        </div>
                        <div className="col-sm-6 m-2 d-flex justify-content-start">
                          <button
                            type="button"
                            className="btn btn-primary px-5"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-6"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default CreateDID;
