import React from "react";

function LiveSummary() {
  return (
    <main id="main" className="main">
      <section>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Create Customer</h5>
            {/* Bordered Tabs Justified */}
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <form method="post">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-6">
                          <h4 className="mb-3">Account Profile</h4>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                            >
                              Account*:
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start"
                            >
                              Password :
                            </label>
                            <div className="col-sm-6">
                              <input
                                className="form-control w-100"
                                placeholder=""
                                type="number"
                                id="typeNumber"
                                // value={createTarget.number}
                                // onChange={handleNumberChange}
                                required=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              Pin:
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>

                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              First Name*:
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>

                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              Last Name:
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              Company:
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              Phone:
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              Moblie:
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              Email*:
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              Address1:
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              Address2:
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              City:
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              Province/State:
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <h4 className="mb-3">Account Settings</h4>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                            >
Status                            </label>
                            <div className="col-sm-6">
                              <select
                                class="form-select form-select-md"
                                aria-label=".form-select-lg example"
                              >
                                <option selected>Active</option>
                                <option value="1">Inactive</option>
                              
                              </select>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                            >
                              Allow Recoding
                            </label>
                            <div className="col-sm-6">
                              <select
                                class="form-select form-select-md"
                                aria-label=".form-select-lg example"
                              >
                                <option selected>Yes</option>
                                <option value="1">No</option>
                               
                              </select>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                            >
                              Allow IP Management
                            </label>
                            <div className="col-sm-6">
                              <select
                                class="form-select form-select-md"
                                aria-label=".form-select-lg example"
                              >
                                <option selected>Yes</option>
                                <option value="1">No</option>
                               
                              </select>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              Number Translation
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>

                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              OUT Caller id Translation
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>

                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              IN Callerid Translation
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              Concurrent calls
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              CPS
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              First Used
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              Account Valid Days
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              Expiry date
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>

                          <h4 className="my-4">Billing Setting</h4>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                            >
                              Rate Group*
                            </label>
                            <div className="col-sm-6">
                              <select
                                class="form-select form-select-md"
                                aria-label=".form-select-lg example"
                              >
                                <option selected>ONLYDID</option>
                                <option value="1">USA-TollFree</option>
                                <option value="2">USA</option>
                               
                              </select>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                            >
                              Account Type
                            </label>
                            <div className="col-sm-6">
                              <select
                                class="form-select form-select-md"
                                aria-label=".form-select-lg example"
                              >
                                <option selected>Prepaid</option>
                                <option value="1">Postpaid</option>
                              
                              </select>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                            >
                              Billing Schedule
                            </label>
                            <div className="col-sm-6">
                              <select
                                class="form-select form-select-md"
                                aria-label=".form-select-lg example"
                              >
                                <option selected>Daily</option>
                                <option value="1">Monthly</option>
                               
                              </select>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                            >
                              Billing Day
                            </label>
                            <div className="col-sm-6">
                              <select
                                class="form-select form-select-md"
                                aria-label=".form-select-lg example"
                              >
                                <option selected>1</option>
                                <option value="1">2</option>
                                <option value="2">3</option>
                                <option value="3">28</option>
                              </select>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              Balance
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              Credit Limit
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                            >
                              Allow Local Calls
                            </label>
                            <div className="col-sm-6">
                              <select
                                class="form-select form-select-md"
                                aria-label=".form-select-lg example"
                              >
                                <option selected>Yes</option>
                                <option value="1">No</option>
                               
                              </select>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              LC Charge/Min
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              Tax
                            </label>
                            <div className="col-sm-6">
                              <textarea
                                className="form-control"
                                placeholder="Leave a comment here"
                                id="floatingTextarea2"
                                style={{ height: 100 }}
                                defaultValue={""}
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                            >
                              Tax Number
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control w-100"
                                // value={createTarget.name}
                                // onChange={handleNameChange}
                                id="inputEmail"
                                name="name"
                                placeholder=""
                                required=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-5 m-2 d-flex justify-content-end">
                        <button type="submit" className="btn btn-success px-5">
                         Save
                        </button>
                      </div>
                      <div className="col-sm-6 m-2 d-flex justify-content-start">
                      <button type="submit" className="btn btn-primary px-5">
                         Cancel 
                        </button>
                      </div>
                     
                    </div>
                  </form>
                </div>
                <div className="col-lg-6"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default LiveSummary;
