import React from 'react'
import xlsx from 'xlsx'

function Importpage() {
  return (
   <>
      <main id="main" className="main">
        {/* End Page Title */}
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <form method="post">
                          <div className="container mt-5">
                            <div className="row">
                              <h4 className="mb-3 d-flex">Import Customers</h4>
                              <div className="col-lg-6">
                                <div className="row mb-3">
                                  <label
                                    htmlFor="inputEmail"
                                    className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                                  >
                                    Generate Pin:
                                  </label>
                                  <div className="col-sm-6">
                                    <select
                                      class="form-select form-select-md"
                                      aria-label=".form-select-md example"
                                    >
                                      <option selected>
                                       Yes
                                      </option>
                                      <option value="1">No</option>
                                     
                                    </select>
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <label
                                    htmlFor="inputEmail"
                                    className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                                  >
                                    Allow Recording:
                                  </label>
                                  <div className="col-sm-6">
                                    <select
                                      class="form-select form-select-md"
                                      aria-label=".form-select-md example"
                                    >
                                      <option selected>
                                        Yes
                                      </option>
                                      <option value="1">No</option>
                                     
                                    </select>
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <label
                                    htmlFor="inputEmail"
                                    className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                                  >
                                    Allow IP Management:
                                  </label>
                                  <div className="col-sm-6">
                                    <select
                                      class="form-select form-select-md"
                                      aria-label=".form-select-md example"
                                    >
                                      <option selected>
                                        Yes
                                      </option>
                                      <option value="1">No</option>
                                     
                                    </select>
                                  </div>
                                </div>

                                <div className="row mb-3">
                                  <label
                                    htmlFor="inputEmail"
                                    className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                                  >
                                    Rate Group:
                                  </label>
                                  <div className="col-sm-6">
                                    <select
                                      class="form-select form-select-md"
                                      aria-label=".form-select-md example"
                                    >
                                      <option selected>
                                       ONLYDID
                                      </option>
                                      <option value="1">USA-TollFree</option>
                                      <option value="2">USA</option>
                                      
                                    </select>
                                  </div>
                                </div>

                                <div className="row mb-3">
                                  <label
                                    htmlFor="inputEmail"
                                    className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                                  >
                                    Create Sip Device:
                                  </label>
                                  <div className="col-sm-6">
                                    <select
                                      class="form-select form-select-md"
                                      aria-label=".form-select-md example"
                                    >
                                      <option selected>
                                       No
                                      </option>
                                      <option value="1">Yes</option>
                                     
                                    </select>
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <label
                                    htmlFor="inputEmail"
                                    className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                                  >
                                    Timezone:
                                  </label>
                                  <div className="col-sm-6">
                                    <select
                                      class="form-select form-select-md"
                                      aria-label=".form-select-md example"
                                    >
                                      <option selected>
                                      Mumbai, New delhi
                                      </option>
                                      <option value="1">One</option>
                                      <option value="2">Two</option>
                                      <option value="3">Three</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <label
                                    htmlFor="inputEmail"
                                    className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                                  >
                                    Country:
                                  </label>
                                  <div className="col-sm-6">
                                    <select
                                      class="form-select form-select-md"
                                      aria-label=".form-select-md example"
                                    >
                                      <option selected>
                                      United State Of America
                                      </option>
                                      <option value="1">One</option>
                                      <option value="2">Two</option>
                                      <option value="3">Three</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <label
                                    htmlFor="inputEmail"
                                    className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                                  >
                                    Currency:
                                  </label>
                                  <div className="col-sm-6">
                                    <select
                                      class="form-select form-select-md"
                                      aria-label=".form-select-md example"
                                    >
                                      <option selected>
                                        US Doller
                                      </option>
                                      <option value="1">One</option>
                                      <option value="2">Two</option>
                                      <option value="3">Three</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <label
                                    htmlFor="formFile"
                                    className="form-label  d-flex d-flex justify-content-start col-sm-5 "
                                  >
                                    Select the file :
                                  </label>
                                  <div className="col-sm-11">
                                    <input
                                      className="form-control w-100"
                                      type="file"
                                      id="formFile"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="row mb-3">
                                  <label
                                    htmlFor="inputEmail"
                                    className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                                  >
                                    Account Type:
                                  </label>
                                  <div className="col-sm-6">
                                    <select
                                      class="form-select form-select-md"
                                      aria-label=".form-select-md example"
                                    >
                                      <option selected>
                                       Prepaid
                                      </option>
                                      <option value="1">Postpaid</option>
                                      <option value="2">Two</option>
                                      <option value="3">Three</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <label
                                    htmlFor="inputEmail"
                                    className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                                  >
                                    Billing Schedule:
                                  </label>
                                  <div className="col-sm-6">
                                    <select
                                      class="form-select form-select-md"
                                      aria-label=".form-select-md example"
                                    >
                                      <option selected>
                                       Monthly
                                      </option>
                                      <option value="1">Daily</option>
                                   
                                    </select>
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <label
                                    htmlFor="inputEmail"
                                    className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                                  >
                                    Billing Day:
                                  </label>
                                  <div className="col-sm-6">
                                    <select
                                      class="form-select form-select-md"
                                      aria-label=".form-select-md example"
                                    >
                                      <option selected>
                                       1
                                      </option>
                                      <option value="1">2</option>
                                      <option value="2">3</option>
                                      <option value="3">28</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="row mb-3">
                                  <label
                                    htmlFor="inputEmail"
                                    className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                                  >
                                    Allow Local Calls:
                                  </label>
                                  <div className="col-sm-6">
                                    <select
                                      class="form-select form-select-md"
                                      aria-label=".form-select-md example"
                                    >
                                      <option selected>
                                       Yes
                                      </option>
                                      <option value="1">No</option>
                                      <option value="2">Two</option>
                                      <option value="3">Three</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <label
                                    htmlFor="inputEmail"
                                    className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                                  >
                                    LC Charge/Min
                                  </label>
                                  <div className="col-sm-6">
                                    <input
                                      type="text"
                                      className="form-control w-100"
                                      // value={createTarget.name}
                                      // onChange={handleNameChange}
                                      id="inputEmail"
                                      name="name"
                                      placeholder=""
                                      required=""
                                    />
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <label
                                    htmlFor="inputEmail"
                                    className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                                  >
                                    Tax
                                  </label>
                                  <div className="col-sm-6">
                                    <textarea
                                      className="form-control"
                                      placeholder="Leave a comment here"
                                      id="floatingTextarea2"
                                      style={{ height: 100 }}
                                      defaultValue={""}
                                    />
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <label
                                    htmlFor="inputEmail"
                                    className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                                  >
                                    Email Alerts ?:
                                  </label>
                                  <div className="col-sm-6">
                                    <select
                                      class="form-select form-select-md"
                                      aria-label=".form-select-md example"
                                    >
                                      <option selected>
                                       Yes
                                      </option>
                                      <option value="1">No</option>
                                      <option value="2">Two</option>
                                      <option value="3">Three</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="col-lg-2">
                          
                        </div> */}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-6 d-flex justify-content-end">
                              <button
                                type="submit"
                                className="btn btn-success px-5"
                              >
                                Import
                              </button>
                            </div>
                            <div className="col-sm-6  d-flex justify-content-start">
                              <button
                                type="submit"
                                className="btn btn-primary px-5"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-lg-6"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
   </>
  )
}

export default Importpage