import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import ReactLoading from "react-loading"; 
import "../../imagecss/imagepopup.css";
Modal.setAppElement("#root");

const ImagePopup = ({ isOpen, onClose, imageSrc, title }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isOpen && imageSrc) {
      setLoading(true);
    }
  }, [isOpen, imageSrc]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Image Popup"
      className="custom-modal"
      overlayClassName="custom-overlay"
    >
      <div className="modal-header">
        <h2 className="modal-title">{title}</h2>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
      </div>
      <div className="modal-body">
        {loading && (
          <div className="spinner-container">
            <ReactLoading
              type="spin"
              color="#000"
              height={50}
              width={50}
              className="loading-spinner"
            />
          </div>
        )}
        {imageSrc ? (
          <img
            src={imageSrc}
            alt={title}
            className="popup-image"
            style={{ display: loading ? "none" : "block" }}
            onLoad={() => setLoading(false)} 
          />
        ) : (
          <p>No Image Available</p>
        )}
      </div>
    </Modal>
  );
};

export default ImagePopup;
