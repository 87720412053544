import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { CallPOSTAPI } from "../../helper/Constants";
import ReactLoading from "react-loading";

const AddNumberModal = ({ show, onHide, onAddNumber, fetchData }) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileIp, setMobileIp] = useState("");
  const [price, setPrice] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [isLocal, setIsLocal] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numberError, setNumberError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [cityError, setCityError] = useState("");


  useEffect(() => {
    if (!show) {
      setMobileNumber("");
      setMobileIp("");
      setPrice("");
      setCountry("");
      setCity("");
      setIsLocal(false);
      setIsPremium(false);
    }
  }, [show]);

  useEffect(() => {
    if (mobileNumber.startsWith('888')) {
      setIsLocal(true);
      setIsPremium(true);
    } else {
      setIsLocal(false);
      setIsPremium(false);
    }
  }, [mobileNumber]);

  const validateForm = () => {
    let isValid = true;

    if (!mobileNumber.trim()) {
      setNumberError("Mobile Number is required !");
      isValid = false;
    } else {
      setNumberError("");
    }

    if (!country.trim()) {
      setCountryError("Country is required !");
      isValid = false;
    } else {
      setCountryError("");
    }

    if (!city.trim()) {
      setCityError("City is required !");
      isValid = false;
    } else {
      setCityError("");
    }

    return isValid;
  };

  const handleAddNumber = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      setLoading(true);
      const response = await CallPOSTAPI(`api/create-phone`, {
        mobile_number: mobileNumber,
        mobile_ip: mobileIp,
        country: country,
        city: city,
        price: price,
        is_local: isLocal ? 1 : 0,
        is_premium: isPremium ? 1 : 0,
      });


      setLoading(false);
      if (response.data.status) {
        onAddNumber(response.data.data);
        toast.success("Number added successfully");
        fetchData();
        onHide();
      } else {
        toast.error("Failed to add number");
      }
    } catch (error) {
      console.error("Error adding number:", error.message);
      toast.error("Error adding number");
      setLoading(false);
    }
  };

  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
    if (e.target.value.trim()) {
      setNumberError("");
    }
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    if (e.target.value.trim()) {
      setCountryError("");
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    if (e.target.value.trim()) {
      setCityError("");
    }
  };

  return (
    <Modal 
  aria-labelledby="contained-modal-title-vcenter"
  centered 
  show={show} 
  onHide={onHide} 
  backdrop="static"
  dialogClassName="custom-modal-width" 
>
      <Modal.Header closeButton>
        <Modal.Title>Add Number</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formMobileNumber"className="mt-3">
            <Form.Label>Mobile Number*</Form.Label>
            <Form.Control
              type="text"
              className={`${numberError ? "is-invalid" : ""}`}
              placeholder="Enter mobile number"
              value={mobileNumber}
              onChange={handleMobileNumberChange}
            />
            {numberError && (
              <div className="invalid-feedback">{numberError}</div>
            )}
          </Form.Group>
          <Form.Group controlId="formMobileIp" className="mt-3">
            <Form.Label>Mobile IP</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter mobile IP"
              value={mobileIp}
              onChange={(e) => setMobileIp(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formCountry" className="mt-3">
            <Form.Label>Country*</Form.Label>
            <Form.Control
              type="text"
              className={`${countryError ? "is-invalid" : ""}`}
              placeholder="Enter Country"
              value={country}
              onChange={handleCountryChange}
            />
            {countryError && (
              <div className="invalid-feedback">{countryError}</div>
            )}
          </Form.Group>

          <Form.Group controlId="formCity" className="mt-3">
            <Form.Label>City*</Form.Label>
            <Form.Control
              type="text"
              className={`${cityError ? "is-invalid" : ""}`}
              placeholder="Enter City"
              value={city}
              onChange={handleCityChange}
            />
            {cityError && (
              <div className="invalid-feedback">{cityError}</div>
            )}
          </Form.Group>

          <Form.Group controlId="formPrice" className="mt-3">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formIsLocal" className="mt-3">
            <Form.Check 
              type="switch"
              id="is-local-switch"
              label="Is Local"
              checked={isLocal}
              onChange={(e) => setIsLocal(e.target.checked)}
              disabled={mobileNumber.startsWith('888')}
            />
          </Form.Group>

          {/* <Form.Group controlId="formIsPremium" className="mt-3">
            <Form.Check 
              type="switch"
              id="is-premium-switch"
              label="Is Premium"
              checked={isPremium}
              onChange={(e) => setIsPremium(e.target.checked)}
            />
          </Form.Group> */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        {loading ? (
          <ReactLoading type="spin" color="blue" height={30} width={30} />
        ) : (
          <Button variant="primary" onClick={handleAddNumber}>
            Add Number
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AddNumberModal;