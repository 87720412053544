import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReactLoading from "react-loading";

function ImportCSVModal({ show, onHide, onFileChange, onFileUpload }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    
    // Check if file is CSV
    if (file && file.type !== "text/csv" && file.name.split('.').pop() !== "csv") {
      setError("Please select a valid CSV file!");
      setSelectedFile(null);
      onFileChange(null); // Clear the file in parent component
      return;
    }

    setSelectedFile(file);
    onFileChange(e);
    setError(""); 
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setError("Please select a file to upload!");
      return;
    }

    setUploading(true);

    await onFileUpload(selectedFile);

    setUploading(false);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Import CSV</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input type="file" onChange={handleFileChange} accept=".csv" />
        {error && <div className="text-danger mt-2">{error}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        {uploading ? (
          <ReactLoading type="spin" color="blue" height={30} width={30} />
        ) : (
          <Button variant="primary" onClick={handleUpload}>
            Upload
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ImportCSVModal;
