import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Send, Loader } from 'lucide-react';

const ChatBot = () => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [phoneNumbers, setPhoneNumbers] = useState('918120926637');
    const [isLoading, setIsLoading] = useState(false);
    const [wsConnected, setWsConnected] = useState(false);
    const chatContainerRef = useRef(null);
    const wsRef = useRef(null);
    const reconnectTimeoutRef = useRef(null);

    useEffect(() => {
        if (phoneNumbers) {
            loadChatHistory();
            connectWebSocket();
        }
        
        // Cleanup
        return () => {
            if (wsRef.current) {
                wsRef.current.close();
            }
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
        };
    }, [phoneNumbers]);

    const connectWebSocket = () => {
        // Close existing connection if any
        if (wsRef.current) {
            wsRef.current.close();
        }

        try {
            wsRef.current = new WebSocket('ws://localhost:3004');

            wsRef.current.onopen = () => {
                console.log('WebSocket Connected');
                setWsConnected(true);
                
                // Only send registration after connection is established
                if (wsRef.current.readyState === WebSocket.OPEN) {
                    const primaryNumber = phoneNumbers.split(',')[0];
                    wsRef.current.send(JSON.stringify({
                        type: 'register',
                        phoneNumber: primaryNumber
                    }));
                }
            };

            wsRef.current.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    if (data.type === 'newMessage') {
                        setMessages(prev => [...prev, data.message]);
                        scrollToBottom();
                    }
                } catch (error) {
                    console.error('Error parsing WebSocket message:', error);
                }
            };

            wsRef.current.onclose = () => {
                console.log('WebSocket Disconnected');
                setWsConnected(false);
                
                // Clear any existing reconnection timeout
                if (reconnectTimeoutRef.current) {
                    clearTimeout(reconnectTimeoutRef.current);
                }
                
                // Set new reconnection timeout
                reconnectTimeoutRef.current = setTimeout(() => {
                    connectWebSocket();
                }, 3000);
            };

            wsRef.current.onerror = (error) => {
                console.error('WebSocket Error:', error);
                setWsConnected(false);
            };

        } catch (error) {
            console.error('Error creating WebSocket connection:', error);
            setWsConnected(false);
            
            // Attempt to reconnect
            reconnectTimeoutRef.current = setTimeout(() => {
                connectWebSocket();
            }, 3000);
        }
    };

    const loadChatHistory = async () => {
        try {
            setIsLoading(true);
            const primaryNumber = phoneNumbers.split(',')[0];
            const response = await axios.get(`http://localhost:3003/api/chat/${primaryNumber}`);
            setMessages(response.data);
        } catch (error) {
            console.error('Error loading chat history:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim()) return;

        try {
            setIsLoading(true);
            const payload = {
                to: phoneNumbers,
                text: newMessage,
                template: {
                    name: "live_pbx",
                    language: {
                        code: "en"
                    }
                }
            };

            const response = await axios.post('http://localhost:3003/api/send-bulk', payload);

            if (response.data.success) {
                const newMsg = {
                    from: 'system',
                    to: phoneNumbers,
                    content: newMessage,
                    timestamp: new Date().toISOString(),
                };

                setMessages(prev => [...prev, newMsg]);
                setNewMessage('');
                scrollToBottom();
            } else {
                console.error('Failed to send messages:', response.data.results.failed);
            }
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    return (
        <div className="flex flex-col h-screen max-w-2xl mx-auto p-4">
            <div className="mb-4">
                <input
                    type="text"
                    value={phoneNumbers}
                    onChange={(e) => setPhoneNumbers(e.target.value)}
                    placeholder="Enter phone numbers (comma-separated, e.g., 918120926637,919098201334)"
                    className="w-full p-2 border rounded"
                />
                {/* Optional: Show connection status */}
                <div className={`text-sm mt-1 ${wsConnected ? 'text-green-500' : 'text-red-500'}`}>
                    {wsConnected ? 'Connected' : 'Disconnected'}
                </div>
            </div>

            <div ref={chatContainerRef} className="flex-1 overflow-y-auto bg-gray-50 rounded-lg p-4 mb-4">
                {isLoading ? (
                    <div className="flex justify-center items-center h-full">
                        <Loader className="animate-spin" />
                    </div>
                ) : (
                    messages.map((msg, index) => (
                        <div key={index} className={`mb-4 ${msg.from === 'system' ? 'ml-auto text-right' : 'mr-auto'}`}>
                            <div className={`inline-block p-3 rounded-lg ${msg.from === 'system' ? 'bg-blue-500 text-white' : 'bg-white border'}`}>
                                {msg.content}
                            </div>
                            <div className="text-xs text-gray-500 mt-1">
                                {new Date(msg.timestamp).toLocaleTimeString()}
                            </div>
                        </div>
                    ))
                )}
            </div>

            <form onSubmit={handleSendMessage} className="flex gap-2">
                <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type your message..."
                    className="flex-1 p-2 border rounded"
                />
                <button
                    type="submit"
                    disabled={isLoading || !newMessage.trim() || !wsConnected}
                    className="p-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
                >
                    <Send className="w-5 h-5" />
                </button>
            </form>
        </div>
    );
};

export default ChatBot;