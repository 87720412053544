import { Button } from 'bootstrap';
import React from 'react';
import ReactLoading from "react-loading";


const BalanceModal = ({ show, onClose, editBalanceValue, onChange, onSave,isLoading }) => {
  if (!show) return null;

  return (
    <div className="modal fade show" style={{ display: 'block' }} role="dialog">
       <div className="modal-dialog" style={{ margin: '100px auto' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Update Balance</h5>
            <button type="button" className="close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="balance">New Balance</label>
              <input
                type="number"
                className="form-control"
                id="balance"
                value={editBalanceValue}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose}>
              Close
            </button>
            {/* <button type="button" className="btn btn-primary" onClick={onSave}>
              Save changes
            </button> */}
             {isLoading ? (
          <ReactLoading type="spin" color="blue" height={30} width={30} />
        ) : (
          <button type="button" className="btn btn-primary" onClick={onSave}>
            Update Balance
          </button>
        )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceModal;