import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { CallPOSTAPI } from "../helper/Constants";

function EditGatway() {
  const location = useLocation();
  const rowData = location.state;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    name: rowData.name,
    sip_profile: rowData.sip_profile,
    user_name: rowData.user_name,
    password: rowData.password,
    proxy: rowData.proxy,
    outbound_proxy: rowData.outbound_proxy,
    register: rowData.register,
    caller_id_inform: rowData.caller_id_inform,
    status: rowData.status,
    from_domain: rowData.from_domain,
    from_user: rowData.from_user,
    realm: rowData.realm,
    extension_incontact: rowData.extension_incontact,
    extension: rowData.extension,
    expire_secounds: rowData.expire_secounds,
    regtransport: rowData.regtransport,
    contact_params: rowData.contact_params,
    ping: rowData.ping,
    retry_seconds: rowData.retry_seconds,
    register_proxy: rowData.register_proxy,
    channel: rowData.channel,
  });

  const handleEdit = async (event) => {
    try {
      event.preventDefault();
      setIsSubmitted(true);

      const invalidField = getFirstInvalidField();
      if (invalidField) {
        invalidField.scrollIntoView({ behavior: "smooth", block: "center" });
        return;
      }

      setIsLoading(true);
      const response = await CallPOSTAPI(
        `api/update-gatway/${rowData.gatway_id}`,
        formData
      );
      if (response.status) {
        toast.success("Data edited successfully.");
        navigate("/gatway");
      } else {
        toast.error("Failed to edit data.");
        console.error("Failed to edit data.");
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again later.");
      console.error("Error:", error);
    } finally {
      setIsLoading(false); // Set loading state back to false after fetching data
    }
  };

  const getFirstInvalidField = () => {
    const fieldsToCheck = ["name", "proxy"]; // Add other fields to check if needed

    for (const field of fieldsToCheck) {
      if (!formData[field]) {
        return formRef.current.querySelector(`#${field}`);
      }
    }

    return null;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    navigate("/gatway");
  };

  // Validation function for a specific field
  const validateField = (fieldName) => {
    if (isSubmitted && !formData[fieldName]) {
      return "This field is required";
    }
    return ""; // Return empty string if validation passes
  };

  return (
    <>
      <main id="main" className="main">
        <section>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Edit Gatway</h5>
              {/* Bordered Tabs Justified */}
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <form onSubmit={handleEdit} ref={formRef}>
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-6">
                            <h4 className="mb-3">Account Profile</h4>
                            <div className="row mb-3">
                              <label
                                htmlFor="name"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Name *:
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className={`form-control w-100 ${
                                    validateField("name") ? "is-invalid" : ""
                                  }`}
                                  value={formData.name}
                                  onChange={handleChange}
                                  id="name"
                                  name="name"
                                  placeholder=""
                                  required=""
                                />
                                {/* Validation message */}
                                {validateField("name") && (
                                  <div className="invalid-feedback">
                                    {validateField("name")}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                SIP profile :
                              </label>
                              <div className="col-sm-6">
                                <select
                                  className="form-select form-select-md"
                                  aria-label=".form-select-lg example"
                                  value={formData.sip_profile}
                                  onChange={handleChange}
                                  name="sip_profile"
                                >
                                  <option value={true}>Yes</option>
                                  <option value={false}>No</option>
                                </select>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Username:
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.user_name}
                                  onChange={handleChange}
                                  id="inputEmail"
                                  name="user_name"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Password:
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.password}
                                  onChange={handleChange}
                                  id="inputEmail"
                                  name="password"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label
                                htmlFor="proxy"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Proxy *:
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className={`form-control w-100 ${
                                    validateField("proxy") ? "is-invalid" : ""
                                  }`}
                                  value={formData.proxy}
                                  onChange={handleChange}
                                  id="proxy"
                                  name="proxy"
                                  placeholder=""
                                  required=""
                                />
                                {/* Validation message */}
                                {validateField("proxy") && (
                                  <div className="invalid-feedback">
                                    {validateField("proxy")}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Outbound Proxy:
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.outbound_proxy}
                                  onChange={handleChange}
                                  id="inputEmail"
                                  name="outbound_proxy"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Register:
                              </label>
                              <div className="col-sm-6">
                                <select
                                  className="form-select form-select-md"
                                  aria-label=".form-select-lg example"
                                  value={formData.register}
                                  onChange={handleChange}
                                  name="register"
                                >
                                  <option value={true}>Yes</option>
                                  <option value={false}>No</option>
                                </select>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Caller-Id-In-From:
                              </label>
                              <div className="col-sm-6">
                                <select
                                  className="form-select form-select-md"
                                  aria-label=".form-select-lg example"
                                  value={formData.caller_id_inform}
                                  onChange={handleChange}
                                  name="caller_id_inform"
                                >
                                  <option value={true}>Yes</option>
                                  <option value={false}>No</option>
                                </select>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Status:
                              </label>
                              <div className="col-sm-6">
                                <select
                                  className="form-select form-select-md"
                                  aria-label=".form-select-lg example"
                                  value={formData.status}
                                  onChange={handleChange}
                                  name="status"
                                >
                                  <option value={true}>Yes</option>
                                  <option value={false}>No</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <h4 className="mb-3">Account Settings</h4>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                From-Domain
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.from_domain}
                                  onChange={handleChange}
                                  id="inputEmail"
                                  name="from_domain"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                From User
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.from_user}
                                  onChange={handleChange}
                                  id="inputEmail"
                                  name="from_user"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Realm
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.realm}
                                  onChange={handleChange}
                                  id="inputEmail"
                                  name="realm"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Extension-In-Contact
                              </label>
                              <div className="col-sm-6">
                                <select
                                  className="form-select form-select-md"
                                  aria-label=".form-select-lg example"
                                  value={formData.extension_incontact}
                                  onChange={handleChange}
                                  name="extension_incontact"
                                >
                                  <option value={true}>Yes</option>
                                  <option value={false}>No</option>
                                </select>
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Extension
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.extension}
                                  onChange={handleChange}
                                  id="inputEmail"
                                  name="extension"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Expire Secounds
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.expire_secounds}
                                  onChange={handleChange}
                                  id="inputEmail"
                                  name="expire_secounds"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Regtransport
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.regtransport}
                                  onChange={handleChange}
                                  id="inputEmail"
                                  name="regtransport"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Contact Params
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.contact_params}
                                  onChange={handleChange}
                                  id="inputEmail"
                                  name="contact_params"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Ping
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.ping}
                                  onChange={handleChange}
                                  id="inputEmail"
                                  name="ping"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Retry Seconds
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.retry_seconds}
                                  onChange={handleChange}
                                  id="inputEmail"
                                  name="retry_seconds"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Register Proxy
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.register_proxy}
                                  onChange={handleChange}
                                  id="inputEmail"
                                  name="register_proxy"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Channel
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.channel}
                                  onChange={handleChange}
                                  id="inputEmail"
                                  name="channel"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-5 m-2 d-flex justify-content-end">
                          <button
                            type="submit"
                            className={`btn btn-success px-5 ${
                              isLoading ? "fade-bg" : ""
                            }`}
                          >
                            {isLoading ? "Edited..." : "Edit"}
                          </button>
                        </div>
                        <div className="col-sm-6 m-2 d-flex justify-content-start">
                          <button
                            type="button"
                            className="btn btn-primary px-5"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-6"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default EditGatway;
