import React from "react";
import { Link } from "react-router-dom";

export const columns = [
  {
    name: (
      <input
        className="form-check-input"
        type="checkbox"
        id="check1"
        name="option1"
        defaultValue="something"
        defaultChecked=""
      />
    ),
    selector: "check",
    cell: () => (
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="check1"
          name="option1"
          defaultValue="something"
          defaultChecked=""
        />
      </div>
    ),
    style: {
      border: "1px solid black", // Add border to the second column
    },
  },
  {
    name: "Account",
    selector: "account",
    sortable: true,
    width: "9%",
    style: {
      border: "1px solid black", // Add border to the second column
    },
    cell: (row) => (
      <p class="align-text-bottom text-nowrap">
        {row.account}
        <i class="bi bi-p-circle-fill h5"></i>
      </p>
    ),
  },
  {
    name: "FIRST NAME",
    selector: "first_name",
    sortable: true,
    compact: true,
    style: {
      border: "1px solid black", // Add border to the second column
    },
    // cell: (d) => <div style={{ backgroundColor: "rgb(135, 208, 104)", color: 'white', fontWeight: 'bold', padding: '3px' }}>{d.status}</div>
  },
  {
    name: "LAST NAME",
    selector: "last_name",
    sortable: true,
    style: {
      border: "1px solid black", // Add border to the second column
    },
  },
  {
    name: "COMPANY",
    selector: "company",
    sortable: true,
    style: {
      border: "1px solid black", // Add border to the second column
    },
  },
  {
    name: "RATE GROUP",
    selector: "rate_group",
    sortable: true,
    style: {
      border: "1px solid black", // Add border to the second column
    },
    // cell: (d) => (
    //     <p class="align-text-bottom text-nowrap">
    //         {d.recording}
    //         <svg height={24}>
    //             <circle cx="12" cy="12" r="5" fill="green" />
    //         </svg>
    //     </p>
    // )
  },

  {
    name: "BALANCE(USD)",
    selector: "balance",
    style: {
      border: "1px solid black", // Add border to the second column
    },
  },
  {
    name: "CREDIT LIMIT(USD)",
    selector: "credit_limit",
    style: {
      border: "1px solid black", // Add border to the second column
    },
  },
  {
    name: "FIRST USED",
    selector: "first_used",
    style: {
      border: "1px solid black", // Add border to the second column
    },
  },
  {
    name: "EXPIRY DATE",
    selector: "expiry_date",
    style: {
      border: "1px solid black", // Add border to the second column
    },
  },
  {
    name: "CC",
    selector: "",
    style: {
      border: "1px solid black", // Add border to the second column
    },
  },
  {
    name: "SIP",
    selector: "sip",
    sortable: true,
    width: "9%",
    style: {
      border: "1px solid black", // Add border to the second column
    },
    cell: (row) => <Link to="/sipdata">{row.sip}</Link>,
  },
  {
    name: "STATUS",
    selector: "status",
    style: {
      border: "1px solid black", // Add border to the second column
    },
    // cell: (row) => (

    //   )
  },
  {
    name: "CREATED DATE",
    selector: "createdAt",
    style: {
      border: "1px solid black", // Add border to the second column
    },
  },
  {
    name: "ACTION",
    center: true,
    sortable: false,
    width: "10%",
    selector: "null",
    style: {
      border: "1px solid black", // Add border to the second column
    },
    cell: (d) => [
      <button type="submit" class="btn btn-sm btn-outline-warning">
        <i class="fa-solid fa-dollar-sign"></i>
      </button>,

      <button type="submit" class="btn btn-sm btn-outline-warning">
        <i class="fa-solid fa-mobile"></i>
      </button>,

      <button type="submit" class="btn btn-sm btn-outline-warning">
        <i class="fa-regular fa-pen-to-square"></i>
      </button>,

      <button type="submit" class="btn btn-sm btn-outline-warning ">
        <i class="fa-regular fa-trash-can"></i>
      </button>,
    ],
  },
];
export const data = [
  {
    check: "",
    account: "98931378045 ",
    firstname: "Bella",
    lastname: "watson",
    company: "motolic",
    rategroup: "USA",
    balance: "-128.160",
    creditlimit: "132.000",
    firstused: "0000-00-00",
    expirydate: "2043-09-21",
    cc: "0",
    status: "Active",
    createddate: "2023-09-21",
    sip: "2312",
  },
  {
    check: "",
    account: "98931378045 ",
    firstname: "tushar",
    lastname: "tomar",
    company: "motolic",
    rategroup: "USA",
    balance: "-128.160",
    creditlimit: "132.000",
    firstused: "0000-00-00",
    expirydate: "2043-09-21",
    cc: "0",
    status: "Inactive",
    createddate: "2023-09-21",
    sip: "2312",
  },
  {
    check: "",
    account: "98931378045 ",
    firstname: "deepak",
    lastname: "singh",
    company: "motolic",
    rategroup: "USA",
    balance: "-128.160",
    creditlimit: "132.000",
    firstused: "0000-00-00",
    expirydate: "2043-09-21",
    cc: "0",
    status: "Active",
    createddate: "2023-09-21",
    sip: "2312",
  },
  {
    check: "",
    account: "98931378045 ",
    firstname: "yogesh",
    lastname: "gandhi",
    company: "motolic",
    rategroup: "USA",
    balance: "-128.160",
    creditlimit: "132.000",
    firstused: "0000-00-00",
    expirydate: "2043-09-21",
    cc: "0",
    status: "Inactive",
    createddate: "2023-09-21",
    sip: "2312",
  },
  {
    check: "",
    account: "98931378045 ",
    firstname: "Bella",
    lastname: "watson",
    company: "motolic",
    rategroup: "USA",
    balance: "-128.160",
    creditlimit: "132.000",
    firstused: "0000-00-00",
    expirydate: "2043-09-21",
    cc: "0",
    status: "",
    createddate: "2023-09-21",
    sip: "2312",
  },
  {
    check: "",
    account: "98931378045 ",
    firstname: "Bella",
    lastname: "watson",
    company: "motolic",
    rategroup: "USA",
    balance: "-128.160",
    creditlimit: "132.000",
    firstused: "0000-00-00",
    expirydate: "2043-09-21",
    cc: "0",
    status: "",
    createddate: "2023-09-21",
    sip: "2312",
  },
  {
    check: "",
    account: "98931378045 ",
    firstname: "Bella",
    lastname: "watson",
    company: "motolic",
    rategroup: "USA",
    balance: "-128.160",
    creditlimit: "132.000",
    firstused: "0000-00-00",
    expirydate: "2043-09-21",
    cc: "0",
    status: "",
    createddate: "2023-09-21",
    sip: "2312",
  },
  {
    check: "",
    account: "98931378045 ",
    firstname: "Bella",
    lastname: "watson",
    company: "motolic",
    rategroup: "USA",
    balance: "-128.160",
    creditlimit: "132.000",
    firstused: "0000-00-00",
    expirydate: "2043-09-21",
    cc: "0",
    status: "",
    createddate: "2023-09-21",
    sip: "2312",
  },
  {
    check: "",
    account: "98931378045 ",
    firstname: "Bella",
    lastname: "watson",
    company: "motolic",
    rategroup: "USA",
    balance: "-128.160",
    creditlimit: "132.000",
    firstused: "0000-00-00",
    expirydate: "2043-09-21",
    cc: "0",
    status: "",
    createddate: "2023-09-21",
    sip: "2312",
  },
  {
    check: "",
    account: "98931378045 ",
    firstname: "Bella",
    lastname: "watson",
    company: "motolic",
    rategroup: "USA",
    balance: "-128.160",
    creditlimit: "132.000",
    firstused: "0000-00-00",
    expirydate: "2043-09-21",
    cc: "0",
    status: "",
    createddate: "2023-09-21",
    sip: "2312",
  },
  {
    check: "",
    account: "98931378045 ",
    firstname: "Bella",
    lastname: "watson",
    company: "motolic",
    rategroup: "USA",
    balance: "-128.160",
    creditlimit: "132.000",
    firstused: "0000-00-00",
    expirydate: "2043-09-21",
    cc: "0",
    status: "",
    createddate: "2023-09-21",
    sip: "2312",
  },

  {
    check: "",
    account: "98931378045 ",
    firstname: "Bella",
    lastname: "watson",
    company: "motolic",
    rategroup: "USA",
    balance: "-128.160",
    creditlimit: "132.000",
    firstused: "0000-00-00",
    expirydate: "2043-09-21",
    cc: "0",
    status: "",
    createddate: "2023-09-21",
    sip: "2312",
  },

  {
    check: "",
    account: "98931378045 ",
    firstname: "Bella",
    lastname: "watson",
    company: "motolic",
    rategroup: "USA",
    balance: "-128.160",
    creditlimit: "132.000",
    firstused: "0000-00-00",
    expirydate: "2043-09-21",
    cc: "0",
    status: "",
    createddate: "2023-09-21",
    sip: "2312",
  },

  {
    check: "",
    account: "98931378045 ",
    firstname: "Bella",
    lastname: "watson",
    company: "motolic",
    rategroup: "USA",
    balance: "-128.160",
    creditlimit: "132.000",
    firstused: "0000-00-00",
    expirydate: "2043-09-21",
    cc: "0",
    status: "",
    createddate: "2023-09-21",
    sip: "2312",
  },
];

export const sipcolumns = [
  {
    name: (
      <input
        className="form-check-input"
        type="checkbox"
        id="check1"
        name="option1"
        defaultValue="something"
        defaultChecked=""
      />
    ),
    selector: "check",
    cell: () => (
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="check1"
          name="option1"
          defaultValue="something"
          defaultChecked=""
        />
      </div>
    ),
    style: {
      border: "1px solid black", // Add border to the second column
    },
  },
  {
    name: "Status",
    selector: "status",
    sortable: true,
    width: "12%",
    style: {
      border: "1px solid black", // Add border to the second column
    },
    cell: (row) => (
      <p class="align-text-bottom text-nowrap">
        <i class="fa-solid fa-phone"></i>
        {row.status}
      </p>
    ),
  },
  {
    name: "User Name",
    selector: "username",
    sortable: true,
    compact: true,
    style: {
      border: "1px solid black", // Add border to the second column
    },
    // cell: (d) => <div style={{ backgroundColor: "rgb(135, 208, 104)", color: 'white', fontWeight: 'bold', padding: '3px' }}>{d.status}</div>
  },
  {
    name: "Sip User",
    selector: "sipuser",
    sortable: true,
    style: {
      border: "1px solid black", // Add border to the second column
    },
  },
  {
    name: "Password",
    selector: "password",
    sortable: true,
    style: {
      border: "1px solid black", // Add border to the second column
    },
  },
  {
    name: "Codec",
    selector: "codec",
    sortable: true,
    style: {
      border: "1px solid black", // Add border to the second column
    },
  },
];
export const sipdata = [
  {
    check: "",
    status: "Unknown",
    username: "tushar",
    sipuser: "987654",
    password: "pass456",
    codec: "g789",
  },
  {
    check: "",
    status: "Unknown",
    username: "tushar",
    sipuser: "987654",
    password: "pass456",
    codec: "g789",
  },
  {
    check: "",
    status: "Unknown",
    username: "tushar",
    sipuser: "987654",
    password: "pass456",
    codec: "g789",
  },
  {
    check: "",
    status: "Unknown",
    username: "tushar",
    sipuser: "987654",
    password: "pass456",
    codec: "g789",
  },
  {
    check: "",
    status: "Unknown",
    username: "tushar",
    sipuser: "987654",
    password: "pass456",
    codec: "g789",
  },
];

// DID Data
export const didcolumns = [
  {
    name: "DID",
    selector: "did",
    sortable: true,
    width: "9%",
    style: {
      border: "1px solid black", // Add border to the second column
    },
    // cell: (row) => (
    //     <p class="align-text-bottom text-nowrap">
    //         {row.account}
    //         <i class="bi bi-p-circle-fill h5"></i>
    //     </p>
    // )
  },
  {
    name: "COUNTRY",
    selector: "country",
    sortable: true,
    compact: true,
    style: {
      border: "1px solid black", // Add border to the second column
    },
    // cell: (d) => <div style={{ backgroundColor: "rgb(135, 208, 104)", color: 'white', fontWeight: 'bold', padding: '3px' }}>{d.status}</div>
  },
  {
    name: "PER MINUTE COST(USD)",
    selector: "perminutecost",
    sortable: true,
    style: {
      border: "1px solid black", // Add border to the second column
    },
  },
  {
    name: "INITIAL INCREAMENT",
    selector: "initialincreament",
    sortable: true,
    style: {
      border: "1px solid black", // Add border to the second column
    },
  },
  {
    name: "INCREAMENT",
    selector: "increament",
    sortable: true,
    style: {
      border: "1px solid black", // Add border to the second column
    },
    // cell: (d) => (
    //     <p class="align-text-bottom text-nowrap">
    //         {d.recording}
    //         <svg height={24}>
    //             <circle cx="12" cy="12" r="5" fill="green" />
    //         </svg>
    //     </p>
    // )
  },

  {
    name: "SETUP FEE(USD)",
    selector: "setupfee",
    style: {
      border: "1px solid black", // Add border to the second column
    },
  },
  {
    name: "MONTHLY FEE(USD)",
    selector: "monthlyfee",
    style: {
      border: "1px solid black", // Add border to the second column
    },
  },

  {
    name: "ACTION",
    center: true,
    sortable: false,
    selector: "null",
    style: {
      border: "1px solid black", // Add border to the second column
    },
    cell: (d) => [
      <button type="submit" class="btn btn-sm btn-outline-warning ">
        <i class="fa-regular fa-trash-can"></i>
      </button>,
      <button
        type="button"
        class="btn btn-sm btn-outline-warning"
        // onClick={() => navigate("/#", { state: row })}
      >
        <i class="fa-regular fa-pen-to-square"></i>
      </button>,
    ],
  },
];

export const diddata = [
  {
    did: "18886023130",
    country: "United States of America ",
    perminutecost: "0.0500",
    initialincreament: "60",
    increament: "60",
    setupfee: "0.000",
    monthlyfee: "0.000",
  },
  {
    did: "19886023130",
    country: "Delhi ",
    perminutecost: "0.0500",
    initialincreament: "60",
    increament: "60",
    setupfee: "0.000",
    monthlyfee: "0.000",
  },
  {
    did: "20886023130",
    country: "Indore",
    perminutecost: "0.0500",
    initialincreament: "60",
    increament: "60",
    setupfee: "0.000",
    monthlyfee: "0.000",
  },
];

// IP Settings

export const ipcolumns = [
  {
    name: "NAME",
    selector: "name",
    sortable: true,
    width: "9%",
    style: {
      border: "1px solid black", // Add border to the second column
    },
    // cell: (row) => (
    //     <p class="align-text-bottom text-nowrap">
    //         {row.account}
    //         <i class="bi bi-p-circle-fill h5"></i>
    //     </p>
    // )
  },
  {
    name: "IP",
    selector: "ip",
    sortable: true,
    compact: true,
    style: {
      border: "1px solid black", // Add border to the second column
    },
    // cell: (d) => <div style={{ backgroundColor: "rgb(135, 208, 104)", color: 'white', fontWeight: 'bold', padding: '3px' }}>{d.status}</div>
  },
  {
    name: "PREFIX",
    selector: "prefix",
    sortable: true,
    style: {
      border: "1px solid black", // Add border to the second column
    },
  },
  {
    name: "CREATED DATE",
    selector: "createddate",
    sortable: true,
    style: {
      border: "1px solid black", // Add border to the second column
    },
  },
  {
    name: "MODIFIED DATE",
    selector: "modifieddate",
    sortable: true,
    style: {
      border: "1px solid black", // Add border to the second column
    },
    // cell: (d) => (
    //     <p class="align-text-bottom text-nowrap">
    //         {d.recording}
    //         <svg height={24}>
    //             <circle cx="12" cy="12" r="5" fill="green" />
    //         </svg>
    //     </p>
    // )
  },
  {
    name: "ACTION",
    center: true,
    sortable: false,
    selector: "null",
    style: {
      border: "1px solid black", // Add border to the second column
    },
    cell: (d) => [
      <button type="submit" class="btn btn-sm btn-outline-warning ">
        <i class="fa-regular fa-trash-can"></i>
      </button>,
    ],
  },
];

export const ipdata = [
  {
    name: "twilio-sip",
    ip: "168.86.128.018",
    prefix: "",
    createddate: "2023-08-13 02:37:14",
    modifieddate: "0000.00.00 00:00:00",
  },
  {
    name: "twilio-sip",
    ip: "168.86.128.018",
    prefix: "",
    createddate: "2023-08-13 02:37:14",
    modifieddate: "0000.00.00 00:00:00",
  },
  {
    name: "twilio-sip",
    ip: "168.86.128.018",
    prefix: "",
    createddate: "2023-08-13 02:37:14",
    modifieddate: "0000.00.00 00:00:00",
  },
  {
    name: "twilio-sip",
    ip: "168.86.128.018",
    prefix: "",
    createddate: "2023-08-13 02:37:14",
    modifieddate: "0000.00.00 00:00:00",
  },
  {
    name: "twilio-sip",
    ip: "168.86.128.018",
    prefix: "",
    createddate: "2023-08-13 02:37:14",
    modifieddate: "0000.00.00 00:00:00",
  },
  {
    name: "twilio-sip",
    ip: "168.86.128.018",
    prefix: "",
    createddate: "2023-08-13 02:37:14",
    modifieddate: "0000.00.00 00:00:00",
  },
  {
    name: "twilio-sip",
    ip: "168.86.128.018",
    prefix: "",
    createddate: "2023-08-13 02:37:14",
    modifieddate: "0000.00.00 00:00:00",
  },
];

export const apicolumns = [
  {
    name: "API Name",
    selector: "name",
    sortable: true,
    style: {
      border: "1px solid black", // Add border to the second column
    },
  },
  {
    name: "ACTION",
    center: true,
    sortable: false,
    selector: "null",
    width: "10%",
    style: {
      border: "1px solid black", // Add border to the second column
    },
    cell: (d) => [
      <button type="submit" class="btn btn-sm btn-outline-warning ">
        <i class="fa-regular fa-trash-can"></i>
      </button>,
    ],
  },
];
