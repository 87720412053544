import React, { useState, useEffect, useCallback } from 'react';
import io from 'socket.io-client';
import { useSIPProvider } from "react-sipjs";
import { toast } from 'react-toastify';
import { BASE_WEB_SOCKET } from '../helper/Constants';
import DataTable from "react-data-table-component";
import ReactLoading from "react-loading";
import { Link } from 'react-router-dom';

const LiveCalls = ({ 
  userId = '', 
  userType = 2, 
  onTotalLiveCallsUpdate,
  BargeCall,
  WhisperCall,
  ListenCall,
  sipData = {}
 }) => {
  const [calls, setCalls] = useState([]);
  const [socket, setSocket] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState('Disconnected');
  const [isLoading, setIsLoading] = useState(true);
  
  const {
    connectStatus,
    CONNECT_STATUS
  } = useSIPProvider();

  const updateCalls = useCallback((newCalls) => {
    console.log('Updating calls:', newCalls);
    const callsArray = Array.isArray(newCalls?.activeCalls) ? newCalls.activeCalls : [];
    setCalls(callsArray);
    if (onTotalLiveCallsUpdate) {
      onTotalLiveCallsUpdate(callsArray.length);
    }
    setIsLoading(false);
  }, [onTotalLiveCallsUpdate]);

  useEffect(() => {
    console.log('Initializing socket connection');
    const newSocket = io(BASE_WEB_SOCKET, {
      withCredentials: true,
      transports: ['websocket']
    });

    newSocket.on('connect', () => {
      console.log('Connected to WebSocket server');
      setConnectionStatus('Connected');
      if (userId) {
        console.log('Subscribing with userId:', userId);
        newSocket.emit('subscribe', userType === 1 ? 'admin' : userId);
      }
    });

    newSocket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server');
      setConnectionStatus('Disconnected');
    });

    newSocket.on('connect_error', (error) => {
      console.error('Connection error:', error);
      setConnectionStatus('Error: ' + error.message);
    });

    newSocket.on('userCallsUpdate', (updatedCalls) => {
      console.log('Received userCallsUpdate:', updatedCalls);
      updateCalls(updatedCalls);
    });

    newSocket.on('allCallsUpdate', (updatedCalls) => {
      console.log('Received allCallsUpdate:', updatedCalls);
      updateCalls(updatedCalls);
    });

    setSocket(newSocket);

    return () => {
      console.log('Disconnecting socket');
      newSocket.disconnect();
    };
  }, [userId, userType, updateCalls]);

  const columns = [
    {
      name: "Caller ID",
      // selector: "callerIdNum",
      selector: row => row.callerIdNum,
      sortable: true,
    },
    {
      name: "Destination",
      // selector: "destinationNumber",
      selector: row => row.destinationNumber,
      sortable: true,
    },
    {
      name: "Status",
      // selector: "status",
      selector: row => row.status,
      sortable: true,
      cell: (row) => (
        <span className={`badge ${row.status === 'Ring' ? 'bg-warning' : 'bg-success'}`}>
          {row.status}
        </span>
      ),
    },
    {
      name: "Extension",
      // selector: "runExt",
      selector: row => row.runExt,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="btn-group" role="group">
          <button className="btn btn-sm btn-primary" onClick={() => BargeCall(row.runExt)}>Barge</button>
          <button className="btn btn-sm btn-info" onClick={() => WhisperCall(row.runExt)}>Whisper</button>
          <button className="btn btn-sm btn-secondary" onClick={() => ListenCall(row.runExt)}>Listen</button>
        </div>
      ),
    },
  ];

  return (
    <main id="main" className="main">
    <div className="pagetitle">
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item active">Live Calls</li>
        </ol>
      </nav>
    </div>
    <section className="section dashboard">
      <div className="card">
        <div className="card-body mt-3">
          <h3>
            <strong className="border-bottom border-3 pb-2">
            Live Calls
            </strong>
          </h3>
          <div className="card" style={{ boxShadow: "none" }}>
            <div className="card-body" style={{ padding: 0 }}>
              <div className="container-fluid d-flex justify-content-center">
                <div className="w-100">
                  {isLoading && (
                    <div className="d-flex justify-content-center my-5">
                      <ReactLoading
                        type="spokes"
                        color="grey"
                        height={50}
                        width={50}
                      />
                    </div>
                  )}
                  {!isLoading && (
                    <div className="main">
                     <DataTable
                  columns={columns}
                  data={calls}
                  pagination
                  highlightOnHover
                  responsive
                  noDataComponent="No Calls Available"
                />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  );
};

export default LiveCalls;