import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Country } from "country-state-city";
import { CallGETAPI } from "../helper/Constants";

const ReportList = () => {
  const [countries, setCountries] = useState([]);
  const [didies, setDidies] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [showFields, setShowFields] = useState(false);
  const [didData, setDidData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const fetchAllDid = async () => {
    try {
      setIsLoading(true);
      const response = await CallGETAPI("api/get-all-dids");
      const result = response.data;
      setDidData(result.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteGatway = async (id) => {
    setIsLoading(true);
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this Did !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes ",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await CallGETAPI(`api/delete-did/${id}`);
          if (response.status) {
            toast.success("Deleted Successfully!");
            fetchAllDid();
          }
        }
      });
    } catch (error) {
      console.error("Error deleting data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllDid();
  }, []);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const didcolumns = [
    {
      name: "DID",
      selector: (row) => `${row.did}`,
      sortable: true,
      width: "9%",
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "COUNTRY",
      selector: (row) => `${row.country}`,
      sortable: true,
      compact: true,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "PER MINUTE COST(USD)",
      selector: (row) => `${row.per_minute_cost}`,
      sortable: true,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "INITIAL INCREAMENT",
      selector: (row) => `${row.initial_increment}`,
      sortable: true,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "INCREAMENT",
      selector: (row) => `${row.increment}`,
      sortable: true,
      style: {
        border: "1px solid black",
      },
    },

    {
      name: "SETUP FEE(USD)",
      selector: (row) => `${row.setup_fee}`,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "MONTHLY FEE(USD)",
      selector: (row) => `${row.monthly_fee}`,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "ACTION",
      center: true,
      sortable: false,
      // selector: "null",
      selector: row => row.null,
      style: {
        border: "1px solid black",
      },
      cell: (row) => [
        <button
          type="button"
          className="btn btn-sm btn-outline-warning"
          onClick={() => navigate("/editdid", { state: row })}
        >
          <i className="fa-regular fa-pen-to-square"></i>
        </button>,

        <button
          type="button"
          className="btn btn-sm btn-outline-warning"
          onClick={() => handleDeleteGatway(row.did_id)}
        >
          <i className="fa-regular fa-trash-can"></i>
        </button>,
      ],
    },
  ];

  const customStyles = {
    table: {
      style: {
        borderCollapse: "collapse",
        border: "1px solid black",
      },
    },
    rows: {
      style: {
        borderCollapse: "collapse",
      },
    },
    columns: {
      style: {
        borderCollapse: "collapse",
      },
    },
    header: {
      style: {
        border: "1px solid black",
      },
    },
  };

  const fetchCountries = async () => {
    const allCountries = Country.getAllCountries();
    setCountries(allCountries);
  };

  const fetchAvailableDids = async () => {
    const allAvailableDid = await CallGETAPI(
      "api/fetchnumber"
    );
    const result = allAvailableDid?.data;
    setDidies(result?.data?.localNumbers);
  };

  const handlePurchaseClick = () => {
    setShowFields(true);
    fetchCountries();
    fetchAvailableDids();
  };

  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Customers</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Profile</a>
              </li>
              <li className="breadcrumb-item active">DID</li>
            </ol>
          </nav>
        </div>
        <section>
          <div className="card">
            <div className="card-body mt-3">
              <h1></h1>
              {/* Bordered tabs Justified */}
              <div className="container-fluid">
                <div className="row">
                  <div className="col-6 d-flex justify-content-start ">
                    {/* <h5 className="text-left" >
                      Customer
                    </h5> */}
                  </div>
                </div>
                <div className="container-fluid mt-4 text-center">
                  <div className="row ">
                    <div className="col-6 d-flex justify-content-start">
                      <button
                        type="submit"
                        class="btn btn-sm btn-outline-warning px-5"
                        onClick={handlePurchaseClick}
                      >
                        {/* <PurchaseFields
                          handlePurchaseClick={handlePurchaseClick}
                        /> */}
                        <i class="fa-solid fa-circle-plus"></i> Purchase
                      </button>

                      <Link to="/create-DID">
                        <button
                          style={{ marginLeft: "2rem", padding: "10px" }}
                          type="submit"
                          class="btn btn-sm btn-outline-primary px-5"
                        >
                          <i class="fa-solid fa-circle-plus"></i> Create
                          Purchase
                        </button>
                      </Link>
                    </div>
                    <div className="col-6 d-flex justify-content-start ">
                      <li
                        className="nav-item flex-fill d-flex  justify-content-end"
                        role="presentation"
                      >
                        <input
                          className="form-control w-50 rounded-0"
                          type="text"
                          placeholder="Search"
                        />
                        <button type="submit" class="btn btn-primary rounded-0">
                          <i class="fa fa-search"></i>
                        </button>
                      </li>
                    </div>
                  </div>
                </div>

                {/* Fields */}
                {showFields && (
                  <div className="container-fluid mt-4 text-center">
                    <div className="row">
                      <div className="col-12">
                        <hr /> {/* Add a horizontal line */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        {/* Country dropdown field */}
                        <select
                          className="form-control mr-2"
                          value={selectedCountry}
                          id="country"
                          name="country"
                          onChange={handleCountryChange}
                        >
                          <option value="">Select Country</option>
                          {countries.map((country) => (
                            <option key={country.isoCode} value={country.id}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-6">
                        {/* Available DID dropdown field */}
                        <select className="form-control">
                          <option value="">Available DID</option>
                          {didies.map((did) => (
                            <option key={did} value={did}>
                              {did}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <hr /> {/* Add a horizontal line */}
                      </div>
                    </div>
                  </div>
                )}

                <div className="container-fluid mt-4 text-center">
                  <div className="row">
                    <div
                      className="tab-content"
                      id="borderedTabJustifiedContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="boredered-justified-campaign"
                        role="tabpanel"
                        aria-labelledby="campaign-tab"
                      >
                        <div className="card" style={{ boxShadow: "none" }}>
                          <div
                            className="card-body"
                            style={{ padding: 0, overflowX: "auto" }}
                          >
                            <div className="table-responsive">
                              <div className="main">
                                {/* <DataTableExtensions {...tableData}> */}
                                {isLoading && (
                                  <div
                                    className="d-flex justify-content-center my-5"
                                    style={{ marginTop: "20px" }}
                                  >
                                    <ReactLoading
                                      type="spokes"
                                      color="grey"
                                      height={50}
                                      width={50}
                                    />
                                  </div>
                                )}
                                {!isLoading && (
                                  <DataTable
                                    columns={didcolumns}
                                    data={didData}
                                    noHeader
                                    defaultSortField="id"
                                    // sortIcon={<SortIcon />}
                                    defaultSortAsc={true}
                                    pagination
                                    highlightOnHover
                                    dense
                                    customStyles={customStyles}
                                  />
                                )}
                                {/* </DataTableExtensions> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ReportList;
