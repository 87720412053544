import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';

const months = [
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' }
];

const FilterDropdown = ({ onFilterChange,loading  }) => {
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 1999 }, (_, i) => currentYear - i);

  useEffect(() => {
    // Set default values to current month and year
    const currentDate = new Date();
    setSelectedMonth(String(currentDate.getMonth() + 1).padStart(2, '0'));
    setSelectedYear(String(currentDate.getFullYear()));
  }, []);

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleFilter = () => {
    if (selectedMonth && selectedYear) {
      const startDate = `${selectedYear}-${selectedMonth}-01`;
      const endDate = new Date(selectedYear, selectedMonth, 0).toISOString().split('T')[0];
      onFilterChange(startDate, endDate);
    }
  };

  return (
    <div className="row justify-content-end gx-2">
    <div className="col-12 col-md-auto mb-2">
      <select
        className="form-select"
        value={selectedMonth}
        onChange={handleMonthChange}
      >
        {months.map((month) => (
          <option key={month.value} value={month.value}>
            {month.label}
          </option>
        ))}
      </select>
    </div>
  
    <div className="col-12 col-md-auto mb-2">
      <select
        className="form-select"
        value={selectedYear}
        onChange={handleYearChange}
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  
    <div className="col-12 col-md-auto mb-2">
      <button
        className="btn btn-primary"
        onClick={handleFilter}
        disabled={loading}
      >
        {loading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          "Apply Filter"
        )}
      </button>
    </div>
  </div>
  
  );
};

export default FilterDropdown;