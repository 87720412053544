import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { CallGETAPI, CallPOSTAPI, DecryptToken } from '../helper/Constants';
import ReactLoading from 'react-loading';
import EditPasswordModal from '../components/models/EditPasswordModal';
import { toast } from 'react-toastify';

const UserExtensions = () => {
  const [extensions, setExtensions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { userId } = useParams();
  const [showEditPasswordModal, setShowEditPasswordModal] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const [editedPassword, setEditedPassword] = useState("");


  const handleShowEditPasswordModal = (recordId, password) => {
    setSelectedRecordId(recordId);
    setEditedPassword(password);
    setShowEditPasswordModal(true);
  };

  const handleCloseEditPasswordModal = () => {
    setShowEditPasswordModal(false);
  };

  const handlePasswordChange = (event) => {
    setEditedPassword(event.target.value);
  };

  useEffect(() => {
    fetchExtensions();
    // handleSavePassword();
  }, [userId]);

  const fetchExtensions = async () => {
    setIsLoading(true);
    try {
      const response = await CallGETAPI(`api/users/${userId}`);
      if (response.status && response.data) {
        const userExtensions = response.data.data.extensions[`user_${userId}_extensions`] || [];
        setExtensions(userExtensions);
      } else {
        setExtensions([]);
      }
    } catch (error) {
      console.error("Error fetching extensions:", error);
      setExtensions([]);
    } finally {
      setIsLoading(false);
    }
  };



  // const handleSavePassword = async () => {
  //   try {
  //   //   const token = DecryptToken();
  //     const payload = {
  //       recordId: selectedRecordId,
  //       newPassword: editedPassword.trim(),
  //     };

  //     const response = await CallPOSTAPI("api/update-password", [payload]);

  //     if (response.status === true) {
  //       fetchExtensions();
  //       toast.success(response.data.message);
  //       setShowEditPasswordModal(false);
  //     } else {
  //       throw new Error("Failed to edit password");
  //     }
  //   } catch (error) {
  //     console.error("Error editing password:", error);
  //     toast.error("Failed to edit password. Please try again later.");
  //   }
  // };




  const columns = [
    {
      name: "Extension ID",
      selector: row => row.id,
      sortable: true,
    },
    {
      name: "Password",
      selector: row => row.password,
      sortable: true,
    },
    {
        name: "ACTION",
        center: true,
        sortable: false,
        selector: "null",
        // style: {
        //   border: "1px solid black",
        // },
        cell: (row) => [
          <button
            type="button"
            className="btn btn-sm btn-outline-warning"
            // onClick={() => handleShowEditPasswordModal(row.id, row.password)}
          >
            <i className="fa-regular fa-pen-to-square"></i>
          </button>,
  
        //   <button
        //     type="button"
        //     className="btn btn-sm btn-outline-warning"
        //     onClick={() => handleDeleteGatway(row.did_id)}
        //   >
        //     <i className="fa-regular fa-trash-can"></i>
        //   </button>,
        ],
      },
  ];

  return (
    <>
    {/* <EditPasswordModal
        show={showEditPasswordModal}
        handleClose={handleCloseEditPasswordModal}
        handleSave={handleSavePassword}
        editedPassword={editedPassword}
        handlePasswordChange={handlePasswordChange}
      /> */}
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>User Extensions</h1>
        <nav>
          <ol className="breadcrumb">
          <li className="breadcrumb-item">
          <a href="/report-list">
        <i className="fas fa-arrow-left"></i> Back
        </a>
       </li>

            <li className="breadcrumb-item">Users</li>
            <li className="breadcrumb-item active">Extensions</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Extensions for User ID: {userId}</h5>
            {isLoading ? (
              <div className="d-flex justify-content-center my-5">
                <ReactLoading type="spokes" color="grey" height={50} width={50} />
              </div>
            ) : (
              <DataTable
                columns={columns}
                data={extensions}
                pagination
                highlightOnHover
                responsive
                noDataComponent="No extensions found for this user."
              />
            )}
          </div>
        </div>
      </section>
    </main>
    </>
    
  );
};

export default UserExtensions;