import React from "react";
import { FILE_BASE_RECORDING } from "../helper/Constants";
import ReactAudioPlayer from "react-audio-player";

// DID Data
export const reportcolumns = [
    {
        name: "CALL DATE",
        // selector: "start_time",
        selector: row => row.start_time,
        sortable: true,
        cell: (d) => {
          // Ensure start_time is defined
          const date = d.start_time ? d.start_time.split('-').slice(0, 3).join('-') : 'N/A';
          return <span>{date}</span>;
        },
      },
      {
        name: "CALL TIME",
        // selector: "end_time",
        selector: row => row.end_time,
        sortable: true,
        cell: (d) => {
          // Ensure end_time is defined
          const time = d.end_time ? d.end_time.split('-')[3] : 'N/A';
          return <span>{time}</span>;
        }
      },
      
    {
        name: "CALLER ID",
        // selector: "did",
        selector: row => row.did,
        sortable: true,
        style: {
            border: '1px solid black', // Add border to the column
        },
    },
    {
        name: "CALLED NUMBER",
        // selector: "uniqueid",
        selector: row => row.uniqueid,
        sortable: true,
        style: {
            border: '1px solid black', // Add border to the column
        },
    },
    {
        name: "CALL FROM",
        // selector: "call_from",
        selector: row => row.call_from,
        sortable: true,
        style: {
            border: '1px solid black', // Add border to the column
        },
    },
    {
        name: "CALL TO",
        // selector: "call_to",
        selector: row => row.call_to,
        sortable: true,
        style: {
            border: '1px solid black', // Add border to the column
        },
    },
    {
        name: "DURATION",
        // selector: "dur",
        selector: row => row.dur,
        sortable: true,
        style: {
            border: '1px solid black', // Add border to the column
        },
    },
    {
        name: "DIRECTION",
        // selector: "direction",
        selector: row => row.direction,
        sortable: true,
        style: {
            border: '1px solid black', // Add border to the column
        },
    },
    {
        name: "STATUS",
        // selector: "status",
        selector: row => row.status,
        sortable: true,
        style: {
            border: '1px solid black', // Add border to the column
        },
    },
   
    {
        name: "BUY CALL COST (USD)",
        // selector: "Buycallcost",
        selector: row => row.Buycallcost,
        sortable: true,
        style: {
            border: '1px solid black', // Add border to the column
        },
    },
    {
        name: "BUY DURATION",
        // selector: "buyduration",
        selector: row => row.buyduration,
        sortable: true,
        style: {
            border: '1px solid black', // Add border to the column
        },
    },
    {
        name: "SELL CALL COST",
        // selector: "sellcallcost",
        selector: row => row.sellcallcost,
        sortable: true,
        style: {
            border: '1px solid black', // Add border to the column
        },
    },
    {
        name: "SELL DURATION",
        // selector: "sellduration",
        selector: row => row.sellduration,
        sortable: true,
        style: {
            border: '1px solid black', // Add border to the column
        },
    },
    {
        name: "MARGIN",
        // selector: "margin",
        selector: row => row.margin,
        sortable: true,
        style: {
            border: '1px solid black', // Add border to the column
        },
    },
    {
        name: "NOT CONNECT STATUS",
        // selector: "notconnectstatus",
        selector: row => row.notconnectstatus,
        sortable: true,
        style: {
            border: '1px solid black', 
        },
    },
    {
        name: "RECORDING",
        center: true,
        sortable: false,
        // selector: "record_url",
        selector: row => row.record_url,
        cell: (row) => {
          // Check if record_url exists in row, if yes, then use it
          const recordingUrl = row?.record_url
      ? row.record_url.substring(row.record_url.lastIndexOf("/") + 1)
      : "";
      
          return (
            <ReactAudioPlayer
              className="w-100"
              src={FILE_BASE_RECORDING + 'callforward_rec/' + recordingUrl}
              controls
            />
          );
        },
      },
];


export const reportdata = [
    {
        date: '23-02-18',
        callerid: "16042278436<",
        callednumber: "1844333115",
        code: "1844",
        destination: "tollfree",
        duration: "00.27",
        debit: "0.000",
        cost: "0.000",
        disposition: "NORMAL_CLEARING",
        account: "Steven watson",
        trunk: "wowpbx",
        rategroup: "USA",
        calltype: "STANDARD",
    },
    {
        date: '23-02-18',
        callerid: "16042278436<",
        callednumber: "1844333115",
        code: "1845",
        destination: "tollfree",
        duration: "00.27",
        debit: "0.000",
        cost: "0.000",
        disposition: "NORMAL_CLEARING",
        account: "Tushar watson",
        trunk: "livepbx",
        rategroup: "USA",
        calltype: "Normal",
    },
    {
        date: '23-02-18',
        callerid: "16042278436<",
        callednumber: "1844333115",
        code: "1846",
        destination: "tollfree",
        duration: "00.27",
        debit: "0.000",
        cost: "0.000",
        disposition: "NORMAL_CLEARING",
        account: "Deepak watson",
        trunk: "wowpbx",
        rategroup: "USA",
        calltype: "STANDARD",
    },
    {
        date: '23-02-18',
        callerid: "16042278436<",
        callednumber: "1844333115",
        code: "1844",
        destination: "tollfree",
        duration: "00.27",
        debit: "0.000",
        cost: "0.000",
        disposition: "NORMAL_CLEARING",
        account: "Steven watson",
        trunk: "wowpbx",
        rategroup: "USA",
        calltype: "STANDARD",
    },
    {
        date: '23-02-18',
        callerid: "16042278436<",
        callednumber: "1844333115",
        code: "1844",
        destination: "tollfree",
        duration: "00.27",
        debit: "0.000",
        cost: "0.000",
        disposition: "NORMAL_CLEARING",
        account: "Steven watson",
        trunk: "wowpbx",
        rategroup: "USA",
        calltype: "STANDARD",
    },
    {
        date: '23-02-18',
        callerid: "16042278436<",
        callednumber: "1844333115",
        code: "1844",
        destination: "tollfree",
        duration: "00.27",
        debit: "0.000",
        cost: "0.000",
        disposition: "NORMAL_CLEARING",
        account: "Steven watson",
        trunk: "wowpbx",
        rategroup: "USA",
        calltype: "STANDARD",
    },
    {
        date: '23-02-18',
        callerid: "16042278436<",
        callednumber: "1844333115",
        code: "1844",
        destination: "tollfree",
        duration: "00.27",
        debit: "0.000",
        cost: "0.000",
        disposition: "NORMAL_CLEARING",
        account: "Steven watson",
        trunk: "wowpbx",
        rategroup: "USA",
        calltype: "STANDARD",
    },
    {
        date: '23-02-18',
        callerid: "16042278436<",
        callednumber: "1844333115",
        code: "1844",
        destination: "tollfree",
        duration: "00.27",
        debit: "0.000",
        cost: "0.000",
        disposition: "NORMAL_CLEARING",
        account: "Steven watson",
        trunk: "wowpbx",
        rategroup: "USA",
        calltype: "STANDARD",
    },
    {
        date: '23-02-18',
        callerid: "16042278436<",
        callednumber: "1844333115",
        code: "1844",
        destination: "tollfree",
        duration: "00.27",
        debit: "0.000",
        cost: "0.000",
        disposition: "NORMAL_CLEARING",
        account: "Steven watson",
        trunk: "wowpbx",
        rategroup: "USA",
        calltype: "STANDARD",
    },
    {
        date: '23-02-18',
        callerid: "16042278436<",
        callednumber: "1844333115",
        code: "1844",
        destination: "tollfree",
        duration: "00.27",
        debit: "0.000",
        cost: "0.000",
        disposition: "NORMAL_CLEARING",
        account: "Steven watson",
        trunk: "wowpbx",
        rategroup: "USA",
        calltype: "STANDARD",
    },
    {
        date: '23-02-18',
        callerid: "16042278436<",
        callednumber: "1844333115",
        code: "1844",
        destination: "tollfree",
        duration: "00.27",
        debit: "0.000",
        cost: "0.000",
        disposition: "NORMAL_CLEARING",
        account: "Steven watson",
        trunk: "wowpbx",
        rategroup: "USA",
        calltype: "STANDARD",
    },
    {
        date: '23-02-18',
        callerid: "16042278436<",
        callednumber: "1844333115",
        code: "1844",
        destination: "tollfree",
        duration: "00.27",
        debit: "0.000",
        cost: "0.000",
        disposition: "NORMAL_CLEARING",
        account: "Steven watson",
        trunk: "wowpbx",
        rategroup: "USA",
        calltype: "STANDARD",
    },
    {
        date: '23-02-18',
        callerid: "16042278436<",
        callednumber: "1844333115",
        code: "1844",
        destination: "tollfree",
        duration: "00.27",
        debit: "0.000",
        cost: "0.000",
        disposition: "NORMAL_CLEARING",
        account: "Steven watson",
        trunk: "wowpbx",
        rategroup: "USA",
        calltype: "STANDARD",
    },
    
      
];