import React, { useState, useRef,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { CallPOSTAPI } from "../helper/Constants";

export const EditCustomer = (user_id) => {
  const location = useLocation();
  const rowData = location.state;
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    account: rowData.account,
    password: rowData.password,
    pin: rowData.pin,
    first_name: rowData.first_name,
    last_name: rowData.last_name,
    company: rowData.company,
    phone: rowData.phone,
    contact_number: rowData.contact_number,
    email: rowData.email,
    address1: rowData.address1,
    address2: rowData.address2,
    city: rowData.city,
    state: rowData.state,
    zip_code: rowData.zip_code,
    country: rowData.country,
    time_zone: rowData.time_zone,
    currency: rowData.currency,
    status: rowData.status,
    allow_recoding: rowData.allow_recoding,
    allow_ipmanagement: rowData.allow_ipmanagement,
    number_translation: rowData.number_translation,
    out_callerid_translation: rowData.out_callerid_translation,
    in_callerid_translation: rowData.in_callerid_translation,
    concurrent_calls: rowData.concurrent_calls,
    cps: rowData.cps,
    first_used: rowData.first_used,
    account_valid_days: rowData.account_valid_days,
    endDate: rowData.endDate ? new Date(rowData.endDate).toISOString().split('T')[0] : '',    rate_group: rowData.rate_group,
    account_type: rowData.account_type,
    billing_schedule: rowData.billing_schedule,
    billing_day: rowData.billing_day,
    balance: rowData.balance,
    credit_limit: rowData.credit_limit,
    allow_local_calls: rowData.allow_local_calls,
    lc_charge: rowData.lc_charge,
    tax: rowData.tax,
    tax_number: rowData.tax_number,
    email_alerts: rowData.email_alerts,
    balance_below: rowData.balance_below,
    ip_address: rowData.ip_address
  });



useEffect(() => {
  if (rowData.endDate) {
    setFormData(prev => ({
      ...prev,
      endDate: new Date(rowData.endDate).toISOString().split('T')[0]
    }));
  }
}, [rowData.endDate]);


  const handleEdit = async (event) => {
    event.preventDefault();
    try {
      setIsSubmitted(true);

      const invalidField = getFirstInvalidField();
      if (invalidField) {
        invalidField.scrollIntoView({ behavior: "smooth", block: "center" });
        return;
      }

      setIsLoading(true); // Set loading state to true while fetching data
      const response = await CallPOSTAPI(
        `api/update-user-v2/${rowData.user_id}`,
        formData
      );
      if (response.status) {
        console.log("Data edited successfully!");
        toast.success("Data edited successfully.");
        navigate("/report-list");
      } else {
        toast.error("Failed to edit data.");
        console.error("Failed to edit data.");
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again later.");
      console.error("Error:", error);
    } finally {
      setIsLoading(false); // Set loading state back to false after fetching data
    }
  };

  const getFirstInvalidField = () => {
    const fieldsToCheck = ["account", "first_name", "email"]; // Add other fields to check if needed

    for (const field of fieldsToCheck) {
      if (!formData[field]) {
        return formRef.current.querySelector(`#${field}`);
      }
    }

    return null;
  };

  // Validation function for a specific field
  const validateField = (fieldName) => {
    if (isSubmitted && !formData[fieldName]) {
      return "This field is required";
    }
    return "";
  };

  const handlechange = (event) => {
    const { name, value } = event.target;
    console.log("Date change - name:", name, "value:", value); // For debugging
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  return (
    <>
      <main id="main" className="main">
        <section>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Create Customer</h5>
              {/* Bordered Tabs Justified */}
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <form method="post" onSubmit={handleEdit} ref={formRef}>
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-6">
                            <h4 className="mb-3">Account Profile</h4>
                            
                            <div className="row mb-3">
                              <label
                                htmlFor="text"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start"
                              >
                                Password :
                              </label>
                              <div className="col-sm-6">
                                <input
                                  className="form-control w-100"
                                  placeholder=""
                                  type="text"
                                  id="password"
                                  name="password"
                                  value={formData.password}
                                  onChange={handlechange}
                                  required=""
                                />
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label
                                htmlFor="first_name"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                First Name*:
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className={`form-control w-100 ${
                                    validateField("first_name")
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  value={formData.first_name}
                                  onChange={handlechange}
                                  id="first_name"
                                  name="first_name"
                                  placeholder=""
                                  required=""
                                />
                                {/* Validation message */}
                                {validateField("first_name") && (
                                  <div className="invalid-feedback">
                                    {validateField("first_name")}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label
                                htmlFor="lastname"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Last Name:
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.last_name}
                                  onChange={handlechange}
                                  id="last_name"
                                  name="last_name"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="company"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Company:
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.company}
                                  onChange={handlechange}
                                  id="company"
                                  name="company"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                           
                            <div className="row mb-3">
                              <label
                                htmlFor="contact_number"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Contact Number:
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="number"
                                  className="form-control w-100"
                                  value={formData.contact_number}
                                  onChange={handlechange}
                                  id="contact_number"
                                  name="contact_number"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="email"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Email*:
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="email"
                                  className={`form-control w-100 ${
                                    validateField("email") ? "is-invalid" : ""
                                  }`}
                                  value={formData.email}
                                  onChange={handlechange}
                                  id="email"
                                  name="email"
                                  placeholder=""
                                  required=""
                                />
                                {/* Validation message */}
                                {validateField("email") && (
                                  <div className="invalid-feedback">
                                    {validateField("email")}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="address1"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Address1:
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.address1}
                                  onChange={handlechange}
                                  id="address1"
                                  name="address1"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="address2"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Address2:
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.address2}
                                  onChange={handlechange}
                                  id="inputEmail"
                                  name="address2"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                           
                           
                        
                            <div className="row mb-3">
                              <label
                                htmlFor="country"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Country*
                              </label>
                              <div className="col-sm-6">
                                <select
                                  class="form-select form-select-md"
                                  aria-label=".form-select-lg example"
                                  id="country"
                                  name="country"
                                  value={formData.country}
                                  onChange={handlechange}
                                >
                                  <option value="India" selected>
                                    India
                                  </option>
                                  <option value="Inactive">Inactive</option>
                                </select>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Timezone
                              </label>
                              <div className="col-sm-6">
                                <select
                                  class="form-select form-select-md"
                                  aria-label=".form-select-lg example"
                                  id="time_zone"
                                  name="time_zone"
                                  value={formData?.time_zone}
                                  onChange={handlechange}
                                >
                                  <option value="Mumbai india" selected>
                                    Mumbai india{" "}
                                  </option>
                                  <option value="Inactive">Inactive</option>
                                </select>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="currency"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Currency
                              </label>
                              <div className="col-sm-6">
                                <select
                                  class="form-select form-select-md"
                                  aria-label=".form-select-lg example"
                                  id="currency"
                                  name="currency"
                                  value={formData.currency}
                                  onChange={handlechange}
                                >
                                  <option value="U.S.Dollar(usd)" selected>
                                    U.S.Dollar(usd)
                                  </option>
                                  <option value="Inactive">Inactive</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <h4 className="mb-3">Account Settings</h4>
                            <div className="row mb-3">
                              <label
                                htmlFor="status"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Status{" "}
                              </label>
                              <div className="col-sm-6">
                                <select
                                  class="form-select form-select-md"
                                  aria-label=".form-select-lg example"
                                  value={formData.status}
                                  onChange={handlechange}
                                  id="status"
                                  name="status"
                                >
                                  <option value={true} selected>
                                    Active
                                  </option>
                                  <option value={false}>Inactive</option>
                                </select>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="allowrecording"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Allow Recoding
                              </label>
                              <div className="col-sm-6">
                                <select
                                  class="form-select form-select-md"
                                  aria-label=".form-select-lg example"
                                  value={formData.allow_recoding}
                                  id="allow_recoding"
                                  name="allow_recoding"
                                >
                                  <option value={true} selected>
                                    Yes
                                  </option>
                                  <option value={false}>No</option>
                                </select>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="allowip"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Allow IP Management
                              </label>
                              <div className="col-sm-6">
                                <select
                                  class="form-select form-select-md"
                                  aria-label=".form-select-lg example"
                                  value={formData.allow_ipmanagement}
                                  onChange={handlechange}
                                  id="allow_ipmanagement"
                                  name="allow_ipmanagement"
                                >
                                  <option value={true} selected>
                                    Yes
                                  </option>
                                  <option value={false}>No</option>
                                </select>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="numbertranslation"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Number Translation
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.number_translation}
                                  onChange={handlechange}
                                  id="number_translation"
                                  name="number_translation"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label
                                htmlFor="outercaller"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                OUT Caller id Translation
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.out_callerid_translation}
                                  onChange={handlechange}
                                  id="out_callerid_translation"
                                  name="out_callerid_translation"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="incallerid"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                IN Callerid Translation
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.in_callerid_translation}
                                  onChange={handlechange}
                                  id="in_callerid_translation"
                                  name="in_callerid_translation"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="concurrentcalls"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Concurrent calls
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.concurrent_calls}
                                  onChange={handlechange}
                                  id="concurrent_calls"
                                  name="concurrent_calls"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="cps"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                CPS
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.cps}
                                  onChange={handlechange}
                                  id="cps"
                                  name="cps"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="firstused"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                First Used
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.first_used}
                                  onChange={handlechange}
                                  id="first_used"
                                  name="first_used"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="accountvaliddays"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Account Valid Days
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.account_valid_days}
                                  onChange={handlechange}
                                  id="account_valid_days"
                                  name="account_valid_days"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="expirydate"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Expiry date
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="date"
                                  className="form-control w-100"
                                  value={formData.endDate}
                                  onChange={handlechange}
                                  id="endDate"
                                  name="endDate"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>

                            <h4 className="my-4">Billing Setting</h4>
                            <div className="row mb-3">
                              <label
                                htmlFor="rategroup"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Rate Group*
                              </label>
                              <div className="col-sm-6">
                                <select
                                  class="form-select form-select-md"
                                  aria-label=".form-select-lg example"
                                  value={formData.rate_group}
                                  onChange={handlechange}
                                  id="rate_group"
                                  name="rate_group"
                                >
                                  <option selected>ONLYDID</option>
                                  <option value="1">USA-TollFree</option>
                                  <option value="2">USA</option>
                                </select>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="accounttype"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Account Type
                              </label>
                              <div className="col-sm-6">
                                <select
                                  class="form-select form-select-md"
                                  aria-label=".form-select-lg example"
                                  value={formData.account_type}
                                  onChange={handlechange}
                                  id="account_type"
                                  name="account_type"
                                >
                                  <option selected>Prepaid</option>
                                  <option value="1">Postpaid</option>
                                </select>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="billigshedule"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Billing Schedule
                              </label>
                              <div className="col-sm-6">
                                <select
                                  class="form-select form-select-md"
                                  aria-label=".form-select-lg example"
                                  value={formData.billing_schedule}
                                  onChange={handlechange}
                                  id="billing_schedule"
                                  name="billing_schedule"
                                >
                                  <option selected>Daily</option>
                                  <option value="1">Monthly</option>
                                </select>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="billingday"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Billing Day
                              </label>
                              <div className="col-sm-6">
                                <select
                                  class="form-select form-select-md"
                                  aria-label=".form-select-lg example"
                                  value={formData.billing_day}
                                  onChange={handlechange}
                                  id="billing_day"
                                  name="billing_day"
                                >
                                  <option value="1" selected>
                                    1
                                  </option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="28">28</option>
                                </select>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Balance
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.balance}
                                  onChange={handlechange}
                                  id="balance"
                                  name="balance"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                IP ADDRESS
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.ip_address}
                                  onChange={handlechange}
                                  id="ip_address"
                                  name="ip_address"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="credit_limit"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Credit Limit
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.credit_limit}
                                  onChange={handlechange}
                                  id="credit_limit"
                                  name="credit_limit"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="allow_localcalls"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Allow Local Calls
                              </label>
                              <div className="col-sm-6">
                                <select
                                  class="form-select form-select-md"
                                  aria-label=".form-select-lg example"
                                  id="allow_localcalls"
                                  name="allow_local_calls"
                                  value={formData.allow_local_calls}
                                >
                                  <option selected>Yes</option>
                                  <option value="1">No</option>
                                </select>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="lc_charge"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                LC Charge/Min
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.lc_charge}
                                  onChange={handlechange}
                                  id="lc_charge"
                                  name="lc_charge"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="tax"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Tax
                              </label>
                              <div className="col-sm-6">
                                <textarea
                                  className="form-control"
                                  placeholder=""
                                  id="tax"
                                  name="tax"
                                  value={formData.tax}
                                  onChange={handlechange}
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="tax_number"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Tax Number
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={formData.tax_number}
                                  onChange={handlechange}
                                  id="tax_number"
                                  name="tax_number"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div>
                              <h4 className="my-4">Alert Theshold</h4>
                              <div className="row mb-3">
                                <label
                                  htmlFor="inputEmail"
                                  className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                                >
                                  Email Alerts
                                </label>
                                <div className="col-sm-6">
                                  <select
                                    class="form-select form-select-md"
                                    aria-label=".form-select-lg example"
                                    id="email_alerts"
                                    name="email_alerts"
                                    value={formData.email_alerts}
                                  >
                                    <option selected>Yes</option>
                                    <option value="1">No</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <label
                                  htmlFor="balance_below"
                                  className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                                >
                                  Balance Below
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    className="form-control w-100"
                                    value={formData.balance_below}
                                    onChange={handlechange}
                                    id="balance_below"
                                    name="balance_below"
                                    placeholder=""
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-5 m-2 d-flex justify-content-end">
                          <button
                            type="submit"
                            className={`btn btn-success px-5 ${
                              isLoading ? "fade-bg" : ""
                            }`}
                          >
                            {isLoading ? "Edited..." : "Edit"}
                          </button>
                        </div>
                        <div className="col-sm-6 m-2 d-flex justify-content-start">
                          <button
                            type="button"
                            className="btn btn-primary px-5"
                            onClick={() => {
                              navigate("/report-list");
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-6"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
