import React, { useState, useEffect } from "react";
import { reportcolumns, reportdata } from "./CustomerReportData";
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";
import { CallGETAPI } from "../helper/Constants";
import moment from "moment-timezone";
import ReactLoading from "react-loading";

function CustomerReport() {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setTodate] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [destination, setDestination] = useState("");
  const [duration, setDuration] = useState("");
  const [account, setAccount] = useState("");
  const [trunk, setTrunk] = useState("");
  const [calltype, setCallType] = useState("");
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [filteredData, setFilteredData] = useState(reportdata);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCallDetails = async () => {
    setIsLoading(true);
    try {
      const response = await CallGETAPI(`api/calls`);
      const result = response?.data?.data;
      if (response.status) {
        setFilteredData(result);
      } else {
        setFilteredData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setFilteredData([]); // Clear data in case of error
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCallDetails();
  }, []);

  const fetchFilteredCallDetails = async () => {
    try {
      const queryParams = new URLSearchParams({
        start_time: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
        end_time: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
        callType: calltype,
      }).toString();

      const response = await CallGETAPI(
        `api/get-filtered-calls?${queryParams}`
      );
      const result = response?.data?.data;
      if (response.status) {
        setFilteredData(result);
      } else {
        setFilteredData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setFilteredData([]);
    }
  };

  useEffect(() => {
    fetchFilteredCallDetails();
  }, [searchTrigger]);

  const handleFromDate = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDate = (e) => {
    setTodate(e.target.value);
  };

  const handleCalltype = (e) => {
    setCallType(e.target.value);
  };

  const handleClear = () => {
    setFromDate("");
    setTodate("");
    setCallType("");
    setSearchTrigger(false);
    setFilteredData([]);
  };
  const handleSearch = () => {
    setSearchTrigger((prev) => !prev);
  };
  const handleExportClick = () => {
    //Export logic here
    console.log("Exporting data:", filteredData);
  };
  const customStyles = {
    table: {
      style: {
        borderCollapse: "collapse",
        border: "1px solid black", // Set the header border
      },
    },
    rows: {
      style: {
        borderCollapse: "collapse",
      },
    },
    columns: {
      style: {
        borderCollapse: "collapse",
      },
    },
    header: {
      style: {
        border: "1px solid black", // Set the header border
      },
    },
  };

  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle"></div>
        <section>
          <div className="card">
            <div className="card-body mt-3">
              <h1></h1>
              {/* Bordered tabs Justified */}
              <div className="container-fluid">
                <div className="row">
                  <div className="col-6 d-flex justify-content-start ">
                    <h5 className="text-left">Customer CDRs Report</h5>
                  </div>
                </div>
                <div className="container-fluid mt-4 text-center">
                  <div className="row ">
                    <div className="col-12 d-flex justify-content-end mb-3">
                      <button
                        type="button"
                        class="btn btn-warning my-2"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Search
                      </button>
                    </div>
                    <div className="col-lg-12 p-4">
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse "
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="row mb-3">
                                <label
                                  htmlFor="inputEmail"
                                  className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                                >
                                  Start date
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="date"
                                    className="form-control w-100"
                                    value={fromDate}
                                    onChange={handleFromDate}
                                    id="date"
                                    placeholder=""
                                    required=""
                                  />
                                </div>
                              </div>
                              <div className="row mb-3">
                                <label
                                  htmlFor="inputEmail"
                                  className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                                >
                                  End date
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="date"
                                    className="form-control w-100"
                                    value={toDate}
                                    onChange={handleToDate}
                                    id="date"
                                    placeholder=""
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="row mb-3">
                                <label
                                  htmlFor="inputEmail"
                                  className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                                >
                                  Call Type
                                </label>
                                <div className="col-sm-6">
                                  <select
                                    className="form-select form-select-md"
                                    id="calltype"
                                    value={calltype}
                                    onChange={handleCalltype}
                                  >
                                    <option value="">--Select--</option>
                                    <option value="CALLPROCESSED">
                                      CALLPROCESSED
                                    </option>
                                    <option value="EXTENSION">EXTENSION</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row my-4">
                                <div className="col-12 d-flex justify-content-evenly">
                                  <button
                                    type="button"
                                    class="btn btn-success px-5"
                                    onClick={handleSearch}
                                  >
                                    Search
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-primary px-5"
                                    onClick={handleClear}
                                  >
                                    Clear
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container-fluid mt-4 text-center">
                  <div className="row ">
                    <div className="col-6 d-flex justify-content-start">
                      <CSVLink
                        data={filteredData}
                        filename="filtered_data.csv"
                        className="btn btn-sm btn-outline-primary"
                        onClick={handleExportClick}
                      >
                        <i className="fa-solid fa-download"></i> Export
                      </CSVLink>
                    </div>
                  </div>
                </div>
                <div className="container-fluid mt-4 text-center">
                  <div className="row">
                    <div
                      className="tab-content"
                      id="borderedTabJustifiedContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="boredered-justified-campaign"
                        role="tabpanel"
                        aria-labelledby="campaign-tab"
                      >
                        <div className="card" style={{ boxShadow: "none" }}>
                          <div
                            className="card-body"
                            style={{ padding: 0, overflowX: "auto" }}
                          >
                            {isLoading && (
                              <div
                                className="d-flex justify-content-center my-5"
                                style={{ marginTop: "20px" }}
                              >
                                <ReactLoading
                                  type="spokes"
                                  color="grey"
                                  height={50}
                                  width={50}
                                />
                              </div>
                            )}

                            {!isLoading && (
                              <div className="table-responsive">
                                <div className="main">
                                  <DataTable
                                    columns={reportcolumns}
                                    data={filteredData}
                                    noHeader
                                    defaultSortField="id"
                                  
                                    defaultSortAsc={true}
                                    pagination
                                    highlightOnHover
                                    dense
                                    customStyles={customStyles}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default CustomerReport;
