// import { useNavigate } from "react-router-dom";
// import React, { useState, useRef } from "react";
// import { toast } from "react-toastify";
// import { CallPOSTAPI } from "../helper/Constants";

// function CreateCustmer() {
//   const [isLoading, setIsLoading] = useState(false);
//   const [isSubmitted, setIsSubmitted] = useState(false);
//   const navigate = useNavigate();
//   const formRef = useRef(null);

//   const [formData, setFormData] = useState({
//     account: "",
//     password: "",
//     pin: "",
//     first_name: "",
//     last_name: "",
//     company: "",
//     phone: "",
//     contact_number: "",
//     email: "",
//     address1: "",
//     address2: "",
//     city: "",
//     state: "",
//     zip_code: "",
//     country: "India",
//     time_zone: "",
//     currency: "",
//     status: true,
//     allow_recoding: true,
//     allow_ipmanagement: true,
//     number_translation: "",
//     out_callerid_translation: "",
//     in_callerid_translation: "",
//     register_proxy: "",
//     channel: "",
//     concurrent_calls: "",
//     cps: "",
//     first_used: "",
//     account_valid_days: "",
//     expiry_date: "",
//     rate_group: "USA-TollFree",
//     account_type: "",
//     billing_schedule: "",
//     billing_day: "",
//     balance: "",
//     credit_limit: "",
//     allow_local_calls: true,
//     lc_charge: "",
//     tax: "",
//     tax_number: "",
//     email_alerts: true,
//     balance_below: "",
//   });

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       setIsSubmitted(true);

//       const invalidField = getFirstInvalidField();
//       if (invalidField) {
//         invalidField.scrollIntoView({ behavior: "smooth", block: "center" });
//         return;
//       }

//       setIsLoading(true);

//       const response = await CallPOSTAPI("api/create-user-v2", formData);
//       if (response.status) {
//         console.log("Data saved successfully!");
//         setFormData({
//           account: "",
//           password: "",
//           pin: "",
//           first_name: "",
//           last_name: "",
//           company: "",
//           phone: "",
//           contact_number: "",
//           email: "",
//           address1: "",
//           address2: "",
//           city: "",
//           state: "",
//           zip_code: "",
//           country: "",
//           time_zone: "",
//           currency: "",
//           status: true,
//           allow_recoding: true,
//           allow_ipmanagement: true,
//           number_translation: "",
//           out_callerid_translation: "",
//           in_callerid_translation: "",
//           register_proxy: "",
//           channel: "",
//           concurrent_calls: "",
//           cps: "",
//           first_used: "",
//           account_valid_days: "",
//           expiry_date: "",
//           rate_group: "",
//           account_type: "",
//           billing_schedule: "",
//           billing_day: "",
//           balance: "",
//           credit_limit: "",
//           allow_local_calls: true,
//           lc_charge: "",
//           tax: "",
//           tax_number: "",
//           email_alerts: true,
//           balance_below: "",
//         });
//         console.log(setFormData);
//         toast.success("Data saved Successful");
//         navigate("/report-list");
//       } else {
//         toast.error("Failed to save data.");
//         console.error("Failed to save data.");
//       }
//     } catch (error) {
//       toast.error("An unexpected error occurred. Please try again later.");
//       console.error("Error:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const getFirstInvalidField = () => {
//     const fieldsToCheck = ["", "first_name", "email"]; // Add other fields to check if needed

//     for (const field of fieldsToCheck) {
//       if (!formData[field]) {
//         return formRef.current.querySelector(`#${field}`);
//       }
//     }

//     return null;
//   };

//   // Validation function for a specific field
//   const validateField = (fieldName) => {
//     if (isSubmitted && !formData[fieldName]) {
//       return "This field is required";
//     }
//     return "";
//   };

//   const handlechange = (event) => {
//     const { name, value } = event.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: value,
//     }));
//   };

//   return (
//     <>
//       <main id="main" className="main">
//         <section>
//           <div className="card">
//             <div className="card-body">
//               <h5 className="card-title">Create Customer</h5>
//               {/* Bordered Tabs Justified */}
//               <div className="container">
//                 <div className="row">
//                   <div className="col-lg-12">
//                     <form method="post" onSubmit={handleSubmit} ref={formRef}>
//                       <div className="container">
//                         <div className="row">
//                           <div className="col-lg-6">
//                             <h4 className="mb-3">Account Profile</h4>
//                             {/* <div className="row mb-3">
//                               <label
//                                 htmlFor="account"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
//                               >
//                                 Account*:
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="text"
//                                   className={`form-control w-100 ${
//                                     validateField("account") ? "is-invalid" : ""
//                                   }`}
//                                   value={formData.account}
//                                   onChange={handlechange}
//                                   id="account"
//                                   name="account"
//                                   placeholder=""
//                                   required="This Feild is Required"
//                                 />
                              
//                                 {validateField("account") && (
//                                   <div className="invalid-feedback">
//                                     {validateField("account")}
//                                   </div>
//                                 )}
//                               </div>
                            
//                             </div> */}
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="text"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start"
//                               >
//                                 Password :
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   className="form-control w-100"
//                                   placeholder=""
//                                   type="text"
//                                   id="password"
//                                   name="password"
//                                   value={formData.password}
//                                   onChange={handlechange}
//                                   required=""
//                                 />
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="pin"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 Pin:
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="number"
//                                   className="form-control w-100"
//                                   value={formData.pin}
//                                   onChange={handlechange}
//                                   id="pin"
//                                   name="pin"
//                                   placeholder=""
//                                   required=""
//                                 />
//                               </div>
//                             </div>

//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="firstname"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 First Name*:
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="text"
//                                   className={`form-control w-100 ${
//                                     validateField("first_name")
//                                       ? "is-invalid"
//                                       : ""
//                                   }`}
//                                   value={formData.first_name}
//                                   onChange={handlechange}
//                                   id="first_name"
//                                   name="first_name"
//                                   placeholder=""
//                                 />
//                                 {/* Validation message */}
//                                 {validateField("first_name") && (
//                                   <div className="invalid-feedback">
//                                     {validateField("first_name")}
//                                   </div>
//                                 )}
//                               </div>
//                               {/* <p>{formErrors.first_name}</p> */}
//                             </div>

//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="lastname"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 Last Name:
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="text"
//                                   className="form-control w-100"
//                                   value={formData.last_name}
//                                   onChange={handlechange}
//                                   id="last_name"
//                                   name="last_name"
//                                   placeholder=""
//                                   required=""
//                                 />
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="company"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 Company:
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="text"
//                                   className="form-control w-100"
//                                   value={formData.company}
//                                   onChange={handlechange}
//                                   id="company"
//                                   name="company"
//                                   placeholder=""
//                                   required=""
//                                 />
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="phone"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 Phone:
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="number"
//                                   className="form-control w-100"
//                                   value={formData.phone}
//                                   onChange={handlechange}
//                                   id="phone"
//                                   name="phone"
//                                   placeholder=""
//                                   required=""
//                                 />
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="contact_number"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 Moblie:
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="number"
//                                   className="form-control w-100"
//                                   value={formData.contact_number}
//                                   onChange={handlechange}
//                                   id="contact_number"
//                                   name="contact_number"
//                                   placeholder=""
//                                   required=""
//                                 />
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="email"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 Email*:
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="email"
//                                   className={`form-control w-100 ${
//                                     validateField("email") ? "is-invalid" : ""
//                                   }`}
//                                   value={formData.email}
//                                   onChange={handlechange}
//                                   id="email"
//                                   name="email"
//                                   placeholder=""
//                                   required=""
//                                 />
//                                 {/* Validation message */}
//                                 {validateField("email") && (
//                                   <div className="invalid-feedback">
//                                     {validateField("email")}
//                                   </div>
//                                 )}
//                               </div>
//                               {/* <p>{formErrors.email}</p> */}
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="address1"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 Address1:
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="text"
//                                   className="form-control w-100"
//                                   value={formData.address1}
//                                   onChange={handlechange}
//                                   id="address1"
//                                   name="address1"
//                                   placeholder=""
//                                   required=""
//                                 />
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="address2"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 Address2:
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="text"
//                                   className="form-control w-100"
//                                   value={formData.address2}
//                                   onChange={handlechange}
//                                   id="inputEmail"
//                                   name="address2"
//                                   placeholder=""
//                                   required=""
//                                 />
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="city"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 City:
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="text"
//                                   className="form-control w-100"
//                                   value={formData.city}
//                                   onChange={handlechange}
//                                   id="city"
//                                   name="city"
//                                   placeholder=""
//                                   required=""
//                                 />
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="state"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 Province/State:
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="text"
//                                   className="form-control w-100"
//                                   value={formData.state}
//                                   onChange={handlechange}
//                                   id="state"
//                                   name="state"
//                                   placeholder=""
//                                   required=""
//                                 />
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="zip_code"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
//                               >
//                                 Zip/Postal Code
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="text"
//                                   className="form-control w-100"
//                                   value={formData.zip_code}
//                                   onChange={handlechange}
//                                   id="zip_code"
//                                   name="zip_code"
//                                   placeholder=""
//                                   required=""
//                                 />
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="country"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
//                               >
//                                 Country*
//                               </label>
//                               <div className="col-sm-6">
//                                 <select
//                                   class="form-select form-select-md"
//                                   aria-label=".form-select-lg example"
//                                   id="country"
//                                   name="country"
//                                   value={formData.country}
//                                   onChange={handlechange}
//                                 >
//                                   <option value="India" selected>
//                                     India
//                                   </option>
//                                   <option value="Inactive">Inactive</option>
//                                 </select>
//                               </div>
//                             </div>
//                             {/* <p>{formErrors.country}</p> */}
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="inputEmail"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
//                               >
//                                 Timezone
//                               </label>
//                               <div className="col-sm-6">
//                                 <select
//                                   class="form-select form-select-md"
//                                   aria-label=".form-select-lg example"
//                                   id="time_zone"
//                                   name="time_zone"
//                                   value={formData?.time_zone}
//                                   onChange={handlechange}
//                                 >
//                                   <option value="Mumbai india" selected>
//                                     Mumbai india{" "}
//                                   </option>
//                                   <option value="Inactive">Inactive</option>
//                                 </select>
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="currency"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
//                               >
//                                 Currency
//                               </label>
//                               <div className="col-sm-6">
//                                 <select
//                                   class="form-select form-select-md"
//                                   aria-label=".form-select-lg example"
//                                   id="currency"
//                                   name="currency"
//                                   value={formData.currency}
//                                   onChange={handlechange}
//                                 >
//                                   <option value="U.S.Dollar(usd)" selected>
//                                     U.S.Dollar(usd)
//                                   </option>
//                                   <option value="Inactive">Inactive</option>
//                                 </select>
//                               </div>
//                             </div>
//                           </div>
//                           <div className="col-lg-6">
//                             <h4 className="mb-3">Account Settings</h4>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="status"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
//                               >
//                                 Status{" "}
//                               </label>
//                               <div className="col-sm-6">
//                                 <select
//                                   class="form-select form-select-md"
//                                   aria-label=".form-select-lg example"
//                                   value={formData.status}
//                                   onChange={handlechange}
//                                   id="status"
//                                   name="status"
//                                 >
//                                   <option value={true} selected>
//                                     Active
//                                   </option>
//                                   <option value={false}>Inactive</option>
//                                 </select>
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="allowrecording"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
//                               >
//                                 Allow Recoding
//                               </label>
//                               <div className="col-sm-6">
//                                 <select
//                                   class="form-select form-select-md"
//                                   aria-label=".form-select-lg example"
//                                   value={formData.allow_recoding}
//                                   id="allow_recoding"
//                                   name="allow_recoding"
//                                 >
//                                   <option value={true} selected>
//                                     Yes
//                                   </option>
//                                   <option value={false}>No</option>
//                                 </select>
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="allowip"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
//                               >
//                                 Allow IP Management
//                               </label>
//                               <div className="col-sm-6">
//                                 <select
//                                   class="form-select form-select-md"
//                                   aria-label=".form-select-lg example"
//                                   value={formData.allow_ipmanagement}
//                                   onChange={handlechange}
//                                   id="allow_ipmanagement"
//                                   name="allow_ipmanagement"
//                                 >
//                                   <option value={true} selected>
//                                     Yes
//                                   </option>
//                                   <option value={false}>No</option>
//                                 </select>
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="numbertranslation"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 Number Translation
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="text"
//                                   className="form-control w-100"
//                                   value={formData.number_translation}
//                                   onChange={handlechange}
//                                   id="number_translation"
//                                   name="number_translation"
//                                   placeholder=""
//                                   required=""
//                                 />
//                               </div>
//                             </div>

//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="outercaller"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 OUT Caller id Translation
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="text"
//                                   className="form-control w-100"
//                                   value={formData.out_callerid_translation}
//                                   onChange={handlechange}
//                                   id="out_callerid_translation"
//                                   name="out_callerid_translation"
//                                   placeholder=""
//                                   required=""
//                                 />
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="incallerid"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 IN Callerid Translation
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="text"
//                                   className="form-control w-100"
//                                   value={formData.in_callerid_translation}
//                                   onChange={handlechange}
//                                   id="in_callerid_translation"
//                                   name="in_callerid_translation"
//                                   placeholder=""
//                                   required=""
//                                 />
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="concurrentcalls"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 Concurrent calls
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="text"
//                                   className="form-control w-100"
//                                   value={formData.concurrent_calls}
//                                   onChange={handlechange}
//                                   id="concurrent_calls"
//                                   name="concurrent_calls"
//                                   placeholder=""
//                                   required=""
//                                 />
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="cps"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 CPS
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="text"
//                                   className="form-control w-100"
//                                   value={formData.cps}
//                                   onChange={handlechange}
//                                   id="cps"
//                                   name="cps"
//                                   placeholder=""
//                                   required=""
//                                 />
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="firstused"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 First Used
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="text"
//                                   className="form-control w-100"
//                                   value={formData.first_used}
//                                   onChange={handlechange}
//                                   id="first_used"
//                                   name="first_used"
//                                   placeholder=""
//                                   required=""
//                                 />
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="accountvaliddays"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 Account Valid Days
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="text"
//                                   className="form-control w-100"
//                                   value={formData.account_valid_days}
//                                   onChange={handlechange}
//                                   id="account_valid_days"
//                                   name="account_valid_days"
//                                   placeholder=""
//                                   required=""
//                                 />
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="expirydate"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 Expiry date
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="date"
//                                   className="form-control w-100"
//                                   value={formData.expiry_date}
//                                   onChange={handlechange}
//                                   id="expiry_date"
//                                   name="expiry_date"
//                                   placeholder=""
//                                   required=""
//                                 />
//                               </div>
//                             </div>

//                             <h4 className="my-4">Billing Setting</h4>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="rate_group"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
//                               >
//                                 Rate Group*
//                               </label>
//                               <div className="col-sm-6">
//                                 <select
//                                   class="form-select form-select-md"
//                                   aria-label=".form-select-lg example"
//                                   value={formData.rate_group}
//                                   onChange={handlechange}
//                                   id="rate_group"
//                                   name="rate_group"
//                                 >
//                                   <option value="ONLYDID" selected>
//                                     ONLYDID
//                                   </option>
//                                   <option value="USA-TollFree">
//                                     USA-TollFree
//                                   </option>
//                                   <option value="USA">USA</option>
//                                 </select>
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="accounttype"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
//                               >
//                                 Account Type
//                               </label>
//                               <div className="col-sm-6">
//                                 <select
//                                   class="form-select form-select-md"
//                                   aria-label=".form-select-lg example"
//                                   value={formData.account_type}
//                                   onChange={handlechange}
//                                   id="account_type"
//                                   name="account_type"
//                                 >
//                                   <option selected>Prepaid</option>
//                                   <option value="1">Postpaid</option>
//                                 </select>
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="billigshedule"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
//                               >
//                                 Billing Schedule
//                               </label>
//                               <div className="col-sm-6">
//                                 <select
//                                   class="form-select form-select-md"
//                                   aria-label=".form-select-lg example"
//                                   value={formData.billing_schedule}
//                                   onChange={handlechange}
//                                   id="billing_schedule"
//                                   name="billing_schedule"
//                                 >
//                                   <option selected>Daily</option>
//                                   <option value="1">Monthly</option>
//                                 </select>
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="billingday"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
//                               >
//                                 Billing Day
//                               </label>
//                               <div className="col-sm-6">
//                                 <select
//                                   class="form-select form-select-md"
//                                   aria-label=".form-select-lg example"
//                                   value={formData.billing_day}
//                                   onChange={handlechange}
//                                   id="billing_day"
//                                   name="billing_day"
//                                 >
//                                   <option value="1" selected>
//                                     1
//                                   </option>
//                                   <option value="2">2</option>
//                                   <option value="3">3</option>
//                                   <option value="28">28</option>
//                                 </select>
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="inputEmail"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 Balance
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="text"
//                                   className="form-control w-100"
//                                   value={formData.balance}
//                                   onChange={handlechange}
//                                   id="balance"
//                                   name="balance"
//                                   placeholder=""
//                                   required=""
//                                 />
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="credit_limit"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 Credit Limit
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="text"
//                                   className="form-control w-100"
//                                   value={formData.credit_limit}
//                                   onChange={handlechange}
//                                   id="credit_limit"
//                                   name="credit_limit"
//                                   placeholder=""
//                                   required=""
//                                 />
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="allow_localcalls"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
//                               >
//                                 Allow Local Calls
//                               </label>
//                               <div className="col-sm-6">
//                                 <select
//                                   class="form-select form-select-md"
//                                   aria-label=".form-select-lg example"
//                                   id="allow_localcalls"
//                                   name="allow_local_calls"
//                                   value={formData.allow_local_calls}
//                                 >
//                                   <option selected>Yes</option>
//                                   <option value="1">No</option>
//                                 </select>
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="lc_charge"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 LC Charge/Min
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="text"
//                                   className="form-control w-100"
//                                   value={formData.lc_charge}
//                                   onChange={handlechange}
//                                   id="lc_charge"
//                                   name="lc_charge"
//                                   placeholder=""
//                                   required=""
//                                 />
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="tax"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 Tax
//                               </label>
//                               <div className="col-sm-6">
//                                 <textarea
//                                   className="form-control"
//                                   placeholder=""
//                                   id="tax"
//                                   name="tax"
//                                   value={formData.tax}
//                                   onChange={handlechange}
//                                 />
//                               </div>
//                             </div>
//                             <div className="row mb-3">
//                               <label
//                                 htmlFor="tax_number"
//                                 className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                               >
//                                 Tax Number
//                               </label>
//                               <div className="col-sm-6">
//                                 <input
//                                   type="text"
//                                   className="form-control w-100"
//                                   value={formData.tax_number}
//                                   onChange={handlechange}
//                                   id="tax_number"
//                                   name="tax_number"
//                                   placeholder=""
//                                   required=""
//                                 />
//                               </div>
//                             </div>
//                             <div>
//                               <h4 className="my-4">Alert Theshold</h4>
//                               <div className="row mb-3">
//                                 <label
//                                   htmlFor="inputEmail"
//                                   className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
//                                 >
//                                   Email Alerts
//                                 </label>
//                                 <div className="col-sm-6">
//                                   <select
//                                     class="form-select form-select-md"
//                                     aria-label=".form-select-lg example"
//                                     id="email_alerts"
//                                     name="email_alerts"
//                                     value={formData.email_alerts}
//                                   >
//                                     <option selected>Yes</option>
//                                     <option value="1">No</option>
//                                   </select>
//                                 </div>
//                               </div>
//                               <div className="row mb-3">
//                                 <label
//                                   htmlFor="balance_below"
//                                   className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
//                                 >
//                                   Balance Below
//                                 </label>
//                                 <div className="col-sm-6">
//                                   <input
//                                     type="text"
//                                     className="form-control w-100"
//                                     value={formData.balance_below}
//                                     onChange={handlechange}
//                                     id="balance_below"
//                                     name="balance_below"
//                                     placeholder=""
//                                     required=""
//                                   />
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="row">
//                         <div className="col-sm-5 m-2 d-flex justify-content-end">
//                           <button
//                             type="submit"
//                             className={`btn btn-success px-5 ${
//                               isLoading ? "fade-bg" : ""
//                             }`}
//                           >
//                             {isLoading ? "Saving..." : "Save"}
//                           </button>
//                         </div>
//                         <div className="col-sm-6 m-2 d-flex justify-content-start">
//                           <button
//                             type="button"
//                             className="btn btn-primary px-5"
//                             onClick={() => {
//                               navigate("/report-list");
//                             }}
//                           >
//                             Cancel
//                           </button>
//                         </div>
//                       </div>
//                     </form>
//                   </div>
//                   <div className="col-lg-6"></div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       </main>
//     </>
//   );
// }

// export default CreateCustmer;






import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CallPOSTAPI, CallPostFileUpload } from '../helper/Constants';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const CreateCustomer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    repassword: '',
    contact_number: '',
    country: '',
    address1: '',
    address2: '',
    company: '',
    company_website: '',
    referred_by: '',
    user_type: ''
  });

  const [files, setFiles] = useState({
    id_proof_front: null,
    id_proof_back: null,
    address_proof_front: null,
    address_proof_back: null,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setFiles((prevFiles) => ({
      ...prevFiles,
      [name]: files[0],
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const formDataToSend = new FormData();

      // Append form fields
      Object.keys(formData).forEach(key => {
        formDataToSend.append(key, formData[key]);
      });

      // Append files
      Object.keys(files).forEach(key => {
        if (files[key]) {
          formDataToSend.append(key, files[key]);
        }
      });

      // Call API
      const response = await CallPostFileUpload('api/create-user-v2', formDataToSend);

      if (response.status === true) {
        toast.success(response.message || "User created successfully, please check your email for verification link");
        navigate('/report-list');
      } else {
        // Check if the error is due to existing email
        if (response.message === "Email already exists") {
          toast.error("This email is already registered. Please use a different email.");
        } else {
          toast.error(response.message || "Email already exists");
        }
      }
    } catch (error) {
      toast.error('An unexpected error occurred. Please try again later.');
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

   // Toggle password visibility
   const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Create Customer</h5>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="first_name" className="form-label">First Name*</label>
                  <input
                    type="text"
                    className="form-control"
                    id="first_name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="last_name" className="form-label">Last Name*</label>
                  <input
                    type="text"
                    className="form-control"
                    id="last_name"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="email" className="form-label">Email*</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="referred_by" className="form-label">Referred By</label>
                <input
                  type="text"
                  className="form-control"
                  id="referred_by"
                  name="referred_by"
                  value={formData.referred_by}
                  onChange={handleChange}
                />
              </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="password" className="form-label">Password*</label>
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}  
                      className="form-control"
                      id="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={togglePasswordVisibility}
                      style={{ border: "none" }}
                    >
                      {showPassword ? <FaEye /> : <FaEyeSlash />}  {/* Icon for show/hide password */}
                    </button>
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="repassword" className="form-label">Confirm Password*</label>
                  <div className="input-group">
                    <input
                      type={showConfirmPassword ? "text" : "password"}  // Toggle for confirm password field
                      className="form-control"
                      id="repassword"
                      name="repassword"
                      value={formData.repassword}
                      onChange={handleChange}
                      required
                    />
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={toggleConfirmPasswordVisibility}
                      style={{ border: "none" }}
                    >
                      {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}  {/* Icon for show/hide confirm password */}
                    </button>
                  </div>
                </div>
              </div>

              <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="contact_number" className="form-label">Contact Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="contact_number"
                  name="contact_number"
                  value={formData.contact_number}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="country" className="form-label">Country</label>
                <input
                  type="text"
                  className="form-control"
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                />
              </div>
              </div>
              <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="address1" className="form-label">Address 1</label>
                <input
                  type="text"
                  className="form-control"
                  id="address1"
                  name="address1"
                  value={formData.address1}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="address2" className="form-label">Address 2</label>
                <input
                  type="text"
                  className="form-control"
                  id="address2"
                  name="address2"
                  value={formData.address2}
                  onChange={handleChange}
                />
              </div>
              </div>
              <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="company" className="form-label">Company</label>
                <input
                  type="text"
                  className="form-control"
                  id="company"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="company_website" className="form-label">Company Website</label>
                <input
                  type="text"
                  className="form-control"
                  id="company_website"
                  name="company_website"
                  value={formData.company_website}
                  onChange={handleChange}
                />
              </div>
              </div>
              <div className="row">
             
              <div className="col-md-6 mb-3">
                <label htmlFor="id_proof_front" className="form-label">ID Proof Front*</label>
                <input
                  type="file"
                  className="form-control"
                  id="id_proof_front"
                  name="id_proof_front"
                  onChange={handleFileChange}
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="id_proof_back" className="form-label">ID Proof Back*</label>
                <input
                  type="file"
                  className="form-control"
                  id="id_proof_back"
                  name="id_proof_back"
                  onChange={handleFileChange}
                  required
                />
              </div>
              </div>
              <div className="row">
             
              <div className="col-md-6 mb-3">
                <label htmlFor="address_proof_front" className="form-label">Address Proof Front*</label>
                <input
                  type="file"
                  className="form-control"
                  id="address_proof_front"
                  name="address_proof_front"
                  onChange={handleFileChange}
                  required
                />
              </div>
              

              <div className="col-md-6 mb-3">
                <label htmlFor="address_proof_back" className="form-label">Address Proof Back*</label>
                <input
                  type="file"
                  className="form-control"
                  id="address_proof_back"
                  name="address_proof_back"
                  onChange={handleFileChange}
                  required
                />
              </div>
              </div>
              <div className="col-md-6 mb-3">
    <label htmlFor="user_type" className="form-label">User Type*</label>
    <select
      className="form-control"
      id="user_type"
      name="user_type"
      value={formData.user_type}
      onChange={handleChange}
      required
    >
      <option value="">Select User Type</option>
      {/* <option value="1">Admin</option> You can customize these options */}
      <option value="2">User</option>
      <option value="3">Extension</option>
      <option value="4">Forwarding</option>
    </select>
  </div>
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <button
                    type="submit"
                    className={`btn btn-success w-100 ${isLoading ? 'opacity-50' : ''}`}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Saving...' : 'Save'}
                  </button>
                </div>
                <div className="col-sm-6 mb-3">
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    onClick={() => navigate('/report-list')}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CreateCustomer;