import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import ReactLoading from "react-loading";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CallGETAPI } from '../helper/Constants';

const SipStatusTable = () => {
  const [extensions, setExtensions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchExtensionsStatus = async () => {
      try {
        setIsLoading(true);
        setError(null);

        const response = await CallGETAPI("api/all-extensions");
        
        if (response.status) {
          const formattedData = response?.data?.data.map(ext => ({
            extension: ext.extension_id,
            status: ext.status,
            last_checked: ext.last_checked,
            version: ext.version,
            user_id: ext.user_id
          }));
          
          setExtensions(formattedData);
          console.log('Extensions Status Data:', formattedData);
        } else {
          throw new Error(response.message || 'Failed to fetch extension data');
        }
      } catch (error) {
        console.error('Error fetching extensions status:', error);
        setError('Failed to load extension data. Please try again later.');
        toast.error('Failed to load extension data');
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch extensions status on component mount
    fetchExtensionsStatus();

    // Refresh data every 30 seconds
    const interval = setInterval(fetchExtensionsStatus, 30000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const columns = [
    {
      name: "Extension ID",
      selector: row => row.extension,
      sortable: true,
    },
    {
      name: "Status",
      selector: row => row.status,
      sortable: true,
      cell: row => (
        <span className={`badge ${
          row.status === 'REGISTER' 
            ? 'bg-success'
            : 'bg-danger'
        }`}>
          {row.status === 'REGISTER' ? 'Registered' : 'Unregistered'}
        </span>
      ),
    },
    {
      name: "User ID",
      selector: row => row.user_id || 'N/A',
      sortable: true,
    },
    // {
    //   name: "Last Checked",
    //   selector: row => row.last_checked,
    //   sortable: true,
    //   cell: row => new Date(row.last_checked).toLocaleString(),
    // },
    // {
    //   name: "Version",
    //   selector: row => row.version,
    //   sortable: true,
    // }
  ];

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">Extension Status</li>
          </ol>
        </nav>
      </div>
      <section className="section dashboard">
        <div className="card">
          <div className="card-body mt-3">
            <h3>
              <strong className="border-bottom border-3 pb-2">
                Extension Status
              </strong>
            </h3>
            <div className="card" style={{ boxShadow: "none" }}>
              <div className="card-body" style={{ padding: 0 }}>
                <div className="container-fluid d-flex justify-content-center">
                  <div className="w-100">
                    {isLoading && (
                      <div className="d-flex justify-content-center my-5">
                        <ReactLoading
                          type="spokes"
                          color="grey"
                          height={50}
                          width={50}
                        />
                      </div>
                    )}
                    {!isLoading && (
                      <div className="main">
                        <DataTable
                          columns={columns}
                          data={extensions}
                          pagination
                          highlightOnHover
                          responsive
                          noDataComponent="No Extensions Available"
                          defaultSortField="extension"
                          defaultSortAsc={true}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default SipStatusTable;