import React, { useEffect } from 'react';
import ReactLoading from "react-loading";

const EndDateModal = ({ show, onClose, editEndDateValue, onChange, onSave, isLoading }) => {
  // Format the date to YYYY-MM-DD for the input
  const formatDate = (dateString) => {
    if (!dateString) return '';
    try {
      const date = new Date(dateString);
      return date.toISOString().split('T')[0];
    } catch (error) {
      console.error('Date formatting error:', error);
      return '';
    }
  };

  const formattedDate = formatDate(editEndDateValue);

  if (!show) return null;

  return (
    <div className="modal fade show" style={{ display: "block" }} role="dialog">
      <div className="modal-dialog" style={{ margin: '100px auto' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Update Subscription End Date</h5>
            <button
              type="button"
              className="close"
              onClick={onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="endDate">New End Date</label>
              <input
                type="date"
                className="form-control"
                id="endDate"
                value={formattedDate}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Close
            </button>
            {isLoading ? (
              <ReactLoading type="spin" color="blue" height={30} width={30} />
            ) : (
              <button type="button" className="btn btn-primary" onClick={onSave}>
                Update Expire Date
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndDateModal;