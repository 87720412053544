import React,{useEffect} from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import chart from "chart.js/auto";
import { Color } from "@kurkle/color";
import { useState, useCallback } from "react";
import { AnsweredCalls, CallPOSTAPI, TotalCall } from "../helper/Constants";
import FilterDropdown from "./FilterDropdown";
import { Spinner } from "react-bootstrap";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

function Dashboard() {
  const [dataSeries, setDataSeries] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [startDate, setStartDate] = useState("2024-09-01"); // Set your default start date
  const [endDate, setEndDate] = useState("2024-09-30"); // Set your default end date
  const [totalCalls, setTotalCalls] = useState(0); // State for total calls
  const [loading, setLoading] = useState(false); // New loading state

  // const [totalDuration, setTotalDuration] = useState(0); // State for total duration
  const [totalProfit, setTotalProfit] = useState(0); // State for total profit
  const toggleDataSeries = useCallback((e) => {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      this.toggleDataSeries = this.toggleDataSeries.bind(this);
      this.addSymbols = this.addSymbols.bind(this);

      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    chart.render();

    // Logic to toggle data series
  }, []);

  const addSymbols = useCallback((e) => {
    var suffixes = ["", "K", "M", "B"];
    var order = Math.max(
      Math.floor(Math.log(Math.abs(e.value)) / Math.log(1000)),
      0
    );
    if (order > suffixes.length - 1) order = suffixes.length - 1;
    var suffix = suffixes[order];
    return (
      CanvasJSReact.CanvasJS.formatNumber(e.value / Math.pow(1000, order)) +
      suffix
    );

    // Logic to add symbols
  }, []);

  useEffect(() => {
    fetchCallDetail();
    fetchExtensionData();
  }, [startDate, endDate]);


  const fetchCallDetail = async () => {
    setLoading(true); 
    try {
      const response = await CallPOSTAPI(`api/get-cdr-date-Call-Processed2`, {
        start_time: startDate,
        end_time: endDate,
        // user_id: userId,
      });

      if (response.status) {
        console.log(response, "check date wise data");
        const result = response?.data?.data?.dateRange;
        processChartData(result);
        const total = response.data.data.total;
        if (total) {
          setTotalCalls(total.count);
          setTotalProfit(total.margin);
        }
      }
    } catch (error) {
      console.error("Error fetching call details:", error);
    } finally {
      setLoading(false); // Stop loading after data fetch
    }
  };

  // Function to handle filter changes from FilterDropdown
  const handleFilterChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    fetchCallDetail(startDate, endDate);
  };

  const processChartData = (data) => {
    const processedData = data.map(item => ({
      x: new Date(item.date),
      NOANSWEREDCount: parseInt(item.NOANSWEREDCount),
      incomingCount: parseInt(item.incomingCount),
      ANSWERCount: parseInt(item.ANSWERCount),
      CANCELCount: parseInt(item.CANCELCount),
      marginCount: parseInt(item.marginCount)

    }));
    setChartData(processedData);
  };

  const options = {
    animationEnabled: true,
    colorSet: "colorSet2",
    axisY: {
      labelFormatter: addSymbols,
    },
    toolTip: {
      shared: true,
    },
    legend: {
      cursor: "pointer",
      itemclick: toggleDataSeries,
      verticalAlign: "top",
    },
    data: [
      {
        type: "line",
        name: "Incoming Calls",
        showInLegend: true,
        dataPoints: chartData.map(item => ({ x: item.x, y: item.incomingCount })),
      },
      {
        type: "line",
        name: "Answered Calls",
        showInLegend: true,
        dataPoints: chartData.map(item => ({ x: item.x, y: item.ANSWERCount })),
      },
      {
        type: "area",
        name: "No Answer",
        markerBorderColor: "white",
        markerBorderThickness: 2,
        showInLegend: true,
        dataPoints: chartData.map(item => ({ x: item.x, y: item.NOANSWEREDCount })),
      },
      {
        type: "area",
        name: "Cancelled Calls",
        showInLegend: true,
        dataPoints: chartData.map(item => ({ x: item.x, y: item.CANCELCount })),
      },
      {
        type: "area",
        name: "Profit",
        showInLegend: true,
        dataPoints: chartData.map(item => ({ x: item.x, y: item.marginCount })),
      },
    ],
  };


  const fetchExtensionData = async () => {
    try {
      const response = await CallPOSTAPI(`api/get-cdr-date-Call-Extensions`);
      if (response.status && response.data && response.data.data) {
        processPieChartData(response.data.data);
        const resultData  = response.data.data;
        console.log({resultData})
        const pieData = [
          { y: parseInt(resultData.total.count) || 0, label: "Total Calls" },
          { y: parseInt(resultData.total.misscalls) || 0, label: "Missed Calls" },
          { y: parseInt(resultData.total.Answere) || 0, label: "Answered Calls" }
        ];
        // console.log()
        setPieChartData(pieData);

      }
    } catch (error) {
      console.error("Error fetching pie chart data:", error);
    }
  };


  const processPieChartData = (data) => {
    // const pieData = [
    //   { y: parseInt(data.TotalCallCounts) || 0, label: "Total Calls" },
    //   { y: parseInt(data.misscalls) || 0, label: "Missed Calls" },
    //   { y: parseInt(data.Answere) || 0, label: "Answered Calls" }
    // ];
    // setPieChartData(pieData);
  };


  const pieOptions = {
    animationEnabled: true,
    title: {
      text: "Call Distribution"
    },
    data: [{
      type: "pie",
      showInLegend: true,
      legendText: "{label}",
      toolTipContent: "{label}: <strong>{y}</strong>",
      indexLabel: "{label} - {y}",
      indexLabelPlacement: "outside",
      dataPoints: pieChartData
    }]
  };

  const bucketListData = [
    {
      country: "USA",
      name: "John",
      previousMonth: "Jan",
      remaining: "NA",
      assigned: 6,
      usedPercentage: "36%",
    },
    {
      country: "UK",
      name: "Mariam",
      previousMonth: "Mar",
      remaining: "NA",
      assigned: 12,
      usedPercentage: "42%",
    },
    {
      country: "UK",
      name: "Alman",
      previousMonth: "Nov",
      remaining: "NA",
      assigned: 2,
      usedPercentage: "22%",
    },
  ];

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Dashboard</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.html">Home</a>
            </li>
            <li className="breadcrumb-item active">Dashboard</li>
          </ol>
        </nav>
      </div>

      <section className="section dashboard">
        <div className="row">
          <div className="col-lg-12">
            <div className="col-12">
              <div className="card top-selling overflow-auto pt-3 pb-3">
                <div className="card-body pb-0">
                  <div className="row">
                    
                    {/* <div className="col-lg-4">
                      <h5 className="user-details">User Details:-</h5>
                      <p style={{ fontSize: "14px" }}>
                        <i
                          className="fa fa-calendar"
                          style={{ color: "#0083be", fontSize: "22px" }}
                        ></i>
                        &nbsp; <strong>Activation Date</strong>
                      </p>
                      <p style={{ fontSize: "14px" }}>
                        <i
                          className="fa fa-calendar"
                          style={{ color: "#0083be", fontSize: "22px" }}
                        ></i>
                        &nbsp; <strong>Billing Cycle</strong>
                      </p>
                      <p style={{ fontSize: "14px" }}>
                        <i
                          className="fa fa-calendar"
                          style={{ color: "#0083be", fontSize: "22px" }}
                        ></i>
                        &nbsp; <strong>Billing Date</strong>
                      </p>
                      <p style={{ fontSize: "14px" }}>
                        <i
                          className="fa fa-calendar"
                          style={{ color: "#0083be", fontSize: "22px" }}
                        ></i>
                        &nbsp; <strong>Dye Date</strong>
                      </p>
                    </div>
                    <div className="col-lg-4">
                      <canvas
                        ref={(ref) => setBarChartCanvas(ref)}
                        style={{ maxHeight: "400px", height: "400px" }}
                      ></canvas>
                    </div>
                    <div className="col-lg-4">
                      <canvas ref={(ref) => setPieChartCanvas(ref)}></canvas>
                    </div>*/}
                 
                   {/* Flexbox Container for Total Calls and Profit */}
                   <div className="col-lg-12 d-flex align-items-center justify-content-between">
                      <h5 style={{ marginRight: "10px" }}>
                        <i className="fa fa-phone" aria-hidden="true"></i>
                        Total Calls: {totalCalls}
                      </h5>
                      <h5>
                        <i className="fa fa-dollar" aria-hidden="true"></i>
                        Total Profit: ${totalProfit}
                      </h5>
                        <FilterDropdown onFilterChange={handleFilterChange} 
                    loading={loading} // Pass loading state to the filter dropdown
                   />
                    </div>
                    <div>
                    {loading ? (
                      <div style={{ textAlign: "center" }}>
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    ) : chartData.length > 0 ? (
                      <CanvasJSReact.CanvasJSChart options={options} />
                    ) : (
                      <div style={{ textAlign: "center" }}>No Data Found</div>
                    )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section dashboard">
        <div className="row">
          <div className="col-lg-6">
          {pieChartData.length > 0 ? (
              <CanvasJSReact.CanvasJSChart options={pieOptions} />
            ) : (
              <div style={{ textAlign: "center" }}>No Data Found</div>
            )}
          </div>
          <div className="col-6">
            <div className="card top-selling overflow-auto pt-3 pb-3">
              <div className="card-body pb-0">
                <h5
                  className="bucket-list"
                  style={{ widhth: "100%", backgroundColor: "skyblue" }}
                >
                  Refill Information{" "}
                </h5>
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">Country</th>
                      <th scope="col">Name</th>
                      <th scope="col">Previous Month</th>
                      <th scope="col">Remaining</th>
                      <th scope="col">Assigned</th>
                      <th scope="col">Used Percentage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bucketListData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.country}</td>
                        <td>{item.name}</td>
                        <td>{item.previousMonth}</td>
                        <td>{item.remaining}</td>
                        <td>{item.assigned}</td>
                        <td>{item.usedPercentage}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Dashboard;
