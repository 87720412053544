import React, { useState, useEffect, useCallback } from 'react';
import io from 'socket.io-client';
import axios from 'axios';
import DataTable from "react-data-table-component";
import ReactLoading from "react-loading";
import { Link } from 'react-router-dom';
import { API_SERVER_URL } from '../helper/Constants';

// const API_SERVER_URL = window.CAMPAIN_WWS_URL;
const FRONT_WEB_SOCKET_URL = "https://api.livepbxphone.us/";

const CampaignLiveCalls = ({ 
    userId = '', 
    userType = 2,
    onTotalCampaignCallsUpdate
  }) => {
    const [campaigns, setCampaigns] = useState([]);
    const [socket, setSocket] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
  
    const processCampaignData = useCallback((data) => {
      const filteredCampaigns = data.map(campaign => ({
        ...campaign,
        calls: campaign.calls.filter(call => call.campaign_Id !== "NULL")
      })).filter(campaign => campaign.calls.length > 0);
  
      setCampaigns(filteredCampaigns);
      
      if (onTotalCampaignCallsUpdate) {
        const totalCalls = filteredCampaigns.reduce((sum, campaign) => sum + campaign.calls.length, 0);
        onTotalCampaignCallsUpdate(totalCalls);
      }
    }, [onTotalCampaignCallsUpdate]);
  
    useEffect(() => {
      let mounted = true;
      let socketInstance = null;
  
      const fetchLiveCalls = async () => {
        try {
          const response = await axios.get(`${API_SERVER_URL}api/live-calls-admin`, {
            params: { user_id: userId }
          });
          console.log(response.data); // Debugging API response
          if (mounted) {
            processCampaignData(response.data);
            setIsLoading(false);
          }
        } catch (err) {
          console.error('Error fetching live calls:', err);
          if (mounted) {
            setError('Failed to fetch live calls.');
            setIsLoading(false);
          }
        }
      };
      
  
      if (userId) {
        fetchLiveCalls();
      }
  
      return () => {
        mounted = false;
        if (socketInstance) {
          socketInstance.disconnect();
        }
      };
    }, [userId, processCampaignData]);
  

  const columns = [
    {
      name: "Call ID",
      selector: row => row.pk_id,
      sortable: true,
    },
    {
      name: "From",
      selector: row => row.call_from,
      sortable: true,
    },
    {
      name: "To",
      selector: row => row.call_to,
      sortable: true,
    },
    {
      name: "DID",
      selector: row => row.did,
      sortable: true,
    },
    {
      name: "Direction",
      selector: row => row.direction,
      sortable: true,
    }
  ];

  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        {error}
      </div>
    );
  }

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">Campaign Live Calls</li>
          </ol>
        </nav>
      </div>
      <section className="section dashboard">
        <div className="card">
          <div className="card-body mt-3">
            <h3>
              <strong className="border-bottom border-3 pb-2">
                Campaign Live Calls
              </strong>
            </h3>
            <div className="card" style={{ boxShadow: "none" }}>
              <div className="card-body" style={{ padding: 0 }}>
                <div className="container-fluid d-flex justify-content-center">
                  <div className="w-100">
                    {isLoading && (
                      <div className="d-flex justify-content-center my-5">
                        <ReactLoading
                          type="spokes"
                          color="grey"
                          height={50}
                          width={50}
                        />
                      </div>
                    )}
                    {!isLoading && (
                      <div className="main">
                        {campaigns.length === 0 ? (
                          <p className="text-center">No campaign calls available</p>
                        ) : (
                          campaigns.map((campaign) => (
                            <div key={campaign.campaignName} className="mb-4">
                              <h4 className="mb-3">
                                {campaign.campaignName} ({campaign.calls.length} {campaign.calls.length === 1 ? 'Call' : 'Calls'})
                              </h4>
                              <DataTable
                                columns={columns}
                                data={campaign.calls}
                                pagination
                                highlightOnHover
                                responsive
                                noDataComponent="No Calls Available"
                              />
                            </div>
                          ))
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CampaignLiveCalls;