import "./App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  Navigate,
} from "react-router-dom";

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import EndSidebar from "./components/EndSidebar";
import Footer from "./components/Footer";
import Dashboard from "./components/Dashboard";
import UserProfile from "./components/UserProfile";
import Purchaseplan from "./components/Purchaseplan";
import NeedHelp from "./components/NeedHelp";
import LiveSummary from "./components/LiveSummary";
import CallLogs from "./components/CallLogs";
import ManageNo from "./components/ManageNo";
import BlockedNumbers from "./lists/BlockedNumbers";
import CreateCampaigns from "./components/CreateCampaigns";
import ManageCampaigns from "./components/ManageCampaigns";
import Publishers from "./components/Publishers";
import ManageBuyers from "./components/Targets-pages/ManageBuyers";
import CreateTargets from "./components/Targets-pages/CreateTargets";
import ManageTargets from "./components/Targets-pages/ManageTargets";
import ManageGroups from "./components/Targets-pages/ManageGroups";
import Activities from "./components/Activities-pages/Activities";
import Notification from "./components/Activities-pages/Notifications";
import UserActivities from "./components/Activities-pages/UserActivities";
import ManageUser from "./components/Settings-pages/ManageUser";
import Profile from "./components/Settings-pages/Profile";
import Login from "./components/Login";
import Sucess from "./components/Sucess";
import Register from "./components/Register";
import Layout from "./components/boilerPlat/layout";
import ReportList from "./lists/ReportList";
import PurchaseNumberList from "./lists/PurchaseNumberList";
import AddPurchaseNumber from "./lists/AddPurchaseNumber";
import Importpage from "./components/Importpage";
import CreateCustmer from "./components/CreateCustmer";
import CustomerReport from "./lists/CustomerReport";
import Sipdata from "./lists/Sipdata";
import { useAuth } from "./components/AuthRouter/AuthContext";
import CreateGatway from "./components/CreateGatway";
import Gatway from "./components/Gatway";
import EditGatway from "./components/EditGatway";
import Api from "./components/Api";
import { EditCustomer } from "./components/EditCustomer";
import CreateDID from "./components/CreateDID";
import EditDID from "./components/EditDid";
import IpWhiteList from "./lists/IpWhiteList";
import UserExtensions from "./lists/UserExtensions";
import DeleteUser from "./components/Settings-pages/DeleteUser";
import LiveCalls from "./components/LiveCalls";
// import whatsappIcon from '../src/images/whatsapp.png';
import ChatBot from "./components/ChatBot";
import CampaignLiveCalls from "./components/CampaignLiveCalls";
import SipStatusTable from "./components/SipStatusTable";
const RenderLayout = (props) => {
  return (
    <>
      <Layout>{props}</Layout>
    </>
  );
};
function App() {
  const { loggedIn } = useAuth();

  // Define styles for the icon
// const iconStyle = {
//   position: 'fixed',
//   bottom: '20px',
//   right: '20px',
//   width: '60px', // Adjust size as needed
//   height: '60px',
//   cursor: 'pointer',
//   zIndex: 1000, // Ensure it is above other elements
// };

  function PrivateRoute({ children, ...rest }) {
    return loggedIn ? <Outlet /> : <Navigate to="/" />;
  }

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={RenderLayout(<Dashboard />)} />
            <Route path="/profile" element={RenderLayout(<UserProfile />)} />
            <Route
              path="/purchaseplan"
              element={RenderLayout(<Purchaseplan />)}
            />
            <Route path="/success" element={RenderLayout(<Sucess />)} />
            {/* success */}
            <Route path="/need-help" element={RenderLayout(<NeedHelp />)} />
            <Route path="/live-calls" element={RenderLayout(<LiveSummary />)} />
            <Route path="/report-list" element={RenderLayout(<ReportList />)} />
            <Route
              path="/purchase-number-list"
              element={RenderLayout(<PurchaseNumberList />)}
            />
            <Route
              path="/add-purchase-number"
              element={RenderLayout(<AddPurchaseNumber />)}
            />
            <Route
              path="/block-number"
              element={RenderLayout(<BlockedNumbers />)}
            />
            <Route
              path="/verify-ip"
              element={RenderLayout(<BlockedNumbers />)}
            />
            <Route
              path="/create-campaigns"
              element={RenderLayout(<CreateCampaigns />)}
            />
            <Route
              path="/manage-campaigns"
              element={RenderLayout(<ManageCampaigns />)}
            />
            <Route path="/publishers" element={RenderLayout(<Publishers />)} />
            <Route
              path="/manage-buyers"
              element={RenderLayout(<ManageBuyers />)}
            />
            <Route
              path="/create-targets"
              element={RenderLayout(<CreateTargets />)}
            />
            <Route
              path="/manage-targets"
              element={RenderLayout(<ManageTargets />)}
            />
            <Route
              path="/manage-groups"
              element={RenderLayout(<ManageGroups />)}
            />
            <Route path="/activities" element={RenderLayout(<Activities />)} />
            <Route
              path="/notifications"
              element={RenderLayout(<Notification />)}
            />
            <Route
              path="/user-activities"
              element={RenderLayout(<UserActivities />)}
            />
               <Route path="/user-extensions/:userId" element={<UserExtensions />} />
            <Route path="/manage-user" element={RenderLayout(<ManageUser />)} />
            <Route path="/Import-page" element={RenderLayout(<Importpage />)} />
            <Route
              path="/Create-Custmer"
              element={RenderLayout(<CreateCustmer />)}
            />
            <Route
              path="/edit-customer"
              element={RenderLayout(<EditCustomer />)}
            />
            <Route
              path="/Customer-Report"
              element={RenderLayout(<CustomerReport />)}
            />
            <Route path="/sipdata" element={RenderLayout(<Sipdata />)} />
            <Route
              path="/create-gatway"
              element={RenderLayout(<CreateGatway />)}
            />
            <Route path="/create-DID" element={RenderLayout(<CreateDID />)} />
            <Route path="/editdid" element={RenderLayout(<EditDID />)} />
            <Route path="/gatway" element={RenderLayout(<Gatway />)} />
            <Route path="/editgatway" element={RenderLayout(<EditGatway />)} />
            <Route path="/api" element={RenderLayout(<Api />)} />
            <Route path="/ip-white-list" element={RenderLayout(<IpWhiteList />)} />
            <Route path="/delete-users" element={RenderLayout(<DeleteUser />)} />
            <Route path="/live-call" element={RenderLayout(<LiveCalls />)} />
            <Route path="/extension-status" element={RenderLayout(<SipStatusTable />)} />

            <Route path="/campaign-live-call" element={RenderLayout(<CampaignLiveCalls />)} />
            <Route path="/chat-boat" element={RenderLayout(<ChatBot />)} />


            {/* ip-white-list */}
          </Route>
        </Routes>
      </Router>

      {/* {loggedIn && (

      <a
        href="https://wa.me/8120926637?text=Hello%2C%20how%20can%20I%20help%20you%3F" // Replace with your actual phone number and message
        target="_blank"
        rel="noopener noreferrer"
        // style={whatsappStyle} // Add your custom styles
      >
        <img src={whatsappIcon} alt="WhatsApp" style={iconStyle} />
      </a>
 )} */}

    </div>
  );
}

export default App;
