import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toggleSidebar, selectIsSidebarOpen } from "../slices/sidebarSlice";

const Sidebar = ({ openNav, navHandler }) => {
  const isSidebarOpen = useSelector((state) => state.sidebar.isSidebarOpen);
  return (
    <>
      <aside id="sidebar" className={`sidebar ${openNav ? "active" : ""}`}>
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link to="/dashboard" className="nav-link">
              {" "}
              {/* Use Link to create the dashboard link */}
              <i className="bi bi-grid" />
              <span>Dashboard</span>
            </Link>
          </li>
          {/* End Dashboard Nav */}
          <li className="nav-item">
            <Link to="/report-list" className="nav-link">
              <span>Customer Detail</span>
            </Link>
          </li>
          {/* End Components Nav */}
          <li className="nav-item">
            <Link to="/purchase-number-list" className="nav-link">
              <span style={{ marginLeft: "-5px" }}>DID</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/ip-white-list" className="nav-link">
              <span style={{ marginLeft: "-5px" }}>IP Settings</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/Customer-Report" className="nav-link">
              <span style={{ marginLeft: "-5px" }}>Customer Report</span>
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link to="/create-gatway" className="nav-link">
              <span style={{ marginLeft: "-5px" }}>Create Gatway</span>
            </Link>
          </li> */}
          <li className="nav-item">
            <Link to="/gatway" className="nav-link">
              <span style={{ marginLeft: "-5px" }}>Gatway</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/api" className="nav-link">
              <span style={{ marginLeft: "-5px" }}>Api</span>
            </Link>
          </li>

          <li className="nav-item">
                <Link to="/manage-user" className="nav-link">
                 
                  <span style={{ marginLeft: "-5px" }}>Manage Users</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/delete-users" className="nav-link">
                 
                  <span style={{ marginLeft: "-5px" }}>Deleted Users</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/live-call" className="nav-link">
                 
                  <span style={{ marginLeft: "-5px" }}>Live Calls</span>
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link to="/chat-boat" className="nav-link">
                 
                  <span style={{ marginLeft: "-5px" }}>Chat Boat</span>
                </Link>
              </li> */}
              {/* <li className="nav-item">
                <Link to="/campaign-live-call" className="nav-link">
                 
                  <span style={{ marginLeft: "-5px" }}>Campaign Live Call</span>
                </Link>
              </li> */}
              <li className="nav-item">
                <Link to="/extension-status" className="nav-link">
                 
                  <span style={{ marginLeft: "-5px" }}>SIP Extensions Status</span>
                </Link>
              </li>

              <li></li>

          {/* End Forms Nav */}
          {/* <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#tables-nav"
              data-bs-toggle="collapse"
              href="#"
            >
              <i className="bi bi-layout-text-window-reverse" />
              <span>Campaigns</span>
              <i className="bi bi-chevron-down ms-auto" />
            </a>
            <ul
              id="tables-nav"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/create-campaigns" className="nav-link">
                  <i className="bi bi-circle" />
                  <span>Create Campaigns</span>
                </Link>
              </li>
              <li>
                <Link to="/manage-campaigns" className="nav-link">
                  <i className="bi bi-circle" />
                  <span>Manage Campaigns</span>
                </Link>
              </li>
            </ul>
          </li>
          {/* End Tables Nav */}
          {/* <li className="nav-item">
            <Link to="/publishers" className="nav-link collapsed">
              <i className="bi bi-person" />
              <span>Publishers</span>
            </Link>
          </li> */}

          {/* <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#charts-nav"
              data-bs-toggle="collapse"
              href="#"
            >
              <i className="bi bi-bar-chart" />
              <span>Targets</span>
              <i className="bi bi-chevron-down ms-auto" />
            </a>
            <ul
              id="charts-nav"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/manage-buyers" className="nav-link">
                  <i className="bi bi-circle" />
                  <span>Manage Buyers</span>
                </Link>
              </li>
              <li>
                <Link to="/create-targets" className="nav-link">
                  <i className="bi bi-circle" />
                  <span>Create Targets</span>
                </Link>
              </li>
              <li>
                <Link to="/manage-targets" className="nav-link">
                  <i className="bi bi-circle" />
                  <span>Manage Targets</span>
                </Link>
              </li>
              <li>
                <Link to="/manage-groups" className="nav-link">
                  <i className="bi bi-circle" />
                  <span>Manage Groups</span>
                </Link>
              </li>
            </ul>
          </li> */}
          {/* End Charts Nav */}
          {/* <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#activities-nav" // Change to a unique target
              data-bs-toggle="collapse"
              href="#"
            >
              <i className="bi bi-gem" />
              <span>Activities</span>
              <i className="bi bi-chevron-down ms-auto" />
            </a>
            <ul
              id="activities-nav" // Update the ID to match the target
              className="nav-content collapse"
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/activities" className="nav-link">
                  <i className="bi bi-circle" />
                  <span>Activities</span>
                </Link>
              </li>
              <li>
                <Link to="/notifications" className="nav-link">
                  <i className="bi bi-circle" />
                  <span>Notifications</span>
                </Link>
              </li>
              <li>
                <Link to="/user-activities" className="nav-link">
                  <i className="bi bi-circle" />
                  <span>User Activities</span>
                </Link>
              </li>
            </ul>
          </li> */}
          {/* <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#settings-nav" // Change to a unique target
              data-bs-toggle="collapse"
              href="#"
            >
              <i className="bi bi-gem" />
              <span>Settings</span>
              <i className="bi bi-chevron-down ms-auto" />
            </a>
            <ul
              id="settings-nav" // Update the ID to match the target
              className="nav-content collapse"
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/manage-user" className="nav-link">
                  <i className="bi bi-circle" />
                  <span>Manage Users</span>
                </Link>
              </li>
              <li>
                <Link to="/profile" className="nav-link">
                  <i className="bi bi-circle" />
                  <span>Profile</span>
                </Link>
              </li>
            </ul>
          </li> */}

          {/* End Icons Nav */}
        </ul>
      </aside>
      {/* <CallLogs /> */}
      {/* <i
        className="bi bi-list toggle-sidebar-btn"
        onClick={handleToggleSidebar}
      /> */}
      {/* <ManageNo /> */}
    </>
  );
};

export default Sidebar;
