import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import yourDocumentPDF from "./eng_10601_56_2324b.pdf";
import { CSVLink } from "react-csv";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { CallGETAPI, CallPOSTAPI } from "../helper/Constants";
import BalanceModal from "../components/models/BalanceModal";
import EndDateModal from "../components/models/EndDateModal";
import ImagePopup from "../components/models/ImagePopup";


const ReportList = () => {
  const [accountSearch, setAccountSearch] = useState("");
  const [fnameSearch, setFnameSearch] = useState("");
  const [lnameSearch, setLnameSearch] = useState("");
  const [emailSearch, setEmailSearch] = useState("");
  const [companySearch, setCompanySearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [editBalanceId, setEditBalanceId] = useState(null);
  const [editBalanceValue, setEditBalanceValue] = useState("");
  const [showBalanceModal, setShowBalanceModal] = useState(false);
    // Naye hooks for updating subscription end date
    const [editEndDateId, setEditEndDateId] = useState(null);
    const [editEndDateValue, setEditEndDateValue] = useState("");
    const [showEndDateModal, setShowEndDateModal] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [popupTitle, setPopupTitle] = useState("");
 const BASE_API = "https://api.livepbxphone.us/";
//  const BASE_API = "http://localhost:3003/";


    // Function to open the image popup
  const openPopup = (image, title) => {
    setSelectedImage(`${BASE_API}images/${image}`);  // Adjust the file base path
    setPopupTitle(title);
    setIsPopupOpen(true);
  };

  // Function to close the image popup
  const closePopup = () => {
    setIsPopupOpen(false);
    setSelectedImage(null);
    setPopupTitle("");
  };

  // const fetchData = async () => {
  //   try {
  //     setIsLoading(true);
  //     const response = await CallGETAPI("api/get-all-customerdetails");
  //     setCustomerData(response.data.customerDetails);
  //   } catch (error) {
  //     console.log(error.message);
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };


  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await CallGETAPI(`api/users-v2`);
      const result = response?.data?.data;
      if (response.status) {
        setCustomerData(result);
      } else {
        setCustomerData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setCustomerData([]); // Clear data in case of error
    } finally {
      setIsLoading(false);
    }
  };
// } catch (error) {
//   console.error("Error:", error);
//   toast.error("Failed to fetch data!");
// } finally {
//   setIsLoading(false);
//   setCustomerData([]);
// }
// };

  // const showSwal = () => {
  //   withReactContent(Swal).fire({
  //     title: <i>Input something</i>,
  //     input: "file",
  //     inputValue,
  //     preConfirm: () => {
  //       setInputValue(Swal.getInput()?.value || "");
  //     },
  //   });
  // };

  useEffect(() => {
    console.log("UseEffect Calling");
    fetchData();
  }, []);

  const handledelete = async (user_id) => {
    setIsLoading(true);
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this customer data !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes ",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await CallGETAPI(`api/delete-user/${user_id}`);

          if (response.status) {
            toast.success("Deleted Successfully!");
            fetchData();
          }
        }
      });
    } catch (error) {
      console.error("Error deleting data:", error);
    } finally {
      setIsLoading(false);
    }
  };



  const handleBalanceUpdate = async () => {
    setIsLoading(true);
    try {
      const response = await CallPOSTAPI(`api/update-balance`, {
        user_id: editBalanceId,
        balance: editBalanceValue,
      });
      
      if (response.status) {
        toast.success(response.message || "Update Balance Successfully!");
        fetchData(); 
        setShowBalanceModal(false);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error('Error updating balance:', error);
      toast.error('Error updating balance');
    } finally {
      setIsLoading(false);  // Set balance loading to false
    }
  };

  const handleEndDateUpdate = async () => {
    setIsLoading(true);
    try {
      // Format the date before sending to API
      const formattedDate = new Date(editEndDateValue).toISOString();
      
      const response = await CallPOSTAPI(`api/update-user-subscription`, {
        user_id: editEndDateId,
        endDate: formattedDate,
      });
  
      if (response.status) {
        toast.success(response.message || "Expiration Date updated successfully!");
        fetchData();
        setShowEndDateModal(false);
      } else {
        toast.error(response.message || "Subscription not found for the given user_id");
      }
    } catch (error) { 
      console.error("Error updating subscription end date:", error);
      toast.error("Error updating subscription end date");
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleToggleStatus = async (userId, currentState) => {
    try {
      setIsLoading(true);
      const response = await CallPOSTAPI(`api/block-user`, {
        user_id: userId,
      });
      if (response.status) {
        const newState = !currentState;
        setCustomerData(
          customerData.map((item) =>
            item.user_id === userId ? { ...item, status: newState } : item
          )
        );
        toast.success(`User ${newState ? "unblocked" : "blocked"}`);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error toggling user status:", error);
      toast.error("Error toggling user status");
    } finally {
      setIsLoading(false);
    }
  };

  


  const columns = [
    {
      name: (
        <input
          className="form-check-input"
          type="checkbox"
          id="check1"
          name="option1"
          defaultValue="something"
          defaultChecked=""
        />
      ),
      selector: (row) => `${row.check}`,
      cell: () => (
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="check1"
            name="option1"
            defaultValue="something"
            defaultChecked=""
          />
        </div>
      ),
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "ACCOUNT",
      selector: (row) => `${row.user_id}`,
      sortable: true,
      compact: true,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "FIRST NAME",
      selector: (row) => `${row.first_name}`,
      sortable: true,
      compact: true,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "LAST NAME",
      selector: (row) => `${row.last_name}`,
      sortable: true,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "EMAIL",
      selector: (row) => `${row.email}`,
      sortable: true,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "PASSWORD",
      selector: (row) => `${row.password}`,
      sortable: true,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "USER TYPE",
      selector: (row) => `${row.user_type}`,
      sortable: true,
      style: {
        border: "1px solid black",
      },
    },
    // {
    //   name: "COMPANY",
    //   selector: (row) => `${row.company}`,
    //   sortable: true,
    //   style: {
    //     border: "1px solid black",
    //   },
    // },
    // {
    //   name: "RATE GROUP",
    //   selector: (row) => `${row.rate_group}`,
    //   sortable: true,
    //   style: {
    //     border: "1px solid black",
    //   },
    //   // cell: (d) => (
    //   //     <p class="align-text-bottom text-nowrap">
    //   //         {d.recording}
    //   //         <svg height={24}>
    //   //             <circle cx="12" cy="12" r="5" fill="green" />
    //   //         </svg>
    //   //     </p>
    //   // )
    // },
    {
      name: "BALANCE(USD)",
      selector: (row) => `${row.balance}`,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "EXTENSIONS",
      selector: (row) => row.extensions[`user_${row.user_id}_extensions`]?.length || 0,
      cell: (row) => (
        <Link to={`/user-extensions/${row.user_id}`} className="btn btn-sm btn-outline-primary">
          View  ({row.extensions[`user_${row.user_id}_extensions`]?.length || 0})
        </Link>
      ),
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "COUNTRY",
      selector: (row) => row.country,
      cell: (row) => row.country ? row.country : "Null", // If data is null, show "Null"
      sortable: true,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "ADDRESS 1",
      selector: (row) => row.address1,
      cell: (row) => row.address1 ? row.address1 : "Null", // If data is null, show "Null"
      sortable: true,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "ADDRESS 2",
      selector: (row) => row.address2,
      cell: (row) => row.address2 ? row.address2 : "Null", // If data is null, show "Null"
      sortable: true,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "COMPANY",
      selector: (row) => row.company,
      cell: (row) => row.company ? row.company : "Null", // If data is null, show "Null"
      sortable: true,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "CONTACT NUMBER",
      selector: (row) => row.contact_number,
      cell: (row) => row.contact_number ? row.contact_number : "0", // If data is null, show "0"
      sortable: true,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "COMPANY WEBSITE",
      selector: (row) => row.company_website,
      cell: (row) => row.company_website ? row.company_website : "Null", // If data is null, show "Null"
      sortable: true,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "ID PROOF FRONT",
      selector: (row) => row.id_proof_front,
      cell: (row) => row.id_proof_front ? (
        <span
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => openPopup(row.id_proof_front, "ID Proof Front")}
        >
          View Image
        </span>
      ) : (
        "Null"  // Display "null" when there's no image
      ),
      sortable: true,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "ID PROOF BACK",
      selector: (row) => row.id_proof_back,
      cell: (row) => row.id_proof_back ? (
        <span
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => openPopup(row.id_proof_back, "ID Proof Back")}
        >
          View Image
        </span>
      ) : (
        "Null"  // Display "Null" when there's no image
      ),
      sortable: true,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "ADDRESS PROOF FRONT",
      selector: (row) => row.address_proof_front,
      cell: (row) => row.address_proof_front ? (
        <span
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => openPopup(row.address_proof_front, "Address Proof Front")}
        >
          View Image
        </span>
      ) : (
        "Null"  // Display "Null" when there's no image
      ),
      sortable: true,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "ADDRESS PROOF BACK",
      selector: (row) => row.address_proof_back,
      cell: (row) => row.address_proof_back ? (
        <span
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => openPopup(row.address_proof_back, "Address Proof Back")}
        >
          View Image
        </span>
      ) : (
        "Null"  // Display "Null" when there's no image
      ),
      sortable: true,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "REFERRED BY",
      // selector: (row) => row.referred_by,
      // sortable: true,
      selector: (row) => row.referred_by,
      cell: (row) => row.referred_by ? row.referred_by : "Null", // If data is null, show "Null"
      sortable: true,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "IP ADDRESS",
      selector: (row) => row.client_ip_address,
      cell: (row) => row.client_ip_address ? row.client_ip_address : "Null",
      sortable: true,
      style: {
        border: "1px solid black",
      },
    },
    // {
    //   name: "CREDIT LIMIT(USD)",
    //   selector: (row) => `${row.credit_limit}`,
    //   style: {
    //     border: "1px solid black",
    //   },
    // },
    // {
    //   name: "FIRST USED",
    //   selector: (row) => `${row.first_used}`,
    //   style: {
    //     border: "1px solid black",
    //   },
    // },
    // {
    //   name: "EXPIRY DATE",
    //   selector: (row) => `${row.expiry_date}`,
    //   style: {
    //     border: "1px solid black",
    //   },
    // },
    // {
    //   name: "CC",
    //   selector: (row) => `${row.concurrent_calls}`,
    //   style: {
    //     border: "1px solid black",
    //   },
    // },
    // {
    //   name: "SIP",
    //   selector: (row) => `${row.sip}`,
    //   sortable: true,
    //   width: "9%",
    //   style: {
    //     border: "1px solid black",
    //   },
    //   cell: (row) => <Link to="/sipdata">{row.sip}</Link>,
    // },
    {
      name: "STATUS",
      selector: (row) => `${row.status}`,
      style: {
        border: "1px solid black",
      },
      cell: (row) => (
        // <div>
        //   <button
        //     type="button"
        //     className={`btn btn-sm ${row.status ? 'btn-success' : 'btn-danger'}`}
        //     onClick={() => handleToggleStatus(row.user_id, row.status)}
        //   >
        //     {row.status ? 'ON' : 'OFF'}
        //   </button>
        // </div>
        <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id={`sipToggle-${row.id}`}
          checked={row.status}
          // className={`btn btn-sm ${row.status ? 'btn-success' : 'btn-danger'}`}
          onClick={() => handleToggleStatus(row.user_id, row.status)}
          // disabled={row.id !== sip}
          // Disable toggling for the logged-in user's extension
        />
      </div>
      ),
      
    },
    {
      name: "CREATED DATE",
      selector: (row) => `${row.createdAt}`,
      style: {
        border: "1px solid black",
      },
    },
    {
      name: "EXPIRATION DATE",
      selector: (row) => `${row.endDate}`,
      style: {
        border: "1px solid black",
      },
     
    },
    // {
    //   name: "PASSWORD",
    //   selector: (row) => `${row.password}`,
    //   sortable: true,
    //   style: {
    //     border: "1px solid black",
    //   },
    // },
    {
      name: "ACTION",
      center: true,
      sortable: false,
      width: "10%",
      // selector: "null",
      selector: row => row.null,
      style: {
        border: "1px solid black",
      },
      
      cell: (row) => [
        <button
        type="button"
        className="btn btn-sm btn-outline-warning"
        onClick={() => {
          setEditBalanceId(row.user_id);
          setEditBalanceValue(row.balance);
          setShowBalanceModal(true);
        }}
      >
        <i className="fa-solid fa-dollar-sign"></i>
      </button>,
      

<button
type="button"
className="btn btn-sm btn-outline-warning ms-2"
onClick={() => {
  setEditEndDateId(row.user_id);
  setEditEndDateValue(row.endDate || '');
  setShowEndDateModal(true);
}}
>
<i className="fa-solid fa-calendar"></i>
</button>,
        <button
          type="button"
          class="btn btn-sm btn-outline-warning"
          onClick={() => navigate("/edit-customer", { state: row })}
        >
          <i class="fa-regular fa-pen-to-square"></i>
        </button>,
        <button
          type="submit"
          class="btn btn-sm btn-outline-warning "
          onClick={() => handledelete(row.user_id)}
        >
          <i class="fa-regular fa-trash-can"></i>
        </button>,
      ],
    },
  ];

  const handleFname = (e) => {
    setFnameSearch(e.target.value);
  };
  const handleLname = (e) => {
    setLnameSearch(e.target.value);
  };
  const handleEmail = (e) => {
    setEmailSearch(e.target.value);
  };
  const handleCompany = (e) => {
    setCompanySearch(e.target.value);
  };
  const handleAccount = (e) => {
    setAccountSearch(e.target.value);
  };
  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };
  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };
  // const handleSearch = () => {
  //   setSearchTrigger(true)
  // };
  const handleClear = () => {
    setAccountSearch("");
    setFnameSearch("");
    setLnameSearch("");
    setCompanySearch("");
    setStartDate("");
    setEndDate("");
    setEmailSearch("");
    setStatus("");
    setSearchTrigger(false);
    setFilteredData(data);
  };

  const handleSearch = () => {
    setSearchTrigger(true);

    const newData = data.filter(
      (item) =>
        (!status || item.status.includes(status)) &&
        (!fnameSearch ||
          item.firstname.toLowerCase().includes(fnameSearch.toLowerCase())) &&
        (!lnameSearch ||
          item.lastname.toLowerCase().includes(lnameSearch.toLowerCase())) &&
        (!companySearch ||
          item.company.toLowerCase().includes(companySearch.toLowerCase())) &&
        (!startDate ||
          item.createddate.toLowerCase().includes(startDate.toLowerCase())) &&
        (!endDate ||
          item.expirydate.toLowerCase().includes(endDate.toLowerCase())) &&
        (!accountSearch || item.account.toString().includes(accountSearch))
    );
    // console.log("Filtered Data:", newData);

    setFilteredData(newData);
  };

  const handleExportClick = () => {
    // Export logic here
    console.log("Exporting data:", customerData);
  };

  const handleClick = () => {
    // Open a new tab with the document URL
    window.open(yourDocumentPDF, "_blank");
  };

  const customStyles = {
    table: {
      style: {
        borderCollapse: "collapse",
        border: "1px solid black", // Set the header border
      },
    },
    rows: {
      style: {
        borderCollapse: "collapse",
      },
    },
    columns: {
      style: {
        borderCollapse: "collapse",
      },
    },
    header: {
      style: {
        border: "1px solid black", // Set the header border
      },
    },
  };

  console.log({ customerData });

  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Dashboard</a>
              </li>
              <li className="breadcrumb-item active">Customer</li>
            </ol>
          </nav>
        </div>
        <section>
          <div className="card">
            <div className="card-body mt-3">
              <h1></h1>
              {/* Bordered tabs Justified */}
              <div className="container-fluid">
                <div className="row">
                  <div className="col-6 d-flex justify-content-start ">
                    <h5 className="text-left">Customer</h5>
                  </div>
                  <div className="col-12 d-flex justify-content-end mb-3">
                    <button
                      type="button"
                      class="btn btn-warning my-2"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Search
                    </button>
                  </div>
                  <div className="col-lg-12 p-4">
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Account:
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={accountSearch}
                                  onChange={handleAccount}
                                  id="account"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                First Name
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={fnameSearch}
                                  onChange={handleFname}
                                  id="firstname"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Last Name
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={lnameSearch}
                                  onChange={handleLname}
                                  id="lastname"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Company
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={companySearch}
                                  onChange={handleCompany}
                                  id="company"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Email
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  value={emailSearch}
                                  onChange={handleEmail}
                                  id="email"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start d-flex d-flex justify-content-start"
                              >
                                Start Date:
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="date"
                                  className="form-control w-100"
                                  value={startDate}
                                  onChange={handleStartDate}
                                  id="createddate"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                End Date
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="date"
                                  className="form-control w-100"
                                  value={endDate}
                                  onChange={handleEndDate}
                                  id="expirydate"
                                  placeholder=""
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-5 col-form-label d-flex d-flex justify-content-start "
                              >
                                Status
                              </label>
                              <div className="col-sm-6">
                                <select
                                  class="form-select form-select-md"
                                  aria-label=".form-select-lg example"
                                  id="status"
                                  value={status}
                                  onChange={handleStatus}
                                >
                                  <option value="">--Select--</option>
                                  <option value="Active">Active</option>
                                  <option value="Inactive">Inactive</option>
                                </select>
                              </div>
                            </div>
                            <div className="row my-4">
                              <div className="col-12 d-flex justify-content-evenly">
                                <button
                                  type="button"
                                  class="btn btn-success px-5"
                                  onClick={handleSearch}
                                >
                                  Search
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-primary px-5"
                                  onClick={handleClear}
                                >
                                  Clear
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container-fluid mt-4 text-center">
                  <div className="row">
                    <div className="col-12 col-md-11 d-flex justify-content-between flex-wrap">
                      <Link to="/Create-Custmer">
                        <button
                          type="submit"
                          className="btn btn-sm btn-outline-warning mb-2"
                        >
                          <i className="fa-solid fa-circle-plus"></i> Create
                          Customer
                        </button>
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-sm btn-outline-warning mb-2"
                      >
                        <i className="fa-solid fa-circle-plus"></i> Mass Create
                      </button>

                      {/* <button
                        onClick={showSwal}
                        className="btn btn-sm btn-outline-warning mb-2"
                      >
                        <i className="fa-solid fa-upload"></i> Import Customer
                        <div>{inputValue}</div>
                      </button> */}

                      <button
                        type="submit"
                        className="btn btn-sm btn-outline-info mb-2"
                      >
                        <i className="fa-solid fa-circle-plus"></i> Create
                        Provider
                      </button>
                      <CSVLink
                        data={customerData}
                        filename="customerData.csv"
                        className="btn btn-sm btn-outline-primary mb-2"
                        onClick={handleExportClick}
                      >
                        <i className="fa-solid fa-download"></i> Export
                      </CSVLink>
                      <button
                        type="submit"
                        className="btn btn-sm btn-outline-danger mb-2"
                      >
                        <i className="fa-solid fa-circle-xmark"></i> Delete
                      </button>
                      <button
                        type="submit"
                        className="btn btn-sm btn-outline-warning mb-2"
                        onClick={handleClick}
                      >
                        <i className="fa-solid fa-mobile"></i> Open Document
                      </button>
                    </div>
                  </div>
                </div>

                <div className="container-fluid mt-4 text-center">
                  <div className="row">
                    <div
                      className="tab-content"
                      id="borderedTabJustifiedContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="boredered-justified-campaign"
                        role="tabpanel"
                        aria-labelledby="campaign-tab"
                      >
                        <div className="card" style={{ boxShadow: "none" }}>
                          <div
                            className="card-body"
                            style={{ padding: 0, overflowX: "auto" }}
                          >
                             {isLoading && (
                                  <div
                                    className="d-flex justify-content-center my-5"
                                    style={{ marginTop: "20px" }}
                                  >
                                    <ReactLoading
                                      type="spokes"
                                      color="grey"
                                      height={50}
                                      width={50}
                                    />
                                  </div>
                                )}
                                 {!isLoading && (
                            <div className="table-responsive">
                              <div className="main">
                                {/* <DataTableExtensions {...tableData}> */}
                               
                               
                                  <DataTable
                                    columns={columns}
                                    data={customerData}
                                    noHeader
                                    defaultSortField="id"
                                    // sortIcon={<SortIcon />}
                                    defaultSortAsc={true}
                                    pagination
                                    highlightOnHover
                                    dense
                                    customStyles={customStyles}
                                  />
                               
                                {/* </DataTableExtensions> */}
                              </div>
                            </div>
                             )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ImagePopup
        isOpen={isPopupOpen}
        onClose={closePopup}
        imageSrc={selectedImage}
        title={popupTitle}
      />
      <BalanceModal
        show={showBalanceModal}
        onClose={() => setShowBalanceModal(false)}
        editBalanceValue={editBalanceValue}
        onChange={(e) => setEditBalanceValue(e.target.value)}
        onSave={handleBalanceUpdate}
        isLoading={isLoading}
      />
        {/* Modal for updating subscription end date */}
        <EndDateModal
        show={showEndDateModal}
        onClose={() => setShowEndDateModal(false)}
        editEndDateValue={editEndDateValue}
        onChange={(e) => setEditEndDateValue(e.target.value)}
        onSave={handleEndDateUpdate}
        isLoading={isLoading}
      />


    </>
  );
};

export default ReportList;
