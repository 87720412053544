import { jwtDecode } from "jwt-decode";
// export const BASE_API = window.BASE_API;
// https://github.com/iqbal-0786/voip-admin.git https://livepbxphone.us/
export const BASE_API = "https://api.livepbxphone.us/";
// export const BASE_API = "https://psx-t222.onrender.com/";
// http://localhost:3000/api/
// export const BASE_API = "http://localhost:3003/";
export const BASE_WEB_SOCKET = "https://api.livepbxphone.us/";
export const API_SERVER_URL = "https://live.livepbxphone.us";



export const FILE_BASE_RECORDING = "https://sip.livepbxphone.us/";

export const DecryptToken = () => {
  let token = "";
  if (typeof window !== "undefined") {
    token = localStorage.getItem("psx_token") || "";
  }

  let decoded = {};

  if (token !== "" && token !== "undefined") {
    decoded = jwtDecode(token);
  }

  console.log("decoded", decoded);
  return decoded;
};

export async function CallGETAPI(url, params = "", headers = {}) {
  try {
    let token = localStorage.getItem("psx_token");
    let AllHeaders = { ...headers };
    if (token) {
      AllHeaders = { Authorization: "Bearer " + token, ...headers };
    }
    let res = await fetch(BASE_API + url + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...AllHeaders,
      },
    });

    if (!res.ok) {
      return { status: false, msg: "No Data Found", data: [] };
    }
    let resultData = await res.json();
    return { status: true, msg: "", data: resultData };
  } catch (e) {
    return { status: false, msg: e.message, data: [] };
  }
}

// export async function CallPOSTAPI(url, params = [], headers = {}) {
//   try {
//     let token = localStorage.getItem("psx_token");
//     let AllHeaders = { ...headers };
//     if (token) {
//       AllHeaders = { Authorization: "Bearer " + token, ...headers };
//     }

//     let res = await fetch(BASE_API + url, {
//       method: "POST",
//       headers: { "Content-Type": "application/json", ...AllHeaders },
//       body: JSON.stringify(params),
//     });

//     if (!res.ok) {
//       return { status: false, msg: "No Data Found", data: [] };
//     }
//     let resultData = await res.json();
//     return { status: true, msg: resultData?.msg, data: resultData };
//   } catch (e) {
//     return { status: false, msg: e.message, data: [] };
//   }
// }



export async function CallPOSTAPI(url, params = {}, headers = {}) {
  try {
    let token = localStorage.getItem("psx_token");
    let AllHeaders = { ...headers };
    
    // Add the authorization token if it exists
    if (token) {
      AllHeaders = { Authorization: "Bearer " + token, ...headers };
    }

    // Check if params is an instance of FormData
    const isFormData = params instanceof FormData;

    let res = await fetch(BASE_API + url, {
      method: "POST",
      // Only set Content-Type if params is NOT FormData
      headers: isFormData ? { ...AllHeaders } : { "Content-Type": "application/json", ...AllHeaders },
      body: isFormData ? params : JSON.stringify(params), // Send params directly if FormData, otherwise stringify it
    });

    if (!res.ok) {
      return { status: false, msg: "No Data Found", data: [] };
    }

    let resultData = await res.json();
    return { status: true, msg: resultData?.msg, data: resultData };
  } catch (e) {
    return { status: false, msg: e.message, data: [] };
  }
}



export async function CallPostFileUpload(url, params, headers = {}) {
  try {
    let token = localStorage.getItem("psx_token");

    let AllHeaders = { ...headers };
    if (token) {
      AllHeaders = { Authorization: "Bearer " + token, ...headers };
    }
    // headers:{'Content-Type':'application/json',...AllHeaders},

    let res = await fetch(BASE_API + url, {
      method: "POST",
      headers: AllHeaders,
      body: params,
    });

    if (!res.ok) {
      return { status: false, msg: "No Data Found", data: [] };
    }
    let resultData = await res.json();
    return { status: true, msg: "", data: resultData };
  } catch (e) {
    return { status: false, msg: e.message, data: [] };
  }
}
